<template>
   <section>
      <table width="100%" class="shift_master_table">
         <tr>
            <td width="15%" class="pa-2  gray6--text">
               {{ $t('start date') | capitalize }}
            </td>
            <td width="10%" class="pa-2   gray6--text">
               :
            </td>
            <td width="25%" style="text-align:start !important" class="pa-2 ">
               {{ report.start_date | capitalize }}
            </td>

            <td width="15%" class="pa-2   gray6--text">
               {{ $t('end date') | capitalize }}
            </td>

            <td width="10%" class="pa-2   gray6--text">
               :
            </td>
            <td width="25%" style="text-align:start !important" class="pa-2 ">
               {{ report.end_date | capitalize }}
            </td>
         </tr>
         <tr>
            <td width="15%" class="pa-2  gray6--text">
               {{ $t('branch') | capitalize }}
            </td>

            <td width="10%" class="pa-2   gray6--text">:</td>

            <td width="25%" style="text-align:start !important" class="pa-2 ">
               {{ report.branch_name | capitalize }}
            </td>


            <td width="15%" class="pa-2   gray6--text">
               {{ $t('employee') | capitalize }}
            </td>

            <td width="10%" class="pa-2   gray6--text">:</td>
            <td width="25%" style="text-align:start !important" class="pa-2 ">
               {{ report.user_full_name | capitalize }}
            </td>
         </tr>
      </table>

      <v-row class="mt-5 align-center" style="margin-top : 30px">
         <v-col cols="5" style="padding: 15px  margin-top : 10px">
            <v-card class="backgroundW shadow pa-5 py-6">
               <div class="summary_row d-flex justify-space-between">
                  <div>{{ $t("total sales") }}</div>
                  <div>{{ report.total_sales || 0 | float }}</div>
               </div>
               <div class="summary_row d-flex justify-space-between">
                  <div>{{ $t("sales cash") }}</div>
                  <div>{{ report.total_sales_cash || 0 | float }}</div>
               </div>
               <div class="summary_row d-flex justify-space-between">
                  <div>{{ $t("master card") }}</div>
                  <div>{{ report.total_sales_master || 0 | float }}</div>
               </div>
               <div class="summary_row d-flex justify-space-between">
                  <div>{{ $t("visa") }}</div>
                  <div>{{ report.total_sales_visa || 0 | float }}</div>
               </div>
               <div class="summary_row d-flex justify-space-between">
                  <div>{{ $t("mada") }}</div>
                  <div>{{ report.total_sales_mada || 0 | float }}</div>
               </div>
               <div class="summary_row d-flex justify-space-between">
                  <div>{{ $t("transfer") }}</div>
                  <div>{{ report.total_sales_trans || 0 | float }}</div>
               </div>
               <div class="summary_row d-flex justify-space-between">
                  <div>{{ $t("tabby") }}</div>
                  <div>{{ report.total_sales_tabby || 0 | float }}</div>
               </div>
               <div class="summary_row d-flex justify-space-between">
                  <div>{{ $t("tamara") }}</div>
                  <div>{{ report.total_sales_tamara || 0 | float }}</div>
               </div>
               <div class="summary_row d-flex justify-space-between">
                  <div>{{ $t("STC Pay") }}</div>
                  <div>{{ report.total_sales_stc || 0 | float }}</div>
               </div>
               <div class="summary_row d-flex justify-space-between">
                  <div>{{ $t("nearpay") }}</div>
                  <div>{{ report.total_sales_nearpay || 0 | float }}</div>
               </div>
               <div class="summary_row d-flex justify-space-between">
                  <div>{{ $t("other") }}</div>
                  <div>{{ report.total_sales_other || 0 | float }}</div>
               </div>
               <div class="summary_row d-flex justify-space-between">
                  <div>{{ $t("notice debtor") }}</div>
                  <div>{{ report.total_nd || 0 | float }}</div>
               </div>
               <div class="summary_row d-flex justify-space-between">
                  <div>{{ $t("notice creditor") }}</div>
                  <div>{{ report.total_nc || 0 | float }}</div>
               </div>
               <div class="summary_row d-flex justify-space-between">
                  <div>{{ $t("used purchase") }}</div>
                  <div>{{ report.total_used_cash || 0 | float }}</div>
               </div>
               <div class="summary_row d-flex justify-space-between">
                  <div>{{ $t("expenses") }}</div>
                  <div>{{ report.total_expenses || 0 | float }}</div>
               </div>
            </v-card>
         </v-col>
         <v-col cols="7" style="padding: 15px; margin-top : 10px">
            <v-card class="backgroundW shadow pa-5">
               <table class="mini_table_two">
                  <tr>
                     <th>{{ $t("") }}</th>
                     <th>{{ $t("start") }}</th>
                     <th>{{ $t("Transactions") }}</th>
                     <th>{{ $t("end") }}</th>
                     <th>{{ $t("difference") }}</th>
                  </tr>
                  <tr>
                     <td>{{ $t('cash') }}</td>
                     <td>{{ report.start_cash || 0 | float }}</td>
                     <td>{{ report.total_cash || 0 | float }}</td>
                     <td>{{ report.end_cash || 0 | float }}</td>
                     <td>{{ report.diff_cash || 0 | float }}</td>
                  </tr>
                  <tr>
                     <td>{{ $t('visa') }}</td>
                     <td>{{ 0 | float }}</td>
                     <td>{{ report.total_sales_visa || 0 | float }}</td>
                     <td>{{ report.end_visa || 0 | float }}</td>
                     <td>{{ report.diff_visa || 0 | float }}</td>
                  </tr>
                  <tr>
                     <td>{{ $t('master card') }}</td>
                     <td>{{ 0 | float }}</td>
                     <td>{{ report.total_sales_master || 0 | float }}</td>
                     <td>{{ report.end_master || 0 | float }}</td>
                     <td>{{ report.diff_master || 0 | float }}</td>
                  </tr>
                  <tr>
                     <td>{{ $t('mada') }}</td>
                     <td>{{ 0 | float }}</td>
                     <td>{{ report.total_sales_mada || 0 | float }}</td>
                     <td>{{ report.end_mada || 0 | float }}</td>
                     <td>{{ report.diff_mada || 0 | float }}</td>
                  </tr>
                  <tr>
                     <td>{{ $t('tabby') }}</td>
                     <td>{{ 0 | float }}</td>
                     <td>{{ report.total_sales_tabby || 0 | float }}</td>
                     <td>{{ report.end_tabby || 0 | float }}</td>
                     <td>{{ report.diff_tabby || 0 | float }}</td>
                  </tr>
                  <tr>
                     <td>{{ $t('tamara') }}</td>
                     <td>{{ 0 | float }}</td>
                     <td>{{ report.total_sales_tamara || 0 | float }}</td>
                     <td>{{ report.end_tamara || 0 | float }}</td>
                     <td>{{ report.diff_tamara || 0 | float }}</td>
                  </tr>
                  <tr>
                     <td>{{ $t('STC Pay') }}</td>
                     <td>{{ 0 | float }}</td>
                     <td>{{ report.total_sales_stc || 0 | float }}</td>
                     <td>{{ report.end_stc || 0 | float }}</td>
                     <td>{{ report.diff_stc || 0 | float }}</td>
                  </tr>
                  <tr>
                     <td>{{ $t('nearpay') }}</td>
                     <td>{{ 0 | float }}</td>
                     <td>{{ report.total_sales_nearpay || 0 | float }}</td>
                     <td>{{ report.end_nearpay || 0 | float }}</td>
                     <td>{{ report.diff_nearpay || 0 | float }}</td>
                  </tr>
                  <tr>
                     <td>{{ $t('other') }}</td>
                     <td>{{ 0 | float }}</td>
                     <td>{{ report.total_sales_other || 0 | float }}</td>
                     <td>{{ report.end_other || 0 | float }}</td>
                     <td>{{ report.diff_other || 0 | float }}</td>
                  </tr>
                  <tr>
                     <td>{{ $t('transfer') }}</td>
                     <td>{{ 0 | float }}</td>
                     <td>{{ report.total_sales_trans || 0 | float }}</td>
                     <td>{{ report.end_trans || 0 | float }}</td>
                     <td>{{ report.diff_trans || 0 | float }}</td>
                  </tr>
               </table>
            </v-card>
         </v-col>


      </v-row>
   </section>
</template>



<script>
export default {
   name: "ShiftReportTemplate1",
   props: {
      report: { default: Object },
      printStaticData: { default: Object },
   },
   data: () => ({

   }),
   watch: {
   },
   components: {

   },
   computed: {

   },
   mounted() {

   },
   methods: {

   },

};
</script>
<style lang="scss" scoped>
.shift_master_table {
   td {
      padding: 0 0 0 13px !important;
   }

   td,
   th,
   tr {
      background: transparent;

      &:hover {
         background: transparent !important;
      }
   }
}

.summary_row {
   padding: 0.4rem 0.2rem;
   border-bottom: 2px solid #eee;
   font-size: 12px !important;
   display: flex !important;
   justify-content: space-between !important;
}

td {
   padding: 1.2rem 0.4rem;
   font-size: 12px !important;
}

td:nth-of-type(1),
td:nth-of-type(4) {
   color: var(--gray7) !important;
}

#print_title {
   width: fit-content;
   text-align: center;
   font-size: 12px !important;
   color: var(--blue1);
   background: var(--blue12);
   display: block;
   padding: 1.2rem;
   margin: 30px auto;
}

.mini_table_two {
   width: 100%;
   border-collapse: collapse !important;

   tr {
      border: 1px solid #f7f9fb !important;

      th {
         text-transform: capitalize;

      }

      td,
      th {
         border: 1px solid #eee !important;
         background: #fff !important;
         height: 34.5px !important;
         font-size: 12px !important;
         padding: 12px;

      }

      td {
         background-color: #f5f6fa;
      }

      &:nth-of-type(odd) {

         td,
         th {
            background: #f7f9fb !important;
         }
      }

      // &:last-of-type {
      //
      //    td,
      //    th {
      //       font-weight: bold;
      //    }
      // }
   }
}
</style>