<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-sheet color="lighten-4" class="mb-5 rounded-lg shadow pa-3">
        <v-row align="center">
          <v-col cols="3">
            <v-skeleton-loader class="mx-auto" max-width="300" type="list-item-two-line"></v-skeleton-loader>
          </v-col>
          <v-col cols="auto">
            <v-divider class="d-inline" vertical inset="inset"></v-divider>
          </v-col>
          <v-col cols="3">
            <v-skeleton-loader class="mx-auto" max-width="300" type="list-item-two-line"></v-skeleton-loader>
          </v-col>
          <v-col cols="auto">
            <v-divider class="d-inline" vertical inset="inset"></v-divider>
          </v-col>
          <v-col cols="3">
            <v-skeleton-loader class="mx-auto" max-width="300" type="list-item-two-line"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-sheet>
      <!-- <v-sheet color="lighten-4" class="mb-5 rounded-lg shadow pa-5">
        <v-row align="center">
          <v-col cols="3" class="pa-1" v-for="(index) in 8" :key="index">
            <v-skeleton-loader class="mx-auto" max-width="100" max-height="55" type="actions"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-sheet> -->
      <v-sheet color="lighten-4" class="my-5 rounded-lg shadow pa-5">
        <v-row align="center">
          <v-col cols="2" class="pa-1" v-for="(index) in 32" :key="index">
            <v-skeleton-loader class="mx-auto" max-width="100" max-height="55" type="actions"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
    <v-col cols="12" md="6">
      <v-skeleton-loader type="table"></v-skeleton-loader>
    </v-col>
  </v-row>
</template>








<script>
export default {
  name: "SkeletonInvoiceLoader",
};
</script>
