<template>
  <span>
    <div style="position: absolute; top: -100000000000px;">
      <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="false" :preview-modal="true"
        :paginate-elements-by-height="$store.state.Settings.windowSize.x" :filename="attachmentFileName"
        :pdf-quality="1.8" :manual-pagination="true" pdf-format="a4" pdf-orientation="portrait"
        :pdf-content-width="$store.state.Settings.windowSize.y" ref="html2Pdf">
        <section slot="pdf-content" :dir="$vuetify.rtl ? 'rtl' : 'ltr'">
          <!-- :html-to-pdf-options="htmlToPdfOptions" -->
          <PrintPDF :tableHeight="'auto'" :header="header" :maxPDFRows="maxPDFRows" :printTitle="printTitle"
            :printSubTitle="printSubTitle" :printSubTitleLeft="printSubTitleLeft" :printSubTitleRight="printSubTitleRight"
            :customize_header_footer="customize_header_footer" :data="exportData || []" :printStaticData="printStaticData"
            :header_slot="header_slot" :footer_slot="footer_slot" :showGroupHeader="groupHeader.length"
            :groupHeader="groupHeader" :footerData="footerData || []">
            <template v-slot:pdf_header="{ page, pages_count, isLastPage }">
              <slot name="pdf_header" :page="page" :pages_count="pages_count" :isLastPage="isLastPage" />
            </template>
            <template v-slot:pdf_footer="{ page, pages_count, isLastPage }">
              <slot name="pdf_footer" :page="page" :pages_count="pages_count" :isLastPage="isLastPage" />
            </template>
            <template v-slot:td="{ row, header, index }">
              <slot name="td" :row="row" :header="header" :index="index"></slot>
            </template>
          </PrintPDF>
        </section>
      </vue-html2pdf>
    </div>


  </span>
</template>


<script>
import VueHtml2pdf from 'vue-html2pdf';
import PrintPDF from "../PrintPDF.vue";
export default {
  name: "ExportNow",
  props: {
    excel: { default: false },
    image: { default: true },
    disabled: { default: false },
    loading: { default: false },
    exportData: { default: [] },
    footerData: { default: [] },
    header: { default: [] },
    attachmentFileName: { default: 'مرفق' },
    printTitle: { default: null },
    printSubTitle: { default: null },
    printSubTitleLeft: { default: null },
    printSubTitleRight: { default: null },
    maxPDFRows: { default: 48 },
    groupHeader: { default: [] },
    showGroupHeader: { default: false },
    customize_header_footer: { default: false },
    printStaticData: { default: Object },
    header_slot: { default: false },
    footer_slot: { default: false },
    printNow: { default: false },
  },
  data: () => ({
    printLoading: false,
  }),
  computed: {

  },
  watch: {
    printNow() {
      if (this.printNow) {
        this.excel ? this.exportExcel() : this.exportPDF()
        setTimeout(() => {
          this.printNow = false
        }, 1000);
      }
    }
  },
  components: {
    VueHtml2pdf,
    PrintPDF,
  },
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {
    exportPDF() {
      this.$refs.html2Pdf.generatePdf();
      this.printLoading = true
      setTimeout(() => { // fake loader
        this.printLoading = false;
      }, this.exportData > 500 ? 5000 : 2000);
    },
    exportExcel() {
      this.printLoading = true
      setTimeout(() => { // fake loader
        this.printLoading = false;
      }, this.exportData > 500 ? 1000 : 500);

      this.$global.ExportExcel(
        this.exportData,
        this.header,
        this.footerData || [],
        this.$i18n.t(this.attachmentFileName)
      )

      // let header = [...this.header];
      // let content = [...this.exportData] || [];
      // let excel_header = []
      // header.forEach(head => {
      //   head.label = head.print_text ? this.$i18n.t(head.print_text) : this.$i18n.t(head.text);
      //   switch (head.type) {
      //     case 'float':
      //       head.value = (row) => {
      //         let val = row[head.key] || 0
      //         return this.$global.NumberWithCommas(val.toFixed(2))
      //       };
      //       break;
      //     case 'percentage':
      //       head.value = (row) => (row[head.key] || 0) + '%';
      //       break;
      //     default:
      //       head.value = head.export_key || head.key
      //       break;
      //   }
      //   if (head.type !== 'actions' && head.type !== 'img' && head.type !== 'print' && head.type !== 'switch' && !head.hideInPrint) {
      //     excel_header.push(head)
      //   }
      // });
      // if (this.footerData.length) {
      //   let footer = [...this.footerData];
      //   footer.forEach(head => {
      //     head.label = this.$i18n.t(head.text);
      //     switch (head.type) {
      //       case 'float':
      //         head.value = (row) => {
      //           let val = row[head.key] || 0
      //           return this.$global.NumberWithCommas(val.toFixed(2))
      //         };
      //         break;
      //       default:
      //         head.value = head.key
      //         break;
      //     }
      //   });
      //   content.push(footer[0])
      // }
      // let data = [
      //   {
      //     sheet: this.$i18n.t(this.attachmentFileName),
      //     columns: excel_header,
      //     content: content || [],
      //   },
      // ]

      // let settings = {
      //   fileName: this.pageData ? this.pageData.entityName : this.$i18n.t(this.attachmentFileName),
      //   extraLength: 3,
      //   writeMode: "writeFile",
      //   writeOptions: {},
      //   RTL: this.$vuetify.rtl,
      // }

      // xlsx(data, settings);
    },
  },
};
</script>



<style scoped lang="scss">
.category {
  padding: 3px;
  min-width: 110px;
}
</style>
