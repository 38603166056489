<template>
  <section>
    <v-col cols="12" class="pa-0" sm="12">
      <v-row align="center">
        <!-- Loop -->
        <v-col cols="6" lg="3" md="4" class="category" v-for="(category, index) in (goldOnly == false ? categories : goldCategories)" :key="index" >
          <!-- if category is Diamond -->


          <!-- if category 18 ,21 , 22 ,24 -->
          <div v-if="category.id == 18 || category.id == 21 || category.id == 22 || category.id == 24" class="light rounded-lg cursor_pointer caption px-1" v-on:click="caliber = category.id"
            :class="caliber != category.id ? 'text--disabled' : 'font-weight-bold'">
            <div class="d-flex align-center" style="height: 20px">
              <img src="../assets/img/svg/dollar.svg" v-if="gold_price[`price_${category.id}`]" height="13px" class="mx-1" alt="Qarat-POS" />
              <span class="text--disabled" v-if="gold_price[`price_${category.id}`]">{{ gold_price[`price_${category.id}`] | float }} {{ $t('ryal') }}</span>
            </div>
            <v-icon color="#0A6ADA" size="14" :disabled="caliber != category.id">mdi-check-circle</v-icon>
             {{ category.title }}
          </div>

          <div v-else class="light rounded-lg d-flex align-center cursor_pointer caption px-1" v-on:click="caliber = category.id"
            :class="caliber != category.id ? 'text--disabled' : 'font-weight-bold'" style="height: 40px" >
            <v-icon color="#0A6ADA" class="mx-1" size="14" :disabled="caliber != category.id">mdi-check-circle</v-icon>
             {{ category.title }}
          </div>

        </v-col>
        <!-- End Of loop -->
      </v-row>
    </v-col>
  </section>
</template>


<script>
export default {
  name: "CategoriesBar",
  props: {
    backValueMethod: { type: Function },
    categories: { default: [] },
    gold_price: { default: {} },
    activeCategory: { default: 18 },
    goldOnly: { default: false },
  },
  data: () => ({
    caliber: null
  }),
  computed: {
    goldCategories(){
      return this.categories.filter((category)=> category.id == 18 || category.id == 21 || category.id == 22 || category.id == 24)
    }
  },
  watch: {
    caliber() {
      this.backValueMethod(this.caliber)
    }
  },
  mounted() {
    this.caliber = this.activeCategory
  },
  beforeDestroy: function () {

  },
  methods: {

  },
};
</script>



<style scoped lang="scss">
.category {
  padding: 3px;
  min-width: 110px;
}
</style>
