<template>
  <v-container color="white" class="pb-3">
    <v-row justify="center">
      <!-- once -->
      <v-col cols="8" md="auto">
        <v-row>
          <v-col cols="auto">
            <img src="../assets/img/svg/gold_price.svg" alt="Qarat-POS" />
          </v-col>
          <v-col cols="auto">
            <h5>{{ $t('2onsa') }} </h5>
            <p class="body-1 font-weight-bold my-1 d-flex align-center">
              <strong class="d-flex align-center">
                {{ gold_price.ounce_price || 0 | float }} {{ $t("dollar") }}
              </strong>
            </p>
          </v-col>
        </v-row>
      </v-col>
      <!-- Calibers -->
      <v-col cols="5" md="auto" v-for="(caliber) in [18, 21, 22, 24]" :key="caliber" class="mb-2">
        <v-row>
          <v-col cols="auto">
            <div class="d-block pa-2 amber darken-1 rounded-pill"></div>
          </v-col>
          <v-col cols="auto">
            <h5 class="text--disabled">{{ $t('gold') }} {{ $t('caliber') }} {{ caliber }}</h5>

            <v-tooltip color="success" bottom>
              <template v-slot:activator="{ on, attrs }">
                <p v-bind="attrs" v-on="on" class="body-1 font-weight-bold my-1 success--text d-flex align-center">
                  {{ gold_price_sales[`gold_price_sales_${caliber}`] | float }} {{ $t('sar') }}
                </p>
              </template>
                <span>{{ $t('selling price') }}</span>
            </v-tooltip>

            <v-tooltip color="error" bottom>
              <template v-slot:activator="{ on, attrs }">
                <p v-bind="attrs" v-on="on" class="body-1 font-weight-bold my-1 red2--text d-flex align-center">
                  {{ gold_price_purchase[`gold_price_purchase_${caliber}`] | float }} {{ $t('sar') }}
                </p>
              </template>
              <span>{{ $t('purchase price') }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>



<script>
export default {
  name: "GoldPrice",
  props: {
    backValueMethod: { type: Function },
    gold_price: { type: Object },
    gold_price_direction: { type: Object },
    gold_price_purchase: { type: Object },
    gold_price_sales: { type: Object },
  },
  computed: {

  },
};
</script>
