import i18n from "@/plugins/i18n";
import Vue from "vue";

// import axios from "axios";
export default {
  namespaced: true,
  state: {
    bill: [],
    vats: {},
    gold_price: {},
    discountDialog: false,
    totalPrice: 0,
    totalVat: 0,
    priceWithoutVat: 0,
    totalWeight: 0,
    totalPieces: 0,
    totalPricePieces: 0,
    priceWithoutDiscount: 0,
    customerType: 1,
    isTypesLoading: false,
    totalDiscount: 0,
    totalDiscountVat: 0,
    totalPriceWithVat: 0,
    priceWithDiscount: 0,
    discountForEachItem: 0,
    error: null,
    discount: {
      type: 1,
      value: 0,
      discount_amount: 0,
      percent_value: 0,
      discount_Items: [],
      is_coupon: 0,
      coupon_id: 0,
      coupon: {},
    },
  },
  getters: {
    bill: (state) => {
      return state.bill;
    },
    goldType: (state) => {
      return state.goldType;
    },
    totalPriceWithDiscount: (state) => {  // without vat
      if (state.discount.type == 1) {
        return Number(state.totalPrice) - state.discount.value;
      }
      if (state.discount.type == 2) {
        return (
          Number(state.totalPrice) -
          Number(state.totalPrice) * (state.discount.value / 100)
        );
      } else {
        return Number(state.totalPrice);
      }
    },
  },
  mutations: {
    UPDATE_INVOICE(state, bill) {
      state.discountDialog = false;
      console.log(bill);
      state.bill = bill;
      var total = state.bill.reduce((oldValue, newValue) => {
        return Number(+oldValue) + +newValue.price;
      }, 0);
      var priceWithoutVat = state.bill.reduce((oldValue, newValue) => {
        return Number(+oldValue) + +newValue.priceWithoutVat;
      }, 0);
      var weight = state.bill.reduce((oldValue, newValue) => {
        return Number(+oldValue) + +newValue.gold_weight;
      }, 0);
      state.totalPieces = state.bill.reduce((oldValue, newValue) => {
        return Number(+oldValue) + +newValue.countPieces;
      }, 0);
      state.totalPricePieces = state.bill.reduce((oldValue, newValue) => {
        return Number(+oldValue) + +newValue.countPiecesPrice;
      }, 0);
      state.totalVat = state.bill.reduce((oldValue, newValue) => {
        return Number(+oldValue) + +newValue.vat;
      }, 0);
      state.totalDiscount = state.bill.reduce((oldValue, newValue) => {
        return Number(+oldValue) + +newValue.discount_value;
      }, 0);
      state.totalDiscountVat = state.bill.reduce((oldValue, newValue) => {
        return Number(+oldValue) + +newValue.countPieces;
      }, 0);
      state.totalPriceWithVat = state.bill.reduce((oldValue, newValue) => {
        return Number(+oldValue) + +newValue.countPieces;
      }, 0);
      state.priceWithDiscount = state.bill.reduce((oldValue, newValue) => {
        return Number(+oldValue) + +newValue.countPieces;
      }, 0);
      state.priceWithoutDiscount = state.bill.reduce((oldValue, newValue) => {
        return Number(+oldValue) + (+newValue.priceWithoutDiscount || +newValue.price);
      }, 0);
      state.totalPrice = total;
      state.priceWithoutVat = priceWithoutVat;
      state.totalWeight = weight;
    },
    UPDATE_TYPES(state, goldType) {
      state.goldType = goldType;
    },
    UPDATE_CUSTOMER_TYPE(state, type) {
      state.customerType = type
    },
    UPDATE_ERROR(state, error) {
      state.error = error;
    },
    DEFAULT_DATA(state, data) {
      state.vats = data.vats;
      state.gold_price = data.gold_price;
    },
    CLEAR_DISCOUNT(state) {
      state.priceWithoutDiscount = 0;
      state.totalDiscount = 0
      state.discount = {
        type: 1,
        value: 0,
        discount_amount: 0,
        percent_value: 0,
        discount_Items: [],
        is_coupon: 0,
        coupon_id: 0,
        coupon: {},
      }
    },
    CLEAR_Bill(state) {
      state.bill = [];
      state.goldType = [];
      state.totalPrice = 0;
      state.totalVat = 0;
      state.priceWithoutDiscount = 0;
      state.totalDiscount = 0
      state.priceWithoutVat = 0;
      state.totalWeight = 0;
      state.isTypesLoading = false;
      state.discount = {
        type: 1,
        value: 0,
        discount_amount: 0,
        percent_value: 0,
        discount_Items: [],
        is_coupon: 0,
        coupon_id: 0,
        coupon: {},
      }
      state.error = null;
    },
  },
  actions: {
    changeCustomerType({ commit, state }, data) {
      commit("UPDATE_CUSTOMER_TYPE", data);
      let itemHasPriceEntered = state.bill.filter((detail) => {
        if (detail.categoryId >= 18 && detail.categoryId <= 24) {
          detail.required_price = detail.required_price || 0
          if (+detail.price >= +detail.required_price) return detail
        } else {
          if (+detail.price > 0) return detail;
        }
      })
      if (state?.discount?.percent_value) {
        commit("CLEAR_DISCOUNT");
        commit("CLEAR_Bill");
      }
      commit("UPDATE_INVOICE", itemHasPriceEntered);
    },
    addBillAction({ commit, state }, data) {
      if (state?.discount?.percent_value) {
        commit("CLEAR_DISCOUNT");
        commit("CLEAR_Bill");
      }
      commit("UPDATE_INVOICE", state.bill.concat(data));
    },
    clearBill({ commit }) {
      commit("CLEAR_Bill");
    },
    deleteBillAction({ state, commit }, index) {
      if (state?.discount?.percent_value) {
        commit("CLEAR_DISCOUNT");
        commit("CLEAR_Bill");
      }
      state.bill.splice(index, 1);
      commit("UPDATE_INVOICE", state.bill)
    },
    setDiscount({ state, dispatch }, data) {
      let discount_amount = 0;
      let percent_value = 0;
      let totalInvoice = 0;
      let minimum = 0;
      let discountType = 1;
      let invoiceItems = state.bill;
      let couponItems = [];
      // 
      console.log('data', data);
      if (data.is_coupon) {
        minimum = data.coupon?.minimum;
        couponItems = data.coupon.items.length
          ? invoiceItems.filter(({ categoryId, subCategoryId }) => data.coupon.items.includes(`${categoryId}-${subCategoryId}`))
          : invoiceItems;
        if (data.coupon.discount_type == 1) {  // discount on wages
          totalInvoice = couponItems.reduce((oldValue, newValue) => {
            let weight = (newValue.gold_weight || newValue.diamond_weight || newValue.stone_weight) // weight
            let gold_price = newValue.categoryId >= 18 && newValue.categoryId <= 24 ? state.gold_price[`price_${newValue.categoryId}`] : 0 // caliber price
            let wages = weight * gold_price
            let different = +newValue.priceWithoutVat - wages; // separate wages from price (without vat) المكسب
            return +oldValue + +different  // total invoice without wages
          }, 0);

        } else { // discount on total price
          totalInvoice = couponItems.reduce((oldValue, newValue) => +oldValue + +newValue.priceWithoutVat, 0); // total invoice price
        }
        discountType = data.coupon?.coupon_type || 1;  // 2=> percentage, 1=> value
      } else {
        couponItems = state.bill;
        totalInvoice = state.priceWithoutVat || 0;
        minimum = state.priceWithoutVat || 0;
        discountType = data.type || 1
      }
      console.log('totalInvoice', totalInvoice);
      if (discountType == 1 && totalInvoice >= minimum) {
        discount_amount = totalInvoice - data.value;
        percent_value = (data.value / totalInvoice) * 100;
      }
      if (discountType == 2 && totalInvoice && totalInvoice >= minimum) {
        discount_amount = totalInvoice - (totalInvoice * (data.value / 100));
        percent_value = data.value;
      }
      if (totalInvoice < minimum) {
        Vue.prototype.$global.ShowModal('error', i18n.t('error in use coupon'), i18n.t('minimum price that can use it with coupon') + ' ' + Vue.prototype.$global.DecimalFormatter(minimum.toFixed(2)));
        setTimeout(() => {
          state.discountDialog = true
        }, 3000);
      }
      const discountCalc = {
        type: data.type,
        value: data.value,
        discount_amount: discount_amount,
        percent_value: percent_value,
        is_coupon: data.is_coupon,
        user_id: data.user_id,
        coupon_id: data.coupon_id,
        coupon: data.coupon,
        discount_Items: data.is_coupon ? couponItems : invoiceItems,
      }
      dispatch("calculate_discount", discountCalc);
    },
    calculate_discount({ commit, state }, data) {
      let invoiceItems = [...state.bill]
      state.discount = data;
      data.discount_Items.forEach(item => {
        item.has_discount = 1;
        item.vatWithoutDiscount = +item.vat // price before discount
        item.priceWithoutDiscount = item.price_before_discount = +item.price // price before discount
        item.tax_percent = state.vats[item.categoryId]; // gold vat percent
        item.discount_percentage = data.percent_value;
        item.discount_type = data.type;
        item.discount_user = data.user_id;
        if (data.coupon.discount_type == 1) { // discount on wages 
          let weight = (item.gold_weight || item.diamond_weight || item.stone_weight) // weight
          let gold_price = item.categoryId >= 18 && item.categoryId <= 24 ? state.gold_price[`price_${item.categoryId}`] : 0 // caliber price
          item.wages = weight * gold_price // calculate wages (without vat) (weight X caliber price) الاجور
          item.different = +item.priceWithoutVat - item.wages; // separate wages from price (without vat) المكسب
          item.discount_value = +item.different * (data.percent_value / 100); // convert discount percentage to value
          item.total_discount = +item.different - item.discount_value; // value of discount (without vat)
          item.total_price_with_discount = +item.wages + item.total_discount; // price with discount without vat = المكسب + الاجور بعد الخصم
          console.log('item', item);
        }
        else {  // discount on total price
          item.discount_value = item.total_discount = +item.priceWithoutVat * (data.percent_value / 100); // convert discount percentage to value
          item.total_price_with_discount = +item.priceWithoutVat - item.total_discount; // price with discount without vat
        }
        item.total_vat_after_discount = item.total_price_with_discount * item.tax_percent; //  vat with discount
        item.total_price_after_discount_with_vat = item.total_price_with_discount + item.total_vat_after_discount; //  price with discount with vat
        item.price = +item.total_price_after_discount_with_vat; //  price with discount with vat
        item.countPiecesPrice = +item.total_price_after_discount_with_vat; //  price with discount with vat
        item.vat = +item.total_vat_after_discount; //  vat with discount
        let getIndex = invoiceItems.findIndex(existItem => existItem.categoryId === item.categoryId && existItem.subCategoryId === item.subCategoryId && existItem.gold_weight === item.gold_weight);
        invoiceItems[getIndex] = { ...item }
      });
      state.discountDialog = false
      commit("UPDATE_INVOICE", invoiceItems)
    },
    toggleDiscountDialog({ state }) {
      state.discountDialog = !state.discountDialog
    }
  },
};
