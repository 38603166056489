<template>
  <router-view></router-view>
</template>



<script>

export default {
  name: "ShiftReports",
  mounted() {

  }
};
</script>
