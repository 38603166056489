<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <button v-bind="attrs" v-if="!stock_watch" v-on="on" v-ripple class="itemBtn">
        {{ $vuetify.rtl == true ? goldType.name_ar : goldType.name_en }}
      </button>
      <button v-bind="attrs" v-on="on" v-ripple :disabled="goldType.stock <= 0"
        :class="goldType.stock <= 0 ? 'light' : 'gradients'" class="itemBtn" elevation="0" min-height="55"
        max-height="100" v-if="stock_watch">
        <p class="ma-0" v-if="goldType.stock > 0">
          <small>{{ $vuetify.rtl == true ? goldType.name_ar : goldType.name_en }}</small>
        </p>
        <span v-if="goldType.stock <= 0">
          <small>
            <p class="ma-0 error--text">{{ $t('notAvail') }}</p>
            <p class="ma-0" style="color: #888">{{ $vuetify.rtl == true ? goldType.name_ar : goldType.name_en }}
            </p>
          </small>
        </span>
      </button>
    </template>
    <v-card relative>
      <v-btn absolute class="mt-2" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
        color="grey lighten-1" text @click="dialog = false">
        <v-icon color="grey lighten-1">mdi-close</v-icon>
      </v-btn>
      <v-card-title class="d-flex justify-space-between py-0">
        <v-col cols="auto">
          <v-row>
            <v-col cols="auto">
              <v-icon small color="blue accent-4" left>mdi-check-circle</v-icon>
            </v-col>
            <v-col cols="auto">
              <h6 class="gray6--text">{{ goldType.name_ar }}</h6>
              <h6 class="my-0">
                <strong>
                  {{ $t('caliber') }} {{ caliber }}
                  <small class="gray6--text">{{ goldType.caliber_price | float }} {{
                    $t('ryal')
                  }}</small>
                </strong>
              </h6>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto">
          <v-row>
            <v-col cols="auto">
              <v-icon small color="blue accent-4" left>mdi-check-circle</v-icon>
            </v-col>
            <v-col cols="auto">
              <h6 class="gray6--text">{{ $t('totalweight') }}</h6>
              <h6 class="my-0">
                <strong>{{ $global.DecimalFormatter(goldType.stock.toFixed(2)) }} {{ $t('gram') }}</strong>
              </h6>
            </v-col>
          </v-row>
        </v-col>
      </v-card-title>
      <v-divider />
      <div class="pa-5 pt-0" v-if="activeStep == 1">
        <v-col cols="12" sm="11" class="ma-auto">
          <h5 class="mb-5">{{ $t('pleaseEnterWeight') }}</h5>
          <NumberPad :activePad="weightForm" :backValueMethod="setWeight" :value="weight"></NumberPad>
        </v-col>

        <v-col cols="11" class="py-0 ma-auto">
          <v-btn color="success" large :disabled="!validWeight" @click="activeStep = 2" class="shadow"
            style="width: 100%">{{ $t('next') }}</v-btn>
        </v-col>
      </div>
      <div class="pa-5 pt-0">
        <v-col cols="12" sm="12" v-if="activeStep == 2">
          <v-row justify="center" align="center">
            <v-col cols="6" class="py-3">
              <div class="d-flex justify-space-between align-center light pa-2 rounded-lg shadow">
                <div>
                  <h5>
                    {{ $t('weight') }} :
                    <br />
                    <span class="gray6--text">{{ $global.DecimalFormatter(Number(weight).toFixed(2)) }} {{ $t('gram')
                      }}</span>
                  </h5>
                </div>
                <v-btn small color="error" @click="activeStep = 1">
                  {{ $t('modify') }}
                  <img src="@/assets/img/svg/editIcon.svg" alt="qarat" />
                </v-btn>
              </div>
            </v-col>
            <v-col cols="6" class="py-3">
              <div class="d-flex justify-space-between align-center light pa-2 rounded-lg shadow">
                <div>
                  <h5>
                    {{ $t('minimum price') }}:
                    <div class="gray6--text">{{ minPrice | float }}</div>
                  </h5>
                </div>
              </div>
            </v-col>
            <v-col cols="11" class="py-0">
              <div class="d-flex mb-5 justify-space-between align-center">
                <h5>
                  {{ $t('pleaseEnterThePrice') }}
                </h5>
                <h5 v-if="costIdentityDetail.d_id">
                  {{ $t('available discount') }} {{ discountsExample.percent }}% :
                  <span class="gray6--text">{{ discountsExample.amount | float }}</span>
                </h5>

              </div>
              <NumberPad :activePad="!weightForm" :backValueMethod="setPrice" :value="price" />
            </v-col>

          </v-row>
        </v-col>
        <v-col cols="12" class="ma-auto pa-0" sm="11" v-if="activeStep == 3">
          <v-row justify="center">
            <v-col cols="6" class="py-5">
              <div class="d-flex justify-space-between align-center light pa-2 rounded-lg shadow">
                <div>
                  <h5>
                    {{ $t('weight') }} :
                    <br />
                    <span class="gray6--text">{{ $global.DecimalFormatter(Number(weight).toFixed(2)) }} {{ $t('gram')
                      }}</span>
                  </h5>
                </div>
                <v-btn small color="error" @click="activeStep = 1">
                  {{ $t('modify') }}
                  <img src="@/assets/img/svg/editIcon.svg" alt="qarat" />
                </v-btn>
              </div>
            </v-col>
            <v-col cols="6" class="py-5">
              <div class="d-flex justify-space-between align-center light pa-2 rounded-lg shadow"
                v-if="activeStep == 3">
                <div>
                  <h5>
                    {{ $t('price') }} :
                    <br />
                    <span class="gray6--text">{{ $global.DecimalFormatter(Number(price).toFixed(2)) }} {{ $t('ryal')
                      }}</span>
                  </h5>
                </div>
                <v-btn small color="error" @click="activeStep = 2">
                  {{ $t('modify') }}
                  <img src="@/assets/img/svg/editIcon.svg" alt="qarat" />
                </v-btn>
              </div>
            </v-col>
            <v-col cols="11" sm="11" class="py-0">
              <h5 class="mb-5">{{ $t('please enter pieces') }}</h5>
              <NumberPad :activePad="piecesForm" :backValueMethod="setPieces" :disabledDot="true" :value="countPieces">
              </NumberPad>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="ma-auto py-2 pa-0" sm="11" v-if="activeStep == 4">
          <v-row>
            <v-col cols="6">
              <div class="d-flex justify-space-between align-center light pa-2 rounded-lg shadow">
                <div>
                  <h5>
                    {{ $t('weight') }} :
                    <br />
                    <span class="gray6--text">{{ $global.DecimalFormatter(Number(weight).toFixed(2)) }} {{ $t('gram')
                      }}</span>
                  </h5>
                </div>
                <v-btn small color="error" @click="activeStep = 1">
                  {{ $t('modify') }}
                  <img src="@/assets/img/svg/editIcon.svg" alt="qarat" />
                </v-btn>
              </div>
            </v-col>
            <v-col cols="6">
              <div class="d-flex justify-space-between align-center light pa-2 rounded-lg shadow">
                <div>
                  <h5>
                    {{ $t('price') }} :
                    <br />
                    <span class="gray6--text">{{ $global.DecimalFormatter(Number(price).toFixed(2)) }} {{ $t('ryal')
                      }}</span>
                  </h5>
                </div>
                <v-btn small color="error" @click="activeStep = 2">
                  {{ $t('modify') }}
                  <img src="@/assets/img/svg/editIcon.svg" alt="qarat" />
                </v-btn>
              </div>
            </v-col>
            <v-col cols="6">
              <div class="d-flex justify-space-between align-center light pa-2 rounded-lg shadow">
                <div>
                  <h5>
                    {{ $t('count pieces') }} :
                    <br />
                    <span class="gray6--text">{{ countPieces }}</span>
                  </h5>
                </div>
                <v-btn small color="error" @click="activeStep = 3">
                  {{ $t('modify') }}
                  <img src="@/assets/img/svg/editIcon.svg" alt="qarat" />
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" sm="12" class="py-0">
              <v-col cols="12" class="pa-0">
                <label class="mx-2 body-2">{{ $t('lobes') }}</label>
                <v-text-field suffix="" min="1" max="100" autofocus v-model="lobes" background-color="#E8E8EE"
                  class="my-2 rounded grey lighten-5 box-shadow-none" hide-details solo type="number"></v-text-field>
              </v-col>
              <v-col cols="12" class="pa-0">
                <label class="mx-2 body-2">{{ $t('notes') }}</label>
                <v-textarea background-color="#e8e8ee" solo :label="''" auto-grow hide-details rows="3" clearable
                  clear-icon="mdi-close-circle" v-model="notes"></v-textarea>
              </v-col>



            </v-col>
          </v-row>
        </v-col>

        <v-col cols="11" class="py-0 ma-auto" v-if="activeStep == 3 || activeStep == 2">
          <v-btn color="success" large :disabled="!validPrice" @click="activeStep = 4" class="shadow"
            style="width: 100%">{{
              $t('next') }}</v-btn>
        </v-col>
        <v-col cols="12" class="py-0 mt-9 px-4 ma-auto" v-if="activeStep == 4">
          <v-btn color="success" large
            :disabled="!((validPrice && countPieces > 0 && Number(weight) > 0) && !(sales_note ? notes ? false : true : false))"
            @click="openSummary()" class="shadow" style="width: 100%">{{ $t('add') }}</v-btn>
        </v-col>
      </div>
    </v-card>
    <InvoiceSummery :backValueMethod="setData" :dialog="summaryDialog" :summery="summary" :loading="loading"
      :sales_note="sales_note" />
  </v-dialog>
</template>

<script>
import NumberPad from "../NumberPad.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("InvoiceStore");
import InvoiceSummery from "./InvoiceSummery.vue";
export default {
  name: "ItemModal",
  props: ["goldType", "caliber", "vat", 'stock_watch', 'sales_note', 'cost_identity_details'],
  computed: {
    ...mapState(["bill", "customerType"]),
    validWeight() {
      switch (this.stock_watch) {
        case 1:
          if (Number(this.weight) > 0 && Number(this.goldType.stock) >= Number(this.weight)) {
            return true
          }
          else {
            return false
          }
        default:
          if (Number(this.weight) > 0) {
            return true
          }
          else {
            return false;
          }
      }
    },
    validPrice() {
      if (this.$store.state.userData.monitor_sales_price) {
        if (this.customerType == 1) {
          return +this.price >= +this.minPrice.toFixed(2);
        } else {
          return true;
        }
      } else {
        return true
      }
    },
    costIdentityDetail() {
      let item_cost_identity = this.cost_identity_details.filter(({ category_id, m_id, is_default }) => this.goldType.cost_identity ? m_id == this.goldType.cost_identity : category_id == this.goldType.category_id && is_default)
      return item_cost_identity.find(({ weight_from, weight_to }) => this.weight >= weight_from && +this.weight <= weight_to) || {}
    },
    discountsExample() {
      const discount = this.costIdentityDetail.discount_percent || 0
      let discounts = {
        percent: discount,
        amount: this.minPrice * ((100 - discount) / 100),
      }
      return discounts
    }
  },
  components: {
    NumberPad,
    InvoiceSummery
  },
  methods: {
    ...mapActions(["addBillAction"]),

    setWeight(returnedWeight) {
      this.weight = returnedWeight;
    },
    setPrice(returnedPrice) {
      this.price = returnedPrice;
      this.totalPriceWithoutVat = (Number(returnedPrice) / (1 + Number(this.vat)));
      this.totalPriceWithVat = Number(returnedPrice);
      this.totalVat = Number(returnedPrice) - (Number(returnedPrice) / (1 + Number(this.vat)));
      this.minPrice = (Number(+this.weight) * Number(+this.goldType.caliber_price)) * (1 + Number(+this.vat))
    },
    setPieces(returnedPieces) {
      this.countPieces = returnedPieces;
    },
    setData(status) {
      if (status == true) {
        this.summaryDialog = false
        this.addBillAction(this.summary).finally(() => {
          this.weight = '';
          this.price = '';
          this.lobes = null;
          this.notes = null;
          this.countPieces = '1';
          this.activeStep = 1;
          this.summary = {}
          return (this.dialog = false), (this.setActive = true);
        });

      } else {
        this.summaryDialog = false
      }
    },
    openSummary() {
      this.summary = {
        d_id: 0,
        item_code: '',
        subCategoryId: this.goldType.sub_category_id,
        itemTitle: this.goldType.name_ar,
        categoryId: this.goldType.category_id,
        gold_weight: Number(this.weight),
        countPieces: this.countPieces,
        minPrice: this.totalPriceWithoutVat,
        priceWithoutVat: Number(+this.totalPriceWithoutVat),
        vat: this.totalVat,
        price: this.price,
        lobes: this.lobes || 0,
        notes: this.notes,
        caliber_price: this.goldType.caliber_price || 0,
        required_price: this.minPrice || 0,
        countPiecesPrice: Number(+this.totalPriceWithVat) * this.countPieces,
      }
      // this.summaryDialog = true
      this.setData(true);
    },

  },
  watch: {},
  data() {
    return {
      summaryDialog: false,
      summary: {},
      dialog: false,
      checkPricePayed: false,
      totalPriceWithoutVat: 0,
      totalPriceWithVat: 0,
      totalVat: 0,
      minPrice: 0,
      weight: "",
      price: "",
      lobes: null,
      notes: null,
      loading: false,
      countPieces: "1",
      weightForm: true,
      piecesForm: false,
      notesForm: false,
      setActive: false,
      activeStep: 1,
    };
  },
  mounted() {
    window.addEventListener("keydown", e => {
      if (e.key == 'Enter' && this.dialog) {
        if (this.activeStep == 1 && this.validWeight) { // weight active
          this.activeStep = 2  //price toggle
        }
        else if (this.activeStep == 2 && this.validPrice) { // price active
          this.activeStep = 4 //Pieces toggle
        }
        else if (this.activeStep == 3) { // pieces active  
          this.activeStep = 4 //notes toggle
        }

        else if (this.validPrice && this.validWeight && this.activeStep == 4) { //notes active
          this.openSummary()
        }
      }
    });
  },
};
</script>
