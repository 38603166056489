<template>
  <v-dialog v-model="dialog" persistent :max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <button v-bind="attrs" v-on="on" v-ripple class="itemBtn">
        {{ goldType[`name_${$i18n.locale}`] }}
      </button>
    </template>
    <v-card relative>
      <v-btn absolute class="mt-2" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
        color="grey lighten-1" text @click="()=>dialog = false">
        <v-icon color="grey lighten-1">mdi-close</v-icon>
      </v-btn>
      <!-- Title -->
      <v-card-title>
        <v-row class="justify-space-between py-0" align="center">
          <v-col cols="auto">
            <v-row>
              <v-col cols="auto">
                <v-icon small color="blue accent-4" left>mdi-check-circle</v-icon>
              </v-col>
              <v-col cols="auto">
                <h6 class="text--disabled body-2">{{ goldType.category_title }}</h6>
                <h6 class="my-0 body-2">
                  <strong>{{ goldType[`name_${$i18n.locale}`] }}</strong>
                </h6>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider class="my-1"></v-divider>
      <!-- / Title -->

      <!-- weight -->
      <div class="pa-5" v-if="activeStep == 1">
        <v-row align="center" justify="center">
          <v-col cols="10" class="ma-auto">
            <h5 class="mb-5">{{ $t('weight') }} </h5>
            <NumberPad :backValueMethod="setWeight" :value="stoneWeight"></NumberPad>
          </v-col>
          <v-col cols="10" class="pa-0 d-flex justify-end">
            <v-btn color="success" large :disabled="!weightValid" @click="() => activeStep = 2"
              class="shadow mb-3 mx-2">{{
                $t('next') }}</v-btn>
          </v-col>
        </v-row>
      </div>
      <!-- / weight -->


      <!-- Caliber -->
      <div class="pa-5 pt-0" v-if="activeStep == 2">
        <v-row align="center" justify="center">
          <v-col cols="12">
            <h5 class="mb-1">{{ $t('caliber') }}</h5>
            <v-row align="center">
              <v-col cols="6" md="3" class="px-1">
                <div class="light rounded-lg cursor_pointer caption px-1" v-on:click="goldCaliber = 18"
                  :class="goldCaliber != 18 ? 'gray6--text' : 'font-weight-bold'">
                  <div class="d-flex align-center">
                    <img src="@/assets/img/svg/dollar.svg" height="13px" class="mx-1" alt="Qarat-POS" />
                    <span class="gray6--text">{{ gold_price['price_18'].toFixed(2) }} {{ $t('ryal') }}</span>
                  </div>
                  <v-icon color="#0A6ADA" size="14" :disabled="goldCaliber != 18">mdi-check-circle</v-icon>
                  {{ $t('gold') }} {{ $t('caliber') }} 18
                </div>
              </v-col>
              <v-col cols="6" md="3" class="px-1">
                <div class="light rounded-lg cursor_pointer caption px-1" v-on:click="goldCaliber = 21"
                  :class="goldCaliber != 21 ? 'gray6--text' : 'font-weight-bold'">
                  <div class="d-flex align-center">
                    <img src="@/assets/img/svg/dollar.svg" height="13px" class="mx-1" alt="Qarat-POS" />
                    <span class="gray6--text">{{ gold_price['price_21'].toFixed(2) }} {{ $t('ryal') }}</span>
                  </div>
                  <v-icon color="#0A6ADA" size="14" :disabled="goldCaliber != 21">mdi-check-circle</v-icon>
                  {{ $t('gold') }} {{ $t('caliber') }} 21
                </div>
              </v-col>
              <v-col cols="6" md="3" class="px-1">
                <div class="light rounded-lg cursor_pointer caption px-1" v-on:click="goldCaliber = 22"
                  :class="goldCaliber != 22 ? 'gray6--text' : 'font-weight-bold'">
                  <div class="d-flex align-center">
                    <img src="@/assets/img/svg/dollar.svg" height="13px" class="mx-1" alt="Qarat-POS" />
                    <span class="gray6--text">{{ gold_price['price_22'].toFixed(2) }} {{ $t('ryal') }}</span>
                  </div>
                  <v-icon color="#0A6ADA" size="14" :disabled="goldCaliber != 22">mdi-check-circle</v-icon>
                  {{ $t('gold') }} {{ $t('caliber') }} 22
                </div>
              </v-col>
              <v-col cols="6" md="3" class="px-1">
                <div class="light rounded-lg cursor_pointer caption px-1" v-on:click="goldCaliber = 24"
                  :class="goldCaliber != 24 ? 'gray6--text' : 'font-weight-bold'">
                  <div class="d-flex align-center">
                    <img src="@/assets/img/svg/dollar.svg" height="13px" class="mx-1" alt="Qarat-POS" />
                    <span class="gray6--text">{{ gold_price['price_24'].toFixed(2) }} {{ $t('ryal') }}</span>
                  </div>
                  <v-icon color="#0A6ADA" size="14" :disabled="goldCaliber != 24">mdi-check-circle</v-icon>
                  {{ $t('gold') }} {{ $t('caliber') }} 24
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="10" class="py-0">
            <h5 class="mb-5">{{ $t('gold weight') }}</h5>
            <NumberPad :backValueMethod="setGoldWeight" :value="gold_weight"></NumberPad>
          </v-col>
          <v-col cols="10" class="pa-0 d-flex justify-end">
            <v-btn color="error" large outlined @click="() => activeStep = activeStep - 1" class="shadow mb-3 mx-2">{{
              $t('back')
            }}</v-btn>
            <v-btn color="success" large @click="() => activeStep = 3" class="shadow mb-3 mx-2">{{ $t('next') }}</v-btn>
          </v-col>
        </v-row>
      </div>
      <!-- / Caliber -->


      <!-- Price -->
      <div class="pa-5" v-if="activeStep == 3">
        <v-row align="center" justify="center">
          <v-col cols="10" class="ma-auto">
            <h5 class="mb-5">{{ $t('Total including tax') }}</h5>
            <NumberPad :backValueMethod="setPrice" :value="price"></NumberPad>
          </v-col>
          <v-col cols="10" class="pa-0 d-flex justify-end">
            <v-btn color="error" large outlined @click="() => activeStep = activeStep - 1" class="shadow mb-3 mx-2">
              {{ $t('back') }}</v-btn>
            <v-btn color="success" large :disabled="!priceValid" @click="() => setSummary()" class="shadow mb-3 mx-2">
              {{ $t('add') }}</v-btn>
          </v-col>
        </v-row>
      </div>
      <!-- / Price -->
    </v-card>
    <InvoiceSummery :backValueMethod="setData" :dialog="summaryDialog" :summery="summary" />
  </v-dialog>
</template>


<script>
import NumberPad from "../NumberPad.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("InvoiceStore");
import InvoiceSummery from "./InvoiceSummery.vue";
export default {
  name: "DefaultItemSalesModal",
  props: {
    goldType: { type: {} },
    caliber: { default: [] },
    vat: { default: {} },
    vats: { default: {} },
    gold_price: { default: {} },
    stones: { default: [] },
    stock_watch: { default: null },
  },
  computed: {
    ...mapState(["bill", "customerType"]),

    priceValid() {
      if (Number(this.price) > 0) {
        return true
      }
      else {
        return false
      }
    },
    weightValid() {
      if (Number(this.stoneWeight) > 0) {
        return true
      }
      else {
        return false
      }
    },
  },
  watch: {
    price() {
      let goldPrice = this.vats[26];
      this.totalPriceWithoutVat = +this.price / (1 + +goldPrice);
      this.totalVat = +this.price - (+this.price / (1 + +goldPrice));
      this.minPrice = 0
    }
  },
  components: { NumberPad, InvoiceSummery },
  data: () => ({
    summaryDialog: false,
    totalVat: 0,
    minPrice: 0,
    dialog: false,
    checkPricePayed: false,
    totalPriceWithoutVat: 0,
    totalPriceWithVat: 0,
    goldCaliber: 18,
    gold_weight: '',
    price: "",
    stoneWeight: "",
    stone: null,
    setActive: false,
    activeStep: 1,
    summary: {},
  }),
  mounted() {
    window.addEventListener("keydown", event => {
      const isEnterKey = event.key === "Enter";
      if (isEnterKey && this.dialog) {
        switch (this.activeStep) {
          case 1:
          case 2:
            if (this.weightValid) { this.activeStep++; }
            break;
          case 3:
            if (this.priceValid) { this.setSummary(); }
            break;
          default: break;
        }
      }
    });
  },
  methods: {
    ...mapActions(["addBillAction"]),
    setPrice(returnedPrice) {
      this.price = returnedPrice
    },
    setGoldWeight(returnedWeight) {
      this.gold_weight = returnedWeight
    },
    setWeight(returnedWeight) {
      this.stoneWeight = returnedWeight
    },
    setSummary() {
      this.summary = {
        d_id: 0,
        categoryId: this.goldType.category_id,
        subCategoryId: this.goldType.sub_category_id,
        itemTitle: this.goldType[`name_${this.$i18n.locale}`],
        category_title: this.goldType.category_title,
        minPrice: this.minPrice,
        gold_weight: +this.gold_weight,
        priceWithoutVat: this.totalPriceWithoutVat,
        vat: this.totalVat,
        price: +this.price,
        countPieces: 1,
        stone_weight: +this.stoneWeight,
        caliber_id: this.goldCaliber,
      }
      this.setData(true)
    },

    setData(status) {

      if (status == true) {
        this.addBillAction(this.summary).finally(() => {
          this.price = '';
          this.gold_weight = '';
          this.stone_weight = '';
          this.summary = {};
          this.activeStep = 1;
        });
        this.dialog = false
      } else {
        this.summaryDialog = false
      }
    },
    openSummary() {

    },
  },

}

</script>
