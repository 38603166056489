<template>
  <section>
    <v-container fluid>
      <v-row justify="space-between">
        <v-col cols="12" sm="auto" class="d-flex justify-center align-center py-0">
          <div class="subtitle-2 text--disabled">
            <strong>{{ $t('usedInvoice') }} . {{ $t("invoiceList") }}</strong>
          </div>
        </v-col>
        <v-col cols="12" sm="auto" class="d-flex justify-center align-center py-0">
          <!-- <div class="subtitle-2">
            {{ $t('This data is shown starting from') }}
            <span class="text--disabled">
              {{
                  new Date(date).toLocaleDateString(
                    `${$vuetify.rtl == true ? "ar" : "en-UK"}`,
                    {
                      weekday: "short",
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                    }
                  )
              }}
            </span>
          </div> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" class="py-0">
          <DynamicTable :isLoading="isLoading" :searchMethod="searchMethod"
            :controlRoute="'/used-purchases/create-purchases-invoice'" createBtnLabel="createaPurchaseInvoice"
            :printURL="'export_used_purchase'" :data="rows" :header="header">
            <template v-slot:inputs>
              <v-btn :color="isActive ? 'error' : 'primary'" depressed @click="isActive = isActive ? 0 : 1">
                <v-icon left> {{ isActive ? 'mdi-minus-box' : 'mdi-pin' }}</v-icon>
                {{ isActive ? $t('deleted') : $t('pin') }}
              </v-btn>
            </template>
            <template v-slot:td="{ row, header }">
              <div v-if="header.key == 'id'">
                <v-btn :to="`used-purchases/purchases-invoice/${row.id}`"
                  class="text-decoration-underline body-2 font-weight-bold info--text ma-1" small text>
                  {{ row.id }}
                </v-btn>
              </div>
              <div v-if="header.key == 'cracking'" class="d-flex align-center">
                <v-btn v-if="row.invoice_status == 1" :disabled="!isActive"
                  :to="`used-purchases/purchases-invoice/${row.id}`" color="blue1" class="white--text shadow" small>
                  {{ $t('cracking') }}
                </v-btn>
                <v-btn v-else :to="`used-purchases/purchases-invoice/${row.id}`" :disabled="!isActive" color="success"
                  class="white--text" text small>
                  {{ $t('cracking is done') }}
                </v-btn>
              </div>
              <div v-if="header.key == 'actions'" class="d-flex align-center">
                <v-btn color="gray4" class="me-2" @click="printBarcode(row)" x-small depressed fab>
                  <v-icon color="white">mdi-barcode</v-icon>
                </v-btn>
                <v-btn :disabled="toggleIsLoading"
                  @click="$router.push(`/used-purchases/edit-purchases-invoice/${row.id}`)" class="green12 ma-1" x-small
                  icon fab>
                  <v-icon color="green1" v-if="isActive">mdi-pen</v-icon>
                  <v-icon color="green1" v-else>mdi-eye</v-icon>
                </v-btn>
                <v-btn :disabled="toggleIsLoading" @click="toggleActive(row.id)" :class="isActive ? 'red12' : 'blue12'"
                  class="ma-1" x-small icon fab>
                  <v-icon color="error" v-if="isActive">mdi-trash-can</v-icon>
                  <v-icon color="info" v-else>mdi-reload</v-icon>
                </v-btn>
              </div>

            </template>
          </DynamicTable>
        </v-col>
        <v-col cols="12" sm="12" class="py-0" v-if="pagination">
          <Pagination :limit="rowsNumber" :backValueMethod="changePage" :pagination="pagination"
            :isLoading="isLoading" />
        </v-col>
      </v-row>
    </v-container>

    <PurchaseInvoiceModal :dialog="purchaseInvoiceDialog" :backValueMethod="closeDialog" :invoice_id="invoice_id" />
  </section>
</template>



<script>
import DynamicTable from "../../components/DynamicTable";
import Pagination from "../../components/Pagination";
import PurchaseInvoiceModal from "@/components/modals/PurchaseInvoiceModal.vue";
export default {
  name: "PurchasesTable",

  data: () => ({
    isLoading: true,
    rows: [],
    header: [],
    pagination: null,
    page: 1,
    rowsNumber: 25,
    searchText: '',
    from: null,
    to: null,
    date: null,
    purchaseInvoiceDialog: false,
    toggleIsLoading: false,
    invoice_id: 0,
    isActive: 1,
  }),
  components: {
    DynamicTable,
    Pagination,
    PurchaseInvoiceModal,
  },
  computed: {},
  watch: {
    isActive() {
      this.getData()
    }
  },
  mounted() {
    this.pageMainData();
    this.getData();
  },
  methods: {
    pageMainData() {
      this.header = [
        { text: "invoice number", key: "id", type: 'slot', classes: "" },
        { text: "customerName", key: "customer_name", type: 'text', classes: "" },
        { text: "customerMobile", key: "customer_mobile", type: 'text', classes: "" },
        { text: "idNumber", key: "id_number", type: 'text', classes: "" },
        { text: "date", key: "action_date", type: 'date', classes: "" },
        { text: "branch", key: "branch_name", type: 'text', classes: "" },
        { text: "added by", key: "user_full_name", type: 'text', classes: "" },
        { text: "", key: "cracking", type: 'slot', classes: "" },
        { text: "print", key: "id", type: 'print', classes: "" },
      ]
      // if (this.$store.state.userData.super_admin == 1) {
      this.header.push({ text: "actions", key: "actions", type: 'slot', classes: "" })
      // }
    },
    getData() {
      this.isLoading = true;
      if (this.$global.CheckAction("04-008", 1)) {
        this.$api.GET_METHOD(`get_used_purchases?rows=${this.rowsNumber}&page=${this.page}&word=${this.searchText}&date=${this.date || ''}&active=${this.isActive}`).then((response) => {
          this.isLoading = false;
          this.rows = response.data.data.data;
          this.page = response.data.data.current_page;
          this.pagination = {
            page: response.data.data.current_page,
            totalPages: response.data.data.last_page,
            per_page: response.data.data.per_page,
            totalRows: response.data.data.total,
          };
        })
      } else {
        this.isLoading = false;
        this.$router.push('/')
      }
    },
    searchMethod(text, date, from, to) {
      this.searchText = text;
      this.from = from;
      this.to = to;
      this.date = date;
      this.getData()
    },
    changePage(page, limit) {
      this.page = page;
      this.rowsNumber = limit;
      this.getData();
    },
    toggleActive(id) {
      this.toggleIsLoading = true;
      this.$api.POST_METHOD(`used_toggle_active/${id}`).then((response) => {
        this.toggleIsLoading = false;
        if (response.check) {
          this.getData();
        }
      });
    },
    closeDialog() {
      this.purchaseInvoiceDialog = false;
    },
    printBarcode(row) {
      window.open(`${this.$api.serverUrl}used_purchases_barcode?id=${row.id}&type=print&clientID=${this.$store.state.clientID}`, '_blank')
    }
  },
};
</script>
