<template>
  <section>
    <v-container fluid>
      <v-row justify="space-between">
        <v-col cols="12" sm="auto" class="d-flex justify-center align-center py-0">
          <div class="subtitle-2 text--disabled">
            <strong>{{ $t('return sales') }} . {{ $t("invoiceList") }}</strong>
          </div>
        </v-col>
        <v-col cols="12" sm="auto" class="d-flex justify-center align-center py-0">
          <!-- <div class="subtitle-2">
            {{ $t('This data is shown starting from') }}
            <span class="text--disabled">
              {{
              new Date(date).toLocaleDateString(
              `${$vuetify.rtl == true ? "ar" : "en-UK"}`,
              {
              weekday: "short",
              year: "numeric",
              month: "numeric",
              day: "numeric",
              }
              )
              }}
            </span>
          </div> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" class="py-0">
          <DynamicTable :isLoading="isLoading" :searchMethod="searchMethod" :controlRoute="returnBy"
            createBtnLabel="create ruturn sales" :printURL="
              $store.state.permissions.includes('06-500') ? 'export_invoice_return_piece' : 'export_invoice_return_qty'
            " :data="rows" :header="header"></DynamicTable>
        </v-col>
        <v-col cols="12" sm="12" class="py-0" v-if="pagination">
          <Pagination :limit="rowsNumber" :backValueMethod="changePage" :pagination="pagination" :isLoading="isLoading">
          </Pagination>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>



<script>
import DynamicTable from "../../components/DynamicTable";
import Pagination from "../../components/Pagination";
export default {
  name: "ReturnSalesTable",

  data: () => ({
    isLoading: true,
    rows: [],
    header: [],
    pagination: null,
    page: 1,
    rowsNumber: 25,
    searchText: '',
    from: null,
    to: null,
    date: null,
    returnBy: "/return-sales/create-return-sales"
  }),
  components: {
    DynamicTable,
    Pagination,
  },
  computed: {

  },
  mounted() {
    this.pageMainData();
    this.getData();
  },
  methods: {
    pageMainData() {
      this.header = [
        { text: "invoice number", key: "m_id", type: 'text', classes: "" },
        { text: "associated sales invoice", key: "sales_invoice_m_id", type: 'text', classes: "" },
        { text: "customerName", key: "customer_name", type: 'text', classes: "" },
        { text: "customerMobile", key: "customer_mobile", type: 'text', classes: "" },
        { text: "date", key: "invoice_date", type: 'text', classes: "" },
        { text: "print", key: "m_id", type: 'print', classes: "" },
      ]
    },
    getData() {
      this.isLoading = true;
      if (this.$global.CheckAction("06-500", 1)) {
        this.returnBy = "/return-sales/create-return-sales"
        this.$api.GET_METHOD(`get_return_invoices?rows=${this.rowsNumber}&page=${this.page}&word=${this.searchText}&date=${this.date || ''}`).then((response) => {
          this.isLoading = false;
          this.rows = response.data.data.data;
          this.page = response.data.data.current_page;
          this.pagination = {
            page: response.data.data.current_page,
            totalPages: response.data.data.last_page,
            per_page: response.data.data.per_page,
            totalRows: response.data.data.total,
          };
        })
      }
      if (this.$global.CheckAction("06-500", 1)) {
        this.returnBy = "/return-sales/create-return-sales-piece";
        this.$api.GET_METHOD(`get_return_invoices_by_piece?rows=${this.rowsNumber}&page=${this.page}&word=${this.searchText}&date=${this.date || ''}`).then((response) => {
          this.isLoading = false;
          this.rows = response.data.data.data;
          this.page = response.data.data.current_page;
          this.pagination = {
            page: response.data.data.current_page,
            totalPages: response.data.data.last_page,
            per_page: response.data.data.per_page,
            totalRows: response.data.data.total,
          };
        })

      }
    },
    searchMethod(text, date, from, to) {
      this.searchText = text;
      this.from = from;
      this.to = to;
      this.date = date;
      this.getData()
    },
    changePage(page, limit) {
      this.page = page;
      this.rowsNumber = limit;
      this.getData();
    },
  },
};
</script>
