<template>
   <section>
      <v-speed-dial fixed v-model="fav" :bottom="true" :left="!($store.state.Settings.isRtl)"
         :direction="$store.state.Settings.isRtl ? 'left' : 'right'" :open-on-hover="false"
         :transition="'slide-x-transition'">
         <template v-slot:activator>
            <v-btn :key="key" v-model="fab" small style="animation : shake 300ms !important;" color="success" dark fab>
               <v-icon v-if="fav">mdi-close</v-icon>
               <v-icon v-else>mdi-plus</v-icon>
            </v-btn>
         </template>
         <v-card :color="$store.state.Settings.darkTheme == true ? 'backgroundW' : '#ebeff3'" class="d-flex shadow"
            elevation="0">
            <v-btn :min-width="120" small v-if="salesPermission" to="/sales/create-sales-invoice"
               :color="$store.state.Settings.darkTheme == true ? 'backgroundW' : '#fff'" depressed class="shadow"
               exact-active-class="primary">{{ $t("createSalesInvoice") }}</v-btn>
               
            <v-btn :min-width="120" small v-if="purchasePermission" to="/used-purchases/create-purchases-invoice"
               :color="$store.state.Settings.darkTheme == true ? 'backgroundW' : '#fff'" depressed class="shadow"
               exact-active-class="primary">{{ $t('createaPurchaseInvoice') }}</v-btn>

            <v-btn :min-width="120" small v-if="noticeDebtor" to="/notice/create-notice-debtor"
               :color="$store.state.Settings.darkTheme == true ? 'backgroundW' : '#fff'" depressed class="shadow"
               exact-active-class="primary">{{ $t('create notice debtor') }}</v-btn>

            <v-btn :min-width="120" small v-if="noticeCreditor" to="/notice/create-notice-creditor"
               :color="$store.state.Settings.darkTheme == true ? 'backgroundW' : '#fff'" depressed class="shadow"
               exact-active-class="primary">{{ $t("create notice creditor") }}</v-btn>

            <v-btn :min-width="120" small v-if="expenses" to="/expenses/create-expenses"
               :color="$store.state.Settings.darkTheme == true ? 'backgroundW' : '#fff'" depressed class="shadow"
               exact-active-class="primary">{{ $t("create expenses invoice") }}</v-btn>

            <v-btn :min-width="120" small v-if="returnPermission"
               :to="isQty ? '/return-sales/create-return-sales' : '/return-sales/create-return-sales-piece'"
               :color="$store.state.Settings.darkTheme == true ? 'backgroundW' : '#fff'" depressed class="shadow"
               exact-active-class="primary">{{ $t("create ruturn sales") }}</v-btn>
         </v-card>
      </v-speed-dial>

   </section>
</template>

<script>

export default {
   name: "SpeedDial",
   computed: {
      salesPermission() {
         return this.$global.CheckAction("06-001", 1) || this.$global.CheckAction("06-008", 1)
      },
      purchasePermission() {
         return this.$global.CheckAction("04-008", 1)
      },
      returnPermission() {
         return this.$global.CheckAction("06-500", 1) || this.$global.CheckAction("06-500", 1)
      },
      noticeDebtor() {
         return this.$global.CheckAction("06-003", 1)
      },
      noticeCreditor() {
         return this.$global.CheckAction("06-002", 1)
      },
      expenses() {
         return this.$global.CheckAction("06-101", 1)
      }
   },
   components: {

   },
   watch: {

   },
   data() {
      return {
         fav: false,
         fab: false,
         key: 1
      };
   },
   mounted() {

   },
   methods: {

   },
}
</script>

<style scoped  lang="scss">
</style>