<template>
   <v-dialog v-model="dialog" persistent max-width="500">
      <v-card relative>
         <v-btn absolute class="mt-2" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
            color="grey lighten-1" text @click="close()">
            <v-icon color="grey lighten-1">mdi-close</v-icon>
         </v-btn>
         <v-card-title class="text-h6">{{ $t('order summery') }}</v-card-title>
         <v-divider></v-divider>
         <div class="px-5 py-2">
            <v-card class="shadow rounded-lg light justify-center my-2 py-5 px-5">
               <div class="d-flex align-center justify-space-between">
                  <p class="my-0 mx-2 font-weight-medium">{{ $t('item') }}</p>
                  <p class="my-0 mx-2" v-if="summery.itemTitle">{{ summery.itemTitle }}</p>
               </div>
               <template v-if="summery.categoryId !== 25 && summery.categoryId !== 26 && summery.categoryId !== 50">
                  <v-divider class="my-2" />
                  <div class="d-flex align-center justify-space-between">
                     <p class="my-0 mx-2 font-weight-medium">{{ $t('caliber') }}</p>
                     <p class="my-0 mx-2">{{ $t('gold') }} {{ summery.categoryId }}</p>
                  </div>
               </template>
               <div class="d-flex align-center justify-space-between" v-if="summery.categoryId == 25">
                  <p class="my-0 mx-2 font-weight-medium">{{ $t('caliber') }}</p>
                  <p class="my-0 mx-2">{{ $t('gold') }} {{ summery.caliber_id }}</p>
               </div>
               <template v-if="summery.categoryId !== 26 && summery.categoryId !== 50">
                  <v-divider class="my-2"></v-divider>
                  <div class="d-flex align-center justify-space-between">
                     <p class="my-0 mx-2 font-weight-medium">{{ $t('gold weight') }}</p>
                     <p class="my-0 mx-2" v-if="summery.coppingWeight || summery.gold_weight">{{
                        $global.DecimalFormatter(Number(summery.coppingWeight || summery.gold_weight).toFixed(2))
                     }} {{ $t('gram') }}</p>
                  </div>
               </template>
               <v-divider class="my-2"></v-divider>

               <div class="d-flex align-center justify-space-between">
                  <p class="my-0 mx-2 font-weight-medium">{{ $t('price') }}</p>
                  <p class="my-0 mx-2" v-if="summery.priceWithoutVat">{{
                     $global.DecimalFormatter(Number(summery.priceWithoutVat).toFixed(2))
                  }} {{ $t('ryal') }}</p>
               </div>
               <v-divider class="my-2"></v-divider>
               <div class="d-flex align-center justify-space-between">
                  <p class="my-0 mx-2 font-weight-medium">{{ $t('qty') }}</p>
                  <p class="my-0 mx-2" v-if="summery.countPieces">{{ summery.countPieces }}</p>
               </div>
               <v-divider class="my-2"></v-divider>
               <div class="d-flex align-center justify-space-between">
                  <p class="my-0 mx-2 font-weight-medium">{{ $t('Total including tax') }}</p>
                  <p class="my-0 mx-2" v-if="summery.price">{{
                     $global.DecimalFormatter(Number(summery.price).toFixed(2))
                  }} {{ $t('ryal') }}</p>
               </div>
               <template v-if="summery.categoryId !== 50">
                  <v-divider class="my-2"></v-divider>
                  <div class="d-flex align-center justify-space-between">
                     <label class="body-2 me-3">{{ $t('lobes') }}:</label>
                     <v-text-field suffix="" min="1" max="100" type="number" background-color="#ccccd1" solo
                        :label="$i18n.t('')" autofocus hide-details class="mt-2" clearable clear-icon="mdi-close-circle"
                        v-model="summery.lobes"></v-text-field>
                  </div>
               </template>
               <v-divider class="my-2"></v-divider>
               <div class="d-flex align-center justify-space-between">
                  <label class="body-2 me-9">{{ $t('notes') }}:</label>
                  <v-textarea background-color="#ccccd1" solo hide-details rows="3" auto-grow clearable
                     clear-icon="mdi-close-circle" v-model="summery.notes"></v-textarea>
               </div>

            </v-card>

            <v-col cols="12" class="py-4 px-0">
               <v-btn color="success" large @click="returnData" class="shadow d-block mi-start-auto" :loading="loading"
                  :disabled="(loading || (sales_note ? summery.notes ? false : true : false))">{{ $t('add to invoice')
                  }}</v-btn>
            </v-col>
         </div>
      </v-card>
   </v-dialog>
</template>



<style scoped lang="scss"></style>



<script>
export default {
   name: "InvoiceSummery",
   props: {
      backValueMethod: { type: Function },
      closeMethod: { type: Function },
      summery: { default: {} },
      loading: { default: false },
      dialog: { default: false },
      sales_note: { default: 0 },
   },
   computed: {
   },
   mounted() {
      window.addEventListener("keydown", e => {
         if (e.key == 'Enter' && this.dialog == true) {
            this.backValueMethod(true);
         }
      });
   },
   data() {
      return {

      };
   },
   methods: {
      returnData() {
         this.backValueMethod(true);
      },
      close() {
         this.backValueMethod(false);
         this.closeMethod()
      },
   },
};
</script>
