<template>
   <div>
      <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="false" :preview-modal="true"
         :paginate-elements-by-height="$store.state.Settings.windowSize.x" filename="كشف حساب  عيارات" :pdf-quality="3"
         :manual-pagination="true" pdf-format="a4" pdf-orientation="portrait"
         :pdf-content-width="$store.state.Settings.windowSize.y" ref="html2Pdf">
         <section slot="pdf-content" :dir="$vuetify.rtl ? 'rtl' : 'ltr'">
            <PrintPDF :header="tableHeader" :maxPDFRows="20" :data="printData" :header_slot="false" :content_slot="false"
               :footer_slot="false" :showGroupHeader="false" :groupHeader="false" :footerData="[]"
               :customize_header_footer="false" :printStaticData="Object">
               <template v-slot:pdf_content="{ page, table_data, isLastPage }">
                  <div class="print_title">
                     <div> كشف الحساب </div>
                     <div> Statement of Account</div>
                  </div>
                  <table v-if="page == 1" id="caliber_statement_header">
                     <tr>
                        <td colspan="1">
                           اسم العميل / Customer
                        </td>
                        <td colspan="3">{{ printStaticData.customer_name }}</td>
                     </tr>

                     <tr>
                        <td>Date From / من التاريخ</td>
                        <td>{{ printStaticData.from }}</td>
                        <td>Date To / من التاريخ</td>
                        <td>{{ printStaticData.to }}</td>
                     </tr>
                  </table>

                  <table class="caliber_statement_content">
                     <tr>
                        <th colspan="3">تفاصيل العمليات</th>
                        <th colspan="1">البيان</th>
                        <th colspan="2">18</th>
                        <th colspan="2">21</th>
                        <th colspan="2">22</th>
                        <th colspan="2">24</th>
                     </tr>
                     <tr>
                        <th>{{ $t('document name') }}</th>
                        <th>{{ $t('reference number') }}</th>
                        <th>{{ $t('date') }}</th>
                        <th>{{ $t('the statement') }}</th>
                        <th>{{ $t('debit') }}</th>
                        <th>{{ $t('credit') }}</th>
                        <th>{{ $t('debit') }}</th>
                        <th>{{ $t('credit') }}</th>
                        <th>{{ $t('debit') }}</th>
                        <th>{{ $t('credit') }}</th>
                        <th>{{ $t('debit') }}</th>
                        <th>{{ $t('credit') }}</th>
                     </tr>
                     <tr v-for="(row, index) in table_data" :key="index">
                        <td>{{ row.id }}</td>
                        <td></td>
                        <td>{{ row.action_date || '-' }}</td>
                        <td>{{ row.transaction_type || '-' }}</td>
                        <td>{{ row.debit_weight_18 | nonZero }}</td>
                        <td>{{ row.credit_weight_18 | nonZero }}</td>
                        <td>{{ row.debit_weight_21 | nonZero }}</td>
                        <td>{{ row.credit_weight_21 | nonZero }}</td>
                        <td>{{ row.debit_weight_22 | nonZero }}</td>
                        <td>{{ row.debit_weight_22 | nonZero }}</td>
                        <td>{{ row.credit_weight_24 | nonZero }}</td>
                        <td>{{ row.credit_weight_24 | nonZero }}</td>
                     </tr>

                     <tr v-if="isLastPage">
                        <td colspan="4">{{ $t('total transaction without first') }}</td>
                        <td>{{ footerData.debit_weight_18_without_first | nonZero }}</td>
                        <td>{{ footerData.credit_weight_18_without_first | nonZero }}</td>
                        <td>{{ footerData.debit_weight_21_without_first | nonZero }}</td>
                        <td>{{ footerData.credit_weight_21_without_first | nonZero }}</td>
                        <td>{{ footerData.debit_weight_22_without_first | nonZero }}</td>
                        <td>{{ footerData.credit_weight_22_without_first | nonZero }}</td>
                        <td>{{ footerData.debit_weight_24_without_first | nonZero }}</td>
                        <td>{{ footerData.credit_weight_24_without_first | nonZero }}</td>
                     </tr>
                     <tr v-if="isLastPage">
                        <td colspan="4">{{ $t('transaction balance') }}</td>
                        <td colspan="2">{{ footerData.weight_18_balance_without_first | nonZero }}</td>
                        <td colspan="2">{{ footerData.weight_21_balance_without_first | nonZero }}</td>
                        <td colspan="2">{{ footerData.weight_22_balance_without_first | nonZero }}</td>
                        <td colspan="2">{{ footerData.weight_24_balance_without_first | nonZero }}</td>
                     </tr>
                     <tr v-if="isLastPage">
                        <td colspan="4">{{ $t('total transaction balance') }}</td>
                        <td>{{ footerData.debit_weight_18 | nonZero }}</td>
                        <td>{{ footerData.credit_weight_18 | nonZero }}</td>
                        <td>{{ footerData.debit_weight_21 | nonZero }}</td>
                        <td>{{ footerData.credit_weight_21 | nonZero }}</td>
                        <td>{{ footerData.debit_weight_22 | nonZero }}</td>
                        <td>{{ footerData.credit_weight_22 | nonZero }}</td>
                        <td>{{ footerData.debit_weight_24 | nonZero }}</td>
                        <td>{{ footerData.credit_weight_24 | nonZero }}</td>
                     </tr>
                     <tr v-if="isLastPage">
                        <td colspan="4">{{ $t('balance') }}</td>
                        <td colspan="2">{{ footerData.weight_18_balance | nonZero }}</td>
                        <td colspan="2">{{ footerData.weight_21_balance | nonZero }}</td>
                        <td colspan="2">{{ footerData.weight_22_balance | nonZero }}</td>
                        <td colspan="2">{{ footerData.weight_24_balance | nonZero }}</td>
                     </tr>
                  </table>

                  <table v-if="isLastPage" class="caliber_statement_content">
                     <tr>
                        <th>{{ $t('currency') }}</th>
                        <th>{{ 18 }}</th>
                        <th>{{ 21 }}</th>
                        <th>{{ 22 }}</th>
                        <th>{{ 24 }}</th>
                        <th>{{ $t('transfer caliber') }}</th>
                        <th>{{ caliber_equivalent }}</th>
                     </tr>
                     <tr>
                        <td>{{ $t('available balance') }}</td>
                        <td>{{ footerData.weight_18_balance | nonZero }}</td>
                        <td>{{ footerData.weight_21_balance | nonZero }}</td>
                        <td>{{ footerData.weight_22_balance | nonZero }}</td>
                        <td>{{ footerData.weight_24_balance | nonZero }}</td>
                        <td colspan="2">{{ footerData.equivalent_24 | nonZero }}</td>
                     </tr>
                     <tr>
                        <td>{{ $t('pending balance') }}</td>
                        <td>{{ 0 | nonZero }}</td>
                        <td>{{ 0 | nonZero }}</td>
                        <td>{{ 0 | nonZero }}</td>
                        <td>{{ 0 | nonZero }}</td>
                        <td colspan="2">{{ 0 | nonZero }}</td>
                     </tr>
                     <tr>
                        <td class="t_total">{{ $t('total') }}</td>
                        <td class="t_total">{{ footerData.weight_18_balance | nonZero }}</td>
                        <td class="t_total">{{ footerData.weight_21_balance | nonZero }}</td>
                        <td class="t_total">{{ footerData.weight_22_balance | nonZero }}</td>
                        <td class="t_total">{{ footerData.weight_24_balance | nonZero }}</td>
                        <td class="t_total" colspan="2">{{ footerData.equivalent_24 | nonZero }}</td>
                     </tr>
                  </table>


               </template>
            </PrintPDF>
         </section>
      </vue-html2pdf>
   </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import PrintPDF from '@/components/PrintPDF.vue';
export default {
   name: "StatementPrint",
   props: {
      printAction: { type: Function },
      print: { default: false },
      caliber_equivalent: { default: '' },
      printData: { default: [] },
      printStaticData: { default: Object },
      footerData: { default: Object },
   },
   computed: {

   },
   components: {
      VueHtml2pdf,
      PrintPDF
   },
   watch: {
      print() {
         if (this.print) {
            this.$refs.html2Pdf.generatePdf();
         }
      },
   },
   data: () => ({
      tableHeader: [],
   }),
   methods: {

   },
   mounted() {

   },

};
</script>

<style lang="scss" >
#caliber_statement_header {
   width: 100%;
   margin-top: 5px !important;
   border: 0 !important;
   background: #f5f5f5 !important;

   tr {
      background: #f5f5f5 !important;
   }

   td {
      text-align: start !important;
      font-weight: 600;
      font-size: 11px !important;
   }

   td:nth-of-type(odd) {
      color: #0079ff
   }

   td:nth-of-type(even) {
      color: #000
   }

}

.caliber_statement_content {
   border-collapse: collapse;
   border: 2px solid #e9e8ee;
   background: #fff !important;
   width: 100%;
   margin-top: 5px !important;

   tr {
      th {
         z-index: 2;
         position: relative;
         padding: 5px !important;
         background: #e9e8ee !important;
         border: 1px solid #ddd !important;
         font-size: 11px !important;
         text-align: center;
      }

      td {
         padding: 5px !important;
         border-bottom: 1px solid #ddd !important;
         font-size: 11px !important;
         background: #f8f9fd !important;
         border-left: 1px solid #eee;
         text-align: center;

         &:nth-of-type(1) {
            background: #f5f5f5 !important;
            color: #000 !important;
            font-weight: 500;
            padding: 5px !important;
            text-align: center !important;
         }

         &:nth-of-type(2) {
            text-align: center !important;
            color: #000 !important;
         }

         &.t_total {
            background-color: #e9e8ee !important;
            font-weight: 600;
         }
      }
   }

}
</style>