<template>
  <section>
    <v-container fluid>
      <v-row justify="space-between">
        <v-col cols="12" sm="auto" class="d-flex justify-center align-center pt-0 pb-2">
          <v-row align="center" justify="center" justify-md="start">
            <v-col cols="12" md="auto">
              <div class="subtitle-2 text-center text--disabled">
                <strong>{{ $t('sales') | capitalize }} . {{ $t('reports') | capitalize }}</strong>
              </div>
            </v-col>
            <v-col cols="auto" class="px-1" v-if="$global.CheckAction('06-014', 1)">
              <v-btn to="/reports/shift-report" color="primary" outlined rounded :min-width="120" style="height: 40px"
                small depressed>{{ $global.FilterPermissions("06-014").sub_title }}</v-btn>
            </v-col>
            <v-col cols="auto" class="px-1" v-if="$global.CheckAction('06-018', 1)">
              <v-btn to="/reports/customer-supplier-statement" outlined rounded :min-width="120" style="height: 40px"
                small color="primary" depressed>{{ $global.FilterPermissions("06-018").sub_title }}</v-btn>
            </v-col>
            <v-col cols="auto" class="px-1" v-if="$global.CheckAction('02-020', 1)">
              <v-btn to="/reports/supplier-statement" color="primary" outlined rounded :min-width="120"
                style="height: 40px" small depressed>{{ $global.FilterPermissions("02-020").sub_title }}</v-btn>
            </v-col>
            <v-col cols="auto" class="px-1" v-if="$global.CheckAction('06-012', 1)">
              <v-btn to="/reports/full_report" color="primary" outlined rounded :min-width="120" style="height: 40px"
                small depressed>{{ $global.FilterPermissions("06-012").sub_title }}</v-btn>
            </v-col>
            <v-col cols="auto" class="px-1" v-if="$global.CheckAction('06-010', 1)">
              <v-btn to="/reports/sales_notice_report" color="primary" outlined rounded :min-width="120"
                style="height: 40px" small depressed>{{ $global.FilterPermissions("06-010").sub_title
                }}</v-btn>
            </v-col>
            <v-col cols="auto" class="px-1" v-if="$global.CheckAction('03-004', 1)">
              <v-btn to="/reports/barcode_report" active-class="primary" rounded :min-width="120" style="height: 40px"
                small color="transparent" depressed>{{ $global.FilterPermissions("03-004").sub_title
                }}</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" class="py-0">
          <DynamicTable :searchMethod="getData" :tooltipRow="false" :searchBtn="false" :searchByDateRange="false"
            :viewSearch="false" :isLoading="pageData.isLoading" :data="pageData.rows" :header="tableHeader"
            :tableHeight="$store.state.Settings.windowSize.y - 320" :checkboxMethod="checkboxMethod" :selectRow="true">
            <!-- <template v-slot:td="{ row, header }">
              <span class="d-flex justify-center" v-if="header.key !== 's_m_id' && header.key !== 'r_m_id'">
                <span v-if="row.sumtion == 2"> ({{ row[header.key] || 0 | float }})</span>
                <span v-else> {{ row[header.key] || 0 | float }}</span>
              </span>
            </template> -->

            <template v-slot:inputs>
              <v-row align="center" class="mt-n3">

                <GenericInput type="select" :clearable="true" :lookups="categories" :value="category_id"
                  @input="category_id = $event" label="caliber" :multi="false" :required="false" paddingY="pt-3"
                  selected_label="title" selected_prop="category_id" :hide-details="true"
                  :isLoading="pageData.editIsLoading" :cols="[12, 'auto', 2]"></GenericInput>


                <GenericInput type="select" :clearable="true" :lookups="filterItems" :value="sub_category_id"
                  @input="sub_category_id = $event" label="item" :multi="false" :required="false" paddingY="pt-3"
                  selected_label="title" selected_prop="id" :hide-details="true" :isLoading="pageData.editIsLoading"
                  :cols="[12, 'auto', 2]"></GenericInput>

                <GenericInput type="select" :clearable="true" :lookups="in_types" :value="in_type"
                  @input="in_type = $event" label="type" :multi="false" :required="false" paddingY="pt-3"
                  selected_label="name" selected_prop="id" :hide-details="true" :isLoading="pageData.editIsLoading"
                  :cols="[12, 'auto', 2]">
                </GenericInput>

                <GenericInput type="text" :keydownEnter="getData" :outlined="true" :clearable="true" :value="in_id"
                  @input="in_id = $event" label="invoice number" :required="false" paddingY="pt-3" :hide-details="true"
                  :isLoading="pageData.editIsLoading" :cols="[12, 'auto', 2]">
                </GenericInput>

                <GenericInput type="text" :keydownEnter="getData" :outlined="true" :clearable="true" :value="item_code"
                  @input="item_code = $event" label="item code" :required="false" paddingY="pt-3" :hide-details="true"
                  :isLoading="pageData.editIsLoading" :cols="[12, 'auto', 2]">
                </GenericInput>

                <GenericInput type="select" :clearable="false" :lookups="branches" :value="branch_id"
                  @input="branch_id = $event" label="branch" :multi="false" :required="false" paddingY="pt-3"
                  selected_label="name" selected_prop="id" :hide-details="true" :isLoading="pageData.editIsLoading"
                  :cols="[12, 'auto', 2]" />

                <GenericInput type="select" :clearable="false" :lookups="types" :value="status" @input="status = $event"
                  label="status" :multi="false" :required="false" paddingY="pt-3" selected_label="name" selected_prop="id"
                  :hide-details="true" :isLoading="pageData.editIsLoading" :cols="[12, 'auto', 2]" />



                <v-col cols="12" md="10" class="py-1 d-flex justify-end">
                  <v-btn v-if="selectedRow.length" @click="print(false)" :min-width="120" color="white" depressed
                    class="shadow" :disabled="!(selectedRow.length > 0)">
                    <v-icon dark left>mdi-cloud-print-outline</v-icon>
                    <span>{{ $t('print') }}</span>
                  </v-btn>
                  <v-btn v-if="selectedRow.length" @click="print(true)" :min-width="120" color="gray2" depressed
                    class="shadow white--text mx-2" :disabled="!(selectedRow.length > 0)">
                    <v-icon left>mdi-cloud-print-outline</v-icon>
                    <span>{{ $t('print') }} RFID</span>
                  </v-btn>
                  <v-btn :loading="pageData.isLoading" @click="getData()" :height="38" class="ms-2 shadow"
                    color="success">
                    {{ $t("search") }}
                    <v-icon size="18" right>mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </DynamicTable>
        </v-col>
        <v-col cols="12" sm="12" class="py-0" v-if="pageData.rows.length">
          <Pagination :limit="pageData.rowsNumber" :backValueMethod="changePage" :pagination="pageData.pagination"
            :isLoading="pageData.isLoading">
          </Pagination>
        </v-col>

      </v-row>
    </v-container>
  </section>
</template>



<script>
import DynamicTable from "@/components/DynamicTable";
import GenericInput from "@/components/GenericInput";
import Pagination from "@/components/Pagination";
import { EntityMixins } from "@/mixins/EntityMixins";
export default {
  name: "BarcodeReportEntity",
  mixins: [EntityMixins],
  data: () => ({
    screen_code: "03-004",
    barcodeID: null,
    printNow: false,
    viewFilter: true,
    live_apps: [],
    rfIDDialog: false,
    rfItem: null,
    rfIndex: null,
    today: null,
    qrCodeImg: null,
    items: [],
    in_types: [],
    types: [],
    selectedRow: [],
    categories: [],
    subcategories: [],
    branches: [],
    sub_category_id: null,
    category_id: null,
    in_type: null,
    in_id: null,
    item_code: null,
    branch_id: 0,
    status: 0,
  }),
  components: {
    DynamicTable,
    GenericInput,
    Pagination
  },
  computed: {
    tableHeader() {
      return [
        { text: "item code", key: "item_code", type: 'text', classes: "" },
        { text: "RFID", key: "rfid", type: 'text', classes: "" },
        { text: "category", key: "category_name", type: 'text', classes: "" },
        { text: "item", key: "sub_category_name", type: 'text', classes: "" },
        { text: "gold weight", key: "gold_weight", type: 'float', classes: "" },
        { text: "diamond weight", key: "diamond_weight", type: 'float', classes: "" },
        { text: "price", key: "price", type: 'price', classes: "" },
        { text: "invoice", key: "in_id", type: 'text', classes: "" },
        { text: "chopping before", key: "is_locked", type: 'boolean', classes: "" },
        { text: "pay done", key: "is_sold", type: 'boolean', classes: "" },
        { text: "action number", key: "out_id", type: 'text', classes: "" },
        { text: "number of sales", key: "total_sales", type: 'text', classes: "" },
        { text: "Supplier code", key: "supplier_code", type: 'text', classes: "" },
        { text: "date", key: "action_date", type: 'date', classes: "" },
      ]
    },
    filterItems() {
      return this.category_id ? this.subcategories.filter(item => item.category_id == this.category_id) : this.subcategories
    },
    check_app() {
      return (appName) => {
        return this.live_apps.some(app => app === appName)
      }
    }
  },
  watch: {
    category_id() {
      this.extraHeaderParams.set('category_id', this.category_id || '');
      this.getData();
    },
    sub_category_id() {
      let selectedSubCategory = this.subcategories.find(item => item.id == this.sub_category_id) || {}
      if (this.category_id == selectedSubCategory.category_id) {
        this.getData();
      } else {
        this.category_id = selectedSubCategory.category_id || null;
      }
      this.extraHeaderParams.set('sub_category_id', this.sub_category_id || '');
    },
    status() {
      this.extraHeaderParams.set('status', this.status || '');
      this.getData();
    },
    in_type() {
      this.extraHeaderParams.set('page', 1);
      this.extraHeaderParams.set('in_type', this.in_type || '');
      if (!this.in_type) { this.getData() }
    },
    item_code() {
      this.extraHeaderParams.set('page', 1);
      this.extraHeaderParams.set('item_code', this.item_code || '');
      if (!this.item_code) { this.getData() }
    },
    in_id() {
      this.extraHeaderParams.set('page', 1);
      this.extraHeaderParams.set('in_id', this.in_id || '');
      if (!this.in_id) { this.getData() }
    },
    branch_id() {
      this.extraHeaderParams.set('branch_id', this.branch_id || '');
      this.getData();
    },
    printNow() {
      if (this.printNow)
        setTimeout(() => {
          this.printNow = false
        }, 1000);
    }
  },
  mounted() {
    this.branch_id = this.$store.state.userData?.user?.shift?.branch_id;
    this.endpoint = "barcode";
    // this.getData();
  },
  methods: {
    setResponse(response) {
      response.data.items?.data?.forEach(row => {
        switch (row.in_type) {
          case 'pr': row.in_type_title = 'purchase'; break;
          case 'nc': row.in_type_title = 'credit notice'; break;
          case 'nd': row.in_type_title = 'debit notice'; break;
          case 'cr': row.in_type_title = 'create'; break;
          case 'receipt_gold_permission': row.in_type_title = 'receipt gold permission'; break;
          case 'payment_gold_permission': row.in_type_title = 'payment gold permission'; break;
          default: break;
        }
        switch (row.out_type) {
          case 'sa':
            row.out_type_title = 'sales invoice';
            row.is_sold = true;
            break;
          default:
            row.is_sold = false;
            break;
        }
      });
      this.pageData.rows = [...response.data.items.data];
      this.categories = response.data.categorys;
      this.live_apps = response.data.live_apps || [];
      response.data.sub_categorys.forEach(item => {
        item.title = item.name + ' #' + item.category_name
      })
      this.subcategories = response.data.sub_categorys;
      this.branches = response.data.branchs || [];
      this.branches.unshift({ id: 0, name: this.$t('all') })
      this.in_types = Object.entries(response.data.in_typs).map(entry => {
        return { id: entry[0], name: entry[1] };
      })
      this.types = [
        {
          id: 0,
          name: this.$t('all')
        },
        {
          id: 1,
          name: this.$t('available')
        },
        {
          id: 2,
          name: this.$t('not available')
        },
      ]
    },
    print(type) {
      const rowIDS = this.selectedRow.map(object => object.id);
      window.open(`${this.$api.serverUrl}print_barcode?id=${rowIDS}&type=print&clientID=${this.$store.state.clientID}${type ? '&rfid=true' : ''}`, '_blank')
    },
    selectAll(status) {
      this.selectedRow = status ? this.pageData.rows : []
    },
    changePage(page, limit) {
      this.pageData.queryParam.set("rows", limit)
      this.pageData.queryParam.set("page", page)
      this.getData();
    },
    checkboxMethod(rows) {
      console.log(rows);
      this.selectedRow = rows
    },
  },
};
</script>
