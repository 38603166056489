<template>
  <section>
    <v-container fluid>
      <v-row justify="space-between">
        <v-col cols="12" sm="auto" class="d-flex justify-center align-center pt-0 pb-2">
          <v-row align="center" justify="center" justify-md="start">
            <v-col cols="12" md="auto">
              <div class="subtitle-2 text-center text--disabled">
                <strong>{{ $t('sales') | capitalize }} . {{ $t('reports') | capitalize }}</strong>
              </div>
            </v-col>
            <v-col cols="auto" class="px-1" v-if="$global.CheckAction('06-014', 1)">
              <v-btn to="/reports/shift-report" color="primary" outlined rounded :min-width="120" style="height: 40px"
                small depressed>{{ $global.FilterPermissions("06-014").sub_title }}</v-btn>
            </v-col>
            <v-col cols="auto" class="px-1" v-if="$global.CheckAction('06-018', 1)">
              <v-btn to="/reports/customer-supplier-statement" active-class="primary" rounded :min-width="120"
                style="height: 40px" small color="transparent" depressed>{{
                  $global.FilterPermissions("06-018").sub_title
                }}</v-btn>
            </v-col>
            <v-col cols="auto" class="px-1" v-if="$global.CheckAction('02-020', 1)">
              <v-btn to="/reports/supplier-statement" outlined rounded :min-width="120" style="height: 40px" small
                color="primary" depressed>{{
                  $global.FilterPermissions("02-020").sub_title }}</v-btn>
            </v-col>
            <v-col cols="auto" class="px-1" v-if="$global.CheckAction('06-012', 1)">
              <v-btn to="/reports/full_report" color="primary" outlined rounded :min-width="120" style="height: 40px"
                small depressed>{{ $global.FilterPermissions("06-012").sub_title }}</v-btn>
            </v-col>
            <v-col cols="auto" class="px-1" v-if="$global.CheckAction('06-010', 1)">
              <v-btn to="/reports/sales_notice_report" color="primary" outlined rounded :min-width="120"
                style="height: 40px" small depressed>{{ $global.FilterPermissions("06-010").sub_title }}</v-btn>
            </v-col>
            <v-col cols="auto" class="px-1" v-if="$global.CheckAction('03-004', 1)">
              <v-btn to="/reports/barcode_report" color="primary" outlined rounded :min-width="120" style="height: 40px"
                small depressed>{{ $global.FilterPermissions("03-004").sub_title }}</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" class="py-0">
          <DynamicTable :isLoading="isLoading" :searchMethod="searchMethod" :controlRoute="returnBy" :viewTitle="false"
            :data="rows" :searchByDateRange="false" :viewSearch="false" :header="header"
            :footerData="[calculatedTotals]" :showGroupHeader="true" :groupHeader="groupHeader"
            :tableHeight="$store.state.Settings.windowSize.y - 220">
            <template v-slot:inputs>

              <v-col cols="12" md="auto" v-if="rows.length">
                <v-btn depressed color="gray3" class="white--text" width="120" :height="37" :disabled="!(customer_id)"
                  @click="printMethod">
                  <v-icon dark left>mdi-cloud-print-outline</v-icon>
                  {{ $t('print') }}</v-btn>
              </v-col>
              <GenericInput type="date" :maxDate="toDate || today" :isPickerOpened="() => toDate = null" :solo="true"
                :dense="true" paddingY="pt-3" :value="fromDate" @input="fromDate = $event" label="from"
                :required="false" :hide-details="true" :cols="[12, 2, 2]">
              </GenericInput>
              <GenericInput type="date" :solo="true" :minDate="fromDate" :maxDate="today" :dense="true" paddingY="pt-3"
                :value="toDate" @input="toDate = $event" label="to" :required="false" :hide-details="true"
                :cols="[12, 2, 2]">
              </GenericInput>


              <GenericInput type="select" :clearable="true" :lookups="customers" selected_label="name"
                selected_prop="id" :value="customer_id" paddingY="pt-3" @input="customer_id = $event" label="customer"
                :isLoading="false" :hideDetails="true" :cols="[12, 2, 2]"></GenericInput>

              <GenericInput type="select" :clearable="true" :lookups="categories" selected_label="name"
                selected_prop="id" :value="balance_caliber" @input="balance_caliber = $event" label="equivalent caliber"
                :isLoading="false" :hideDetails="true" :cols="[12, 2, 2]"></GenericInput>

            </template>
          </DynamicTable>
        </v-col>

      </v-row>
    </v-container>
    <StatementPrint :caliber_equivalent="caliber_equivalent" :printAction="() => print = false" :print="print"
      :printData="rows" :footerData="calculatedTotals" :printStaticData="printStaticData" />
  </section>
</template>



<script>
import DynamicTable from "@/components/DynamicTable";
import GenericInput from "@/components/GenericInput";
import StatementPrint from "./StatementPrint.vue";
export default {
  name: "CustomerSupplierStatementTable",

  data: () => ({
    isLoading: true,
    rows: [],
    pagination: null,
    page: 1,
    rowsNumber: 25,
    searchText: null,
    from: null,
    to: null,
    date: null,
    returnBy: "/reports/customer-supplier-statement",
    categories: [],
    groupHeader: [],
    customers: [],
    customer_id: null,
    balance_caliber: 24,
    fromDate: null,
    toDate: null,
    today: null,
    print: false,
    printStaticData: Object,
  }),
  components: {
    DynamicTable,
    GenericInput,
    StatementPrint
  },
  computed: {
    calculatedTotals() {
      if (this.rows.length) {
        // total transaction balance with first
        const debit_weight_18 = this.rows.reduce((oldValue, newValue) => {
          return Number(+oldValue) + Number(+newValue.debit_weight_18 || 0);
        }, 0)
        const credit_weight_18 = this.rows.reduce((oldValue, newValue) => {
          return Number(+oldValue) + Number(+newValue.credit_weight_18 || 0);
        }, 0)
        const debit_weight_21 = this.rows.reduce((oldValue, newValue) => {
          return Number(+oldValue) + Number(+newValue.debit_weight_21 || 0);
        }, 0)
        const credit_weight_21 = this.rows.reduce((oldValue, newValue) => {
          return Number(+oldValue) + Number(+newValue.credit_weight_21 || 0);
        }, 0)
        const debit_weight_22 = this.rows.reduce((oldValue, newValue) => {
          return Number(+oldValue) + Number(+newValue.debit_weight_22 || 0);
        }, 0)
        const credit_weight_22 = this.rows.reduce((oldValue, newValue) => {
          return Number(+oldValue) + Number(+newValue.credit_weight_22 || 0);
        }, 0)
        const debit_weight_24 = this.rows.reduce((oldValue, newValue) => {
          return Number(+oldValue) + Number(+newValue.debit_weight_24 || 0);
        }, 0)
        const credit_weight_24 = this.rows.reduce((oldValue, newValue) => {
          return Number(+oldValue) + Number(+newValue.credit_weight_24 || 0);
        }, 0)
        const debit_amount = this.rows.reduce((oldValue, newValue) => {
          return Number(+oldValue) + Number(+newValue.debit_amount || 0);
        }, 0)
        const credit_amount = this.rows.reduce((oldValue, newValue) => {
          return Number(+oldValue) + Number(+newValue.credit_amount || 0);
        }, 0)

        const weight_in_24 = this.rows.at(-1).weight_in_24;
        const equivalent_24 = this.rows.at(-1).equivalent_24;
        const amount_balance = this.rows.at(-1).amount_balance;

        //  transaction balance without first
        const debit_weight_18_without_first = debit_weight_18 - this.rows.at(0).debit_weight_18;
        const credit_weight_18_without_first = credit_weight_18 - this.rows.at(0).credit_weight_18;
        const debit_weight_21_without_first = debit_weight_21 - this.rows.at(0).debit_weight_21;
        const credit_weight_21_without_first = credit_weight_21 - this.rows.at(0).credit_weight_21;
        const debit_weight_22_without_first = debit_weight_22 - this.rows.at(0).debit_weight_22;
        const credit_weight_22_without_first = credit_weight_22 - this.rows.at(0).credit_weight_22;
        const debit_weight_24_without_first = debit_weight_24 - this.rows.at(0).debit_weight_24;
        const credit_weight_24_without_first = credit_weight_24 - this.rows.at(0).credit_weight_24;

        // total transaction balance without first
        const weight_18_balance_without_first = debit_weight_18_without_first - credit_weight_18_without_first;
        const weight_21_balance_without_first = debit_weight_21_without_first - credit_weight_21_without_first;
        const weight_22_balance_without_first = debit_weight_22_without_first - credit_weight_22_without_first;
        const weight_24_balance_without_first = debit_weight_24_without_first - credit_weight_24_without_first;

        //  total balance
        const weight_18_balance = debit_weight_18 - credit_weight_18;
        const weight_21_balance = debit_weight_21 - credit_weight_21;
        const weight_22_balance = debit_weight_22 - credit_weight_22;
        const weight_24_balance = debit_weight_24 - credit_weight_24;

        return {
          m_id: this.$i18n.t('total'),
          debit_weight_18: debit_weight_18,
          credit_weight_18: credit_weight_18,
          debit_weight_21: debit_weight_21,
          credit_weight_21: credit_weight_21,
          debit_weight_22: debit_weight_22,
          credit_weight_22: credit_weight_22,
          debit_weight_24: debit_weight_24,
          credit_weight_24: credit_weight_24,
          weight_in_24: weight_in_24,
          equivalent_24: equivalent_24,
          debit_amount: debit_amount,
          credit_amount: credit_amount,
          amount_balance: amount_balance,
          weight_18_balance: weight_18_balance,
          weight_21_balance: weight_21_balance,
          weight_22_balance: weight_22_balance,
          weight_24_balance: weight_24_balance,
          weight_18_balance_without_first: weight_18_balance_without_first,
          weight_21_balance_without_first: weight_21_balance_without_first,
          weight_22_balance_without_first: weight_22_balance_without_first,
          weight_24_balance_without_first: weight_24_balance_without_first,
          debit_weight_18_without_first: debit_weight_18_without_first,
          credit_weight_18_without_first: credit_weight_18_without_first,
          debit_weight_21_without_first: debit_weight_21_without_first,
          credit_weight_21_without_first: credit_weight_21_without_first,
          debit_weight_22_without_first: debit_weight_22_without_first,
          credit_weight_22_without_first: credit_weight_22_without_first,
          debit_weight_24_without_first: debit_weight_24_without_first,
          credit_weight_24_without_first: credit_weight_24_without_first,
        }
      } else {
        return {
          debit_weight_18: 0,
          credit_weight_18: 0,
          debit_weight_21: 0,
          credit_weight_21: 0,
          debit_weight_22: 0,
          credit_weight_22: 0,
          debit_weight_24: 0,
          credit_weight_24: 0,
          weight_in_24: 0,
          debit_amount: 0,
          credit_amount: 0,
          amount_balance: 0,
          weight_18_balance: 0,
          weight_21_balance: 0,
          weight_22_balance: 0,
          weight_24_balance: 0,
          weight_18_balance_without_first: 0,
          weight_21_balance_without_first: 0,
          weight_22_balance_without_first: 0,
          weight_24_balance_without_first: 0,
          debit_weight_18_without_first: 0,
          credit_weight_18_without_first: 0,
          debit_weight_21_without_first: 0,
          credit_weight_21_without_first: 0,
          debit_weight_22_without_first: 0,
          credit_weight_22_without_first: 0,
          debit_weight_24_without_first: 0,
          credit_weight_24_without_first: 0,
        }
      }
    },
    caliber_equivalent() {
      return `${this.$i18n.t('caliber equivalent')} ${this.balance_caliber}`
    },
    selectedCustomer() {
      if (this.customer_id)
        return this.$global.FilterArrayOfObjectElement(this.customers || [], 'id', this.customer_id, 'name') || null
      else
        return null
    },
    header() {
      return [
        { text: "transaction number", key: "id", type: 'text', classes: "" },
        { text: "date", key: "action_date", type: 'date', classes: "" },
        { text: "the statement", key: "transaction_type", type: 'text', classes: "" },

        { text: "debit", print_text: 'caliber 18 debit', key: "debit_weight_18", type: 'non_zero_float', classes: " td-border-right" },
        { text: "credit", print_text: 'caliber 18 credit', key: "credit_weight_18", type: 'non_zero_float', classes: " td-border-left" },

        { text: "debit", print_text: 'caliber 21 debit', key: "debit_weight_21", type: 'non_zero_float', classes: " td-border-right" },
        { text: "credit", print_text: 'caliber 21 credit', key: "credit_weight_21", type: 'non_zero_float', classes: " td-border-left" },

        { text: "debit", print_text: 'caliber 22 debit', key: "debit_weight_22", type: 'non_zero_float', classes: " td-border-right" },
        { text: "credit", print_text: 'caliber 22 credit', key: "credit_weight_22", type: 'non_zero_float', classes: " td-border-left" },

        { text: "debit", print_text: 'caliber 24 debit', key: "debit_weight_24", type: 'non_zero_float', classes: " td-border-right" },
        { text: "credit", print_text: 'caliber 24 credit', key: "credit_weight_24", type: 'non_zero_float', classes: " td-border-left" },

        { text: this.caliber_equivalent, print_text: this.caliber_equivalent, key: "equivalent_24", type: 'non_zero_float', classes: "green4 ", background: '#35ebbd' },

        { text: "debit", print_text: 'cash debit', key: "debit_amount", type: 'non_zero_float', classes: "" },
        { text: "credit", print_text: 'cash credit', key: "credit_amount", type: 'non_zero_float', classes: "" },
        { text: "balance", print_text: 'cash balance', key: "amount_balance", type: 'non_zero_float', classes: "green4 ", background: '#35ebbd' },
      ]
    }
  },
  watch: {
    customer_id() {
      this.searchMethod();
    },
    balance_caliber() {
      this.searchMethod();
    }
  },
  mounted() {
    this.pageMainData();
    this.getData();
  },
  methods: {
    pageMainData() {
      document.title = `${this.$i18n.t("Qarat - ")}${this.$global.FilterPermissions("06-018").sub_title}`;
      this.categories = [
        { id: 18, name: this.$i18n.t('caliber 18') },
        { id: 21, name: this.$i18n.t('caliber 21') },
        { id: 22, name: this.$i18n.t('caliber 22') },
        { id: 24, name: this.$i18n.t('caliber 24') },
      ];
      this.groupHeader = [
        { text: " ", colspan: 3, classes: "" },
        { text: "caliber 18", colspan: 2, type: 'text', classes: "blue11", background: '#ccd8ff' },
        { text: "caliber 21", colspan: 2, type: 'text', classes: "yellow11", background: '#fff8dd' },
        { text: "caliber 22", colspan: 2, type: 'text', classes: "green11", background: '#d2fbf0' },
        { text: "caliber 24", colspan: 2, type: 'text', classes: "gray11", background: '#e8e8ea' },
        { text: "balance", colspan: 1, type: 'text', classes: "green4", background: '#35ebbd' },
        { text: "Cash", colspan: 2, type: 'text', classes: "" },
        { text: "", colspan: 1, type: 'text', classes: "green4", background: '#35ebbd' },
      ];
      this.today = this.$global.GetCurrentDate();
      this.toDate = this.$global.GetCurrentDate();
      this.fromDate = this.$global.GetFirstDayOfMonth();
    },
    getData() {
      this.isLoading = true;
      this.$api.GET_METHOD(`customer_supplier_statement_report`).then((response) => {
        this.isLoading = false;
        if (response.check) {
          this.customers = response.data.customers;
        }
      })
    },
    searchMethod(text, date, from, to) {
      this.rows = []
      this.searchText = text;
      this.from = from;
      this.to = to;
      this.date = date;
      this.isLoading = true;
      const body = {
        from: this.fromDate,
        to: this.toDate,
        customer_id: this.customer_id,
        balance_caliber: this.balance_caliber,
        type: 'json'
      }
      if (this.customer_id) {
        this.$api
          .POST_METHOD(`customer_supplier_statement_report`, body, false)
          .then((response) => {
            this.isLoading = false;
            if (response.check) {
              this.prepareStatement(response.data);
            }
          });
      } else {
        this.isLoading = false;
        this.$swal.fire({
          title: this.$i18n.t('Please Check'),
          text: this.$i18n.t('please choose customer'),
          icon: "info",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    prepareStatement(statement) {

      statement.first.weight_in_24 = statement.first.total_as_24 || 0;
      statement.first.equivalent_24 = statement.first.total_as_24 || 0;
      statement.first.amount_balance = statement.first.total_amount || 0;
      statement.action.forEach((row, index) => {
        row = this.statementType(row);
        let prevIndex = index ? index - 1 : 0;
        let metal_type = +row.metal_action_type == 2 ? 'credit' : 'debit';
        let money_type = +row.money_action_type == 2 ? 'credit' : 'debit';
        row[`${metal_type}_weight_18`] = row.weight_18;
        row[`${metal_type}_weight_21`] = row.weight_21;
        row[`${metal_type}_weight_22`] = row.weight_22;
        row[`${metal_type}_weight_24`] = row.weight_24;
        row[`${money_type}_amount`] = row.total_amount;
        if (index == 0) { // First Balance
          row.equivalent_24 = metal_type == 'credit' ? +statement.first.weight_in_24 - +row.weight_in_24 : +statement.first.weight_in_24 + +row.weight_in_24;
          row.amount_balance = money_type == 'credit' ? +statement.first.amount_balance - +row.credit_amount : +statement.first.total_amount + +row.debit_amount
        } else { // Actions Balances
          row.equivalent_24 = metal_type == 'credit' ? +statement.action[prevIndex].equivalent_24 - +row.weight_in_24 : +statement.action[prevIndex].equivalent_24 + +row.weight_in_24
          row.amount_balance = money_type == 'credit' ? +statement.action[prevIndex].amount_balance - +row.credit_amount : +statement.action[prevIndex].amount_balance + +row.debit_amount
        }
      });
      statement.first.id = this.$i18n.t('first credit date');
      this.rows = statement.action;
      this.rows.unshift(statement.first);
    },
    statementType(action) {
      switch (action.type) {
        case 'rgp':
          action.transaction_type = this.$i18n.t('receipt gold permission');
          action.transaction_type_route = `/accounting/receipt_gold_permission-control/${action.id}`;
          break;
        case 'pgp':
          action.transaction_type = this.$i18n.t('payment gold permission');
          action.transaction_type_route = `/accounting/payment_gold_permission-control/${action.id}`;
          break;
        case 'sales':
          action.transaction_type = this.$i18n.t('sales');
          action.transaction_type_route = null;
          break;
        case 'notice_creditor':
          action.transaction_type = this.$i18n.t('notice creditor');
          action.transaction_type_route = null;
          break;
        case 'notice_debtor':
          action.transaction_type = this.$i18n.t('notice debtor');
          action.transaction_type_route = null;
          break;
        case 'purchase':
          action.transaction_type = this.$i18n.t('purchase');
          action.transaction_type_route = null;
          break;
        case 'MV':
          action.transaction_type = this.$i18n.t('multi voucher');
          action.transaction_type_route = null;
          break;
        case 'ClosingTransfer':
          action.transaction_type = this.$i18n.t('closing transfer');
          action.transaction_type_route = null;
          break;
        default:
          action.transaction_type = null;
          action.transaction_type_route = null;
          break;
      }
      return action;
    },
    changePage(page, limit) {
      this.page = page;
      this.rowsNumber = limit;
      this.getData();
    },
    printMethod() {
      this.print = true;
      this.printStaticData = {
        customer_name: this.selectedCustomer,
        customer_id: this.customer_id,
        from: this.fromDate,
        to: this.toDate,
      };
      setTimeout(() => {
        // this.printStaticData = Object;
        this.print = false;
      }, 1000);
    },
  },
};
</script>
