<template>
  <section>
    <v-container fluid>
      <v-row align="center" v-if="!isLoading">
        <!-- <v-col cols="12" md="6" class="white rounded-lg shadow px-5" sm="12">
          <v-row align="center">
            <v-col cols="6" md="3" class="px-1">
              <div class="light rounded-lg cursor_pointer caption px-1" v-on:click="goldCaliber = 18"
                :class="goldCaliber != 18 ? 'text--disabled' : 'font-weight-bold'">
                <div class="d-flex align-center">
                  <img src="../../assets/img/svg/dollar.svg" height="13px" class="mx-1" alt="Qarat-POS" />
                  <span class="text--disabled">{{ gold_price['18_price'].toFixed(2) }} {{ $t('ryal') }}</span>
                </div>
                <v-icon color="#0A6ADA" size="14" :disabled="goldCaliber != 18">mdi-check-circle</v-icon>
                {{ $t('gold') }} {{ $t('caliber') }} 18
              </div>
            </v-col>
            <v-col cols="6" md="3" class="px-1">
              <div class="light rounded-lg cursor_pointer caption px-1" v-on:click="goldCaliber = 21"
                :class="goldCaliber != 21 ? 'text--disabled' : 'font-weight-bold'">
                <div class="d-flex align-center">
                  <img src="../../assets/img/svg/dollar.svg" height="13px" class="mx-1" alt="Qarat-POS" />
                  <span class="text--disabled">{{ gold_price['21_price'].toFixed(2) }} {{ $t('ryal') }}</span>
                </div>
                <v-icon color="#0A6ADA" size="14" :disabled="goldCaliber != 21">mdi-check-circle</v-icon>
                {{ $t('gold') }} {{ $t('caliber') }} 21
              </div>
            </v-col>
            <v-col cols="6" md="3" class="px-1">
              <div class="light rounded-lg cursor_pointer caption px-1" v-on:click="goldCaliber = 22"
                :class="goldCaliber != 22 ? 'text--disabled' : 'font-weight-bold'">
                <div class="d-flex align-center">
                  <img src="../../assets/img/svg/dollar.svg" height="13px" class="mx-1" alt="Qarat-POS" />
                  <span class="text--disabled">{{ gold_price['22_price'].toFixed(2) }} {{ $t('ryal') }}</span>
                </div>
                <v-icon color="#0A6ADA" size="14" :disabled="goldCaliber != 22">mdi-check-circle</v-icon>
                {{ $t('gold') }} {{ $t('caliber') }} 22
              </div>
            </v-col>
            <v-col cols="6" md="3" class="px-1">
              <div class="light rounded-lg cursor_pointer caption px-1" v-on:click="goldCaliber = 24"
                :class="goldCaliber != 24 ? 'text--disabled' : 'font-weight-bold'">
                <div class="d-flex align-center">
                  <img src="../../assets/img/svg/dollar.svg" height="13px" class="mx-1" alt="Qarat-POS" />
                  <span class="text--disabled">{{ gold_price['24_price'].toFixed(2) }} {{ $t('ryal') }}</span>
                </div>
                <v-icon color="#0A6ADA" size="14" :disabled="goldCaliber != 24">mdi-check-circle</v-icon>
                {{ $t('gold') }} {{ $t('caliber') }} 24
              </div>
            </v-col>
          </v-row>
        </v-col> -->
        
        <v-col cols="12" v-if="items.length" class="white rounded-lg shadow px-5 ">
          <v-row>
            <v-col v-for="(item, index) in items" :key="index" cols="6" sm="4" md="3" lg="2" xl="auto">
              <v-btn depressed @click="sub_category_id = item.sub_category_id"
                :class="sub_category_id === item.sub_category_id ? 'primary' : 'gradients'" class="rounded-md subtitle-1"
                style="width: 100%" elevation="0" :height="50.06" max-height="100">
                <p class="ma-0 py-1">
                  <v-icon size="14" class="d-md" v-if="sub_category_id === item.sub_category_id"
                    left>mdi-check-circle</v-icon>
                  <small>{{ item.sub_category_title }} - {{ item.category_title }}</small>
                </p>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="pa-0 mt-5" v-else>
          <v-alert type="info" outlined>
            <span v-if="goldCaliber == 26">{{ $t("notAvail") }} {{ $t("services") }} {{ $t("here") }}</span>
            <span v-else>{{ $t("notAvail") }} {{ $t("items") }} {{ $t("here") }}</span>
          </v-alert>
        </v-col>
        <v-col cols="12" sm="12" class="px-0">
          <PurchaseUsedForm :subCategories="subCategories" :categoryID="goldCaliber" :sub_category_id="sub_category_id"
            :sales_agents="sales_agents" :selectedItem="selectedItem" :selectedAgent="selectedAgent" />
        </v-col>
      </v-row>

      <v-row justify="center" style="height: 90vh;" align="center" v-if="isLoading == true">
        <v-col cols="auto">
          <v-progress-circular :size="70" :width="5" style="margin: auto; " class="d-block" color="primary"
            indeterminate></v-progress-circular>
          <p class="text-center">{{ $t('Loading data') }}</p>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>



<script>
import PurchaseUsedForm from "../../components/PurchaseUsedForm";
import router from "../../router";
export default {
  name: "PurchasesInvoices",

  data: () => ({
    goldCaliber: 18,
    sub_category_id: null,
    subCategories: [],
    items: [],
    sales_agents: [],
    categoryID: "",
    toggle_caliber: 1,
    isLoading: true,
    gold_price: {},
    selectedAgent: {},
  }),
  components: {
    PurchaseUsedForm,
  },
  computed: {
    filteredItems() {
      return this.items.filter(item => item.category_id == this.goldCaliber)
    },
    selectedItem() {
      return this.items.find(item => item.sub_category_id == this.sub_category_id)
    }
  },
  watch: {
    // goldCaliber() {
    //   this.sub_category_id = null
    // },
    'selectedItem.sub_category_id'() {
      this.goldCaliber = this.selectedItem.category_id
    },
  },
  mounted() {
    if (!this.$global.CheckAction("06-100", 1)) {
      router.push('/login')
    } else {
      this.$api.GET_METHOD("get_create_used_invoice_data").then((response) => {
        this.isLoading = false;
        this.gold_price = response.data.gold_price
        this.subCategories = response.data.sub_categorys
        this.items = response.data.items || [];
        this.sales_agents = response.data.sales_agents || [];
        this.selectedAgent = response.data.sales_agents.length ? response.data.sales_agents[0] : Object
        this.sub_category_id = response.data.items.length ? response.data.items[0].sub_category_id : null
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      })
    }


  },
  methods: {},
};
</script>
