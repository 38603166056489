import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import router from "../router";
import store from "../store";
import i18n from "./i18n";
//////////////////


var accessToken = localStorage.getItem("access-token");
const branch = localStorage.getItem("currentbranch");


///////// Axios Defaults Header /////////

axios.defaults.baseURL = store.state.endpointURL + 'api/v1/' + store.state.Settings.language + "/";
axios.defaults.headers.common["access-token"] = store.state.apiAccess;
axios.defaults.headers.common["clientID"] = store.state.clientID;
axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
axios.defaults.headers.common["currentbranch"] = branch;
axios.defaults.headers.common["platform"] = 'POS';
axios.defaults.headers.common["app_ver"] = `pos_web_${store.state.app_version}`;
// axios.defaults.timeout = 180000; // 3min

export default {
  install(Vue) {
    // define a global property
    Vue.PLUGIN_VERSION = "0.0.2";
    Vue.prototype.$api = {
      serverUrl: store.state.endpointURL,
      //  GET ------------------------------------------------------------
      async GET_METHOD(RESOURCE_NAME) {
        // if (navigator.onLine == true) {
        try {
          const response = await axios.get(RESOURCE_NAME);
          if (response.data.check == false) {
            this.ShowAlert("error", i18n.t("Error"), response.data.msg);
          }
          return response.data;
        } catch (error) {
          console.log('error ==>', error.response.status);
          this.SetError(error.response.status, error, RESOURCE_NAME, '', 'GET');
          return false
        }
        // else {
        //   this.ShowModal("error", null, i18n.t("No Internet Connection"));
        //   return false;
        // }
      },
      //  Download File ------------------------------------------------------------
      async DOWNLOAD_METHOD(RESOURCE_NAME) {
        window.open(`${store.state.endpointURL}${RESOURCE_NAME}&clientID=${store.state.clientID}`);
      },
      //  POST ------------------------------------------------------------
      async POST_METHOD(RESOURCE_NAME, NEW_DATA, TOAST = false, TOAST_MESSAGE = " ") {
        // if (navigator.onLine == true) {
        try {
          const response = await axios.post(RESOURCE_NAME, NEW_DATA);
          if (TOAST) {
            this.ShowAlert("success", " ", TOAST_MESSAGE);
          }
          if (!TOAST && response.data.check == true && response.data.msg) {
            this.ShowAlert("success", " ", response.data.msg);
          }
          if (response.data.check == false) {
            this.ShowAlert("error", i18n.t("Error"), response.data.msg);
          }
          return response.data;
        } catch (error) {
          if (error.response) {
            this.SetError(error.response.status, error, RESOURCE_NAME, NEW_DATA, 'POST');
            console.log('error ==>', error.response.status, error.response.data, error.response.headers);
            return false;
          } else if (error.request) {
            console.log('error ==>', error.request);
            this.SetError(null, error.request, RESOURCE_NAME, NEW_DATA, 'POST');
            return false;
          } else {
            this.SetError(null, error.message, RESOURCE_NAME, NEW_DATA, 'POST');
            console.log('error ==>', error.message);
            return false;
          }
        }
        // } 
        //else {
        //   this.ShowModal("error", null, i18n.t("No Internet Connection"));
        //   return false;
        // }
      },
      //  POST Multipart File ------------------------------------------------------------
      async POST_METHOD_MULTIPART(RESOURCE_NAME, NEW_DATA, TOAST = false, TOAST_MESSAGE = " ") {
        try {
          const formData = new FormData();
          Object.keys(NEW_DATA).forEach((key) => {
            formData.append(key, NEW_DATA[key]);
          });
          const response = await axios.request({
            method: "post",
            url: `${store.state.endpointURL}api/v1/${store.state.Settings.language}/${RESOURCE_NAME}`,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          });
          if (TOAST) {
            this.ShowAlert("success", " ", TOAST_MESSAGE);
          }
          if (!TOAST && response.data.check == true && response.data.msg) {
            this.ShowAlert("success", " ", response.data.msg);
          }
          if (response.data.check == false) {
            this.ShowAlert("error", i18n.t("Error"), response.data.msg);
          }
          return response.data;
        } catch (error) {
          if (error.response) {
            this.SetError(error.response.status, error, RESOURCE_NAME, NEW_DATA, 'POST');
            console.log(
              "error ==>",
              error.response.status,
              error.response.data,
              error.response.headers
            );
            return false;
          } else if (error.request) {
            console.log("error ==>", error.request);
            this.SetError(null, error.request, RESOURCE_NAME, NEW_DATA, 'POST');
            return false;
          } else {
            this.SetError(null, error.message, RESOURCE_NAME, NEW_DATA, 'POST');
            console.log("error ==>", error.message);
            return false;
          }
        }
      },
      //  PUT ------------------------------------------------------------
      async PUT_METHOD(RESOURCE_NAME, ID, NEW_DATA, TOAST = false, TOAST_MESSAGE = " ") {
        try {
          const response = await axios.put(RESOURCE_NAME + "/" + ID, NEW_DATA);
          if (TOAST) {
            this.ShowAlert("success", " ", TOAST_MESSAGE);
          }
          return response.data;
        } catch (error) {
          console.log(error);
          this.SetError(error.response.status, error, RESOURCE_NAME, NEW_DATA, 'PUT');
        }
      },
      //  DELETE ------------------------------------------------------------
      async DELETE_METHOD(RESOURCE_NAME, ID, TOAST = false, TOAST_MESSAGE = " ") {
        try {
          const response = await axios.delete(RESOURCE_NAME + "/" + ID);
          if (TOAST) {
            this.ShowAlert("success", " ", TOAST_MESSAGE);
          }
          return response.data;
        } catch (error) {
          console.log(error);
          this.SetError(error.response.status, error, RESOURCE_NAME, ID, 'DELETE');
        }
      },
      //   LOGIN ------------------------------------------------------------
      async LOGIN_AUTH(LOGIN_DATA, TOAST = true, TOAST_MESSAGE = i18n.t("Welcome Back")) {
        try {
          const response = await axios.post("login", LOGIN_DATA);
          if (response?.data?.check == false) {
            this.ShowAlert("error", i18n.t(response.data.msg), "");
          } else {
            if (TOAST) {
              this.ShowAlert("success", i18n.t("Logged in successfully"), TOAST_MESSAGE);
            }
            this.SET_AUTH(response.data.data);
          }
          return response.data;
        } catch (error) {
          console.log(error);
          this.ShowModal("error", ' ', i18n.t(error.response.data.msg), 10000);
          this.SetError(error?.response?.status, error, "login", LOGIN_DATA, 'POST');
        }
      },
      // SET Branch Year
      SET_Branch_Year(branchid, yearid, reload = true) {
        if (branchid) {
          axios.defaults.headers.common["branchid"] = branchid;
        }
        if (yearid) {
          axios.defaults.headers.common["yearid"] = yearid;
        }
        if (reload == true) {
          window.location.reload(true);
        }
      },
      ///////// Set Auth Data /////////
      SET_AUTH(AUTH_DATA) {
        localStorage.setItem(
          Vue.prototype.$global.CRYPT("cloud", "access-token"),
          Vue.prototype.$global.CRYPT("cloud", AUTH_DATA.api_token)
        );
        axios.defaults.headers.common["Authorization"] = "Bearer " + AUTH_DATA.api_token;
        if (AUTH_DATA.user.shift) {
          // router.push("/");
          store.state.isAuth = true;
          localStorage.setItem("lastUpdate", AUTH_DATA.user.shift.start_date);
          store.dispatch("setLastUpdate", AUTH_DATA.user.shift.start_date);
          store.dispatch("setUser", AUTH_DATA);
          const screens = AUTH_DATA.user.screens;
          store.state.screens = screens;
          localStorage.setItem(
            Vue.prototype.$global.CRYPT("cloud", "screens"),
            Vue.prototype.$global.CRYPT("cloud", JSON.stringify(screens))
          );
          let permissions = [];
          const mypermissions = AUTH_DATA.user.mypermissions;
          Object.keys(mypermissions).map(function (key) {
            var permission = {
              screen_code: mypermissions[key][0].screen_code,
              permission: mypermissions[key][0].permission,
              main_id: mypermissions[key][0].main_id,
              main_title: mypermissions[key][0].main_title,
              cat_id: mypermissions[key][0].cat_id,
              cat_title: mypermissions[key][0].cat_title,
              sub_id: mypermissions[key][0].sub_id,
              sub_title: mypermissions[key][0].sub_title,
              url: mypermissions[key][0].url,
            };
            permissions.push(permission);
          });
          store.state.permissions = permissions;
          axios.defaults.headers.common["currentbranch"] = AUTH_DATA.user.shift.branch_id;
          localStorage.setItem(
            Vue.prototype.$global.CRYPT("cloud", "currentbranch"),
            Vue.prototype.$global.CRYPT("cloud", AUTH_DATA.user.shift.branch_id)
          );
          localStorage.setItem(Vue.prototype.$global.CRYPT("cloud", "permissions"), Vue.prototype.$global.CRYPT("cloud", JSON.stringify(permissions)));
          localStorage.setItem(Vue.prototype.$global.CRYPT("cloud", "shift"), Vue.prototype.$global.CRYPT("cloud", JSON.stringify(AUTH_DATA.user.shift)));
        } else {
          router.push("/login");
          this.ShowAlert("info", i18n.t("Logged in successfully"), i18n.t("Please Start Shift"));
        }
        return AUTH_DATA;
      },

      // SET_AUTH(AUTH_DATA) {
      //   if (AUTH_DATA.user.mypermissions.length !== 0) {
      //     store.state.splashScreen = true;
      //     localStorage.setItem(
      //       Vue.prototype.$global.CRYPT("cloud", "access-token"),
      //       Vue.prototype.$global.CRYPT("cloud", AUTH_DATA.api_token)
      //     );
      //     axios.defaults.headers.common["Authorization"] =
      //       "Bearer " + AUTH_DATA.api_token;
      //     store.state.userData = AUTH_DATA;
      //     store.state.shift = AUTH_DATA.user.shift;
      //     store.state.current_branch = localStorage.getItem("branchid")
      //       ? localStorage.getItem("branchid")
      //       : AUTH_DATA.user.current_branch;
      //     store.state.current_year = AUTH_DATA.user.current_year;
      //     //  branches
      //     const branches = [];
      //     Object.keys(AUTH_DATA.user.branchs).forEach((key) =>
      //       branches.push({
      //         id: Number(+key),
      //         name: AUTH_DATA.user.branchs[key],
      //       })
      //     );
      //     store.state.branches = branches;
      //     //  years
      //     const years = [];
      //     Object.keys(AUTH_DATA.user.years).forEach((key) =>
      //       years.push({
      //         id: Number(+key),
      //         name: AUTH_DATA.user.years[key],
      //       })
      //     );
      //     store.state.years = years;
      //     //  screens
      //     const screens = AUTH_DATA.user.screens;
      //     store.state.screens = screens;
      //     localStorage.setItem(
      //       Vue.prototype.$global.CRYPT("cloud", "screens"),
      //       Vue.prototype.$global.CRYPT("cloud", JSON.stringify(screens))
      //     );
      //     //  permissions
      //     let permissions = [];
      //     const mypermissions = AUTH_DATA.user.mypermissions;
      //     Object.keys(mypermissions).map(function (key) {
      //       var permission = {
      //         screen_code: mypermissions[key][0].screen_code,
      //         permission: mypermissions[key][0].permission,
      //         main_id: mypermissions[key][0].main_id,
      //         main_title: mypermissions[key][0].main_title,
      //         cat_id: mypermissions[key][0].cat_id,
      //         cat_title: mypermissions[key][0].cat_title,
      //         sub_id: mypermissions[key][0].sub_id,
      //         sub_title: mypermissions[key][0].sub_title,
      //         url: mypermissions[key][0].url,
      //       };
      //       permissions.push(permission);
      //     });
      //     store.state.permissions = permissions;
      //     localStorage.setItem(
      //       Vue.prototype.$global.CRYPT("cloud", "permissions"),
      //       Vue.prototype.$global.CRYPT("cloud", JSON.stringify(permissions))
      //     );
      //     store.state.isAuth = true;
      //     store.state.splashScreen = false;
      //     return AUTH_DATA;
      //   } else {
      //     this.ShowModal(
      //       "error",
      //       "",
      //       i18n.t("you are haven't any permission assigned yet")
      //     );
      //   }
      // },
      ///////// Check Internet Connection /////////
      CHECK_INTERNET() {
        return navigator.onLine
      },
      ///////// Logout /////////
      LOGOUT(reload = true) {
        store.state.isAuth = false;
        store.state.permissions = [];
        store.state.InvoiceStore.bill = [];
        var lang = localStorage.getItem("language");
        localStorage.clear();
        localStorage.setItem("language", lang ? lang : "ar");
        axios.defaults.headers.common["Authorization"] = null;
        axios.defaults.headers.common["currentbranch"] = null;
        axios.defaults.headers.common["clientID"] = null;
        if (reload == true) {
          router.push("/login");
          window.location.reload(true);
        } else {
          router.push("/login");
        }
      },

      ///////// API Errors Action /////////
      SetError(STATUS, ERROR, ENDPOINT, PAYLOAD, METHOD) {
        store.state.splashScreen = false;
        switch (STATUS) {
          case 401:
            // not auth
            // this.ShowModal("error", "Request error", ERROR);
            this.LOGOUT(false)
            break;
          case 404:
            // not found
            // router.push("/notfound");
            this.ShowSnack('error', 'error 404')
            Vue.prototype.$issues.SEND_ISSUE(STATUS, ERROR, ENDPOINT, PAYLOAD, METHOD , '')
            break;
          case 403:
            // not Auth
            this.LOGOUT(true)
            // this.ShowModal("error", "Request error", ERROR);
            break;
          case 500:
            // server error
            this.ShowModal("error", i18n.t("error happend"), i18n.t("Try to refresh the page or feel free to contact us if the problem persists"));
            Vue.prototype.$issues.SEND_ISSUE(STATUS, ERROR, ENDPOINT, PAYLOAD, METHOD , '')
            break;

          default:
            if (ERROR.code == "ERR_NETWORK") {
              this.ShowModal("error", "Request error", ERROR);
              Vue.prototype.$issues.SEND_ISSUE(STATUS, ERROR, ENDPOINT, PAYLOAD, METHOD, 'Network Connection Error')
            } else { //CORS ERROR
              this.ShowModal("error", i18n.t("error happend"), ERROR);
              Vue.prototype.$issues.SEND_ISSUE(STATUS, ERROR, ENDPOINT, PAYLOAD, METHOD, 'CORS ERROR')
            }
            break;
        }

      },
      ShowSnack(TYPE = 'success', TITLE, MSG = '', TIMER = 4500) {
        store.state.snackbarTitle = i18n.t(TITLE);
        store.state.snackbarType = TYPE;
        store.state.showSnackbar = true;
        store.state.snackbarTime = TIMER;
        store.state.snackbarMessages = typeof MSG === 'string' ? [MSG] : MSG;
      },
      ///////// Alert Toast /////////
      ShowAlert(TYPE, TITLE, MSG) {
        Swal.fire({
          icon: TYPE,
          title: i18n.t(TITLE),
          text: i18n.t(MSG),
          toast: true,
          timer: 3000,
          timerProgressBar: true,
          position: store.state.Settings.translation.rtl == true ? "top-start" : "top-end",
          showConfirmButton: false,
          showCloseButton: true,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.getHtmlContainer().addEventListener("mouseleave", () => {
              Swal.resumeTimer();
            });
            Swal.getHtmlContainer().addEventListener("mouseover", () => {
              Swal.stopTimer();
            });
          },
        });
      },
      ///////// Modal /////////
      ShowModal(TYPE, TITLE, MSG, TIMER = 3000) {
        Swal.fire({
          icon: TYPE,
          title: i18n.t(TITLE),
          text: i18n.t(MSG),
          toast: false,
          timer: TIMER,
          showConfirmButton: false,
          showCloseButton: false,
          allowOutsideClick: true,
          timerProgressBar: true,
          allowEscapeKey: true,
          didOpen: () => {
            Swal.getHtmlContainer().addEventListener("mouseleave", () => {
              Swal.resumeTimer();
            });
            Swal.getHtmlContainer().addEventListener("mouseover", () => {
              Swal.stopTimer();
            });
          },
        });
      },
      //  Print
      PrintInvoice(URL, ID = 0) {
        // var printInvoice = window.open('_blank', 'فاتورة Qarat - POS', 'width=50,height=50');
        // printInvoice.document.write('<html><head><style type="text/css"> img{opacity : 0;}@media print {img{opacity: 1;}} </style></head>');
        // printInvoice.document.write(`<img ${src}  onload="window.print(); window.close()" />`);
        // printInvoice.document.write('</body></html>');
        let printInvoice = document.createElement('iframe');
        printInvoice.name = "printInvoice_" + ID;
        printInvoice.style.position = "absolute";
        printInvoice.style.top = "-1000000px";
        document.body.appendChild(printInvoice);
        let invoice = printInvoice.contentWindow ? printInvoice.contentWindow : printInvoice.contentDocument.document ? printInvoice.contentDocument.document : printInvoice.contentDocument;
        invoice.document.open();
        invoice.document.write(`<html lang="ar"><head><title> فاتورة قيراط رقم ${ID}</title>`);
        invoice.document.write('</head><body>');
        invoice.document.write(`<img ${URL} onload="window.print(); window.close()"  />`);
        invoice.document.write('</body></html>');
        return false;
      },
      PrintInvoiceURL(URL, ID = 0) {
        var printContent = document.getElementById("printInvoice");
        console.log("printContent", printContent);
        document.title = "qarat invoice " + ID
        let printInvoice = document.createElement("iframe");
        printInvoice.name = "printInvoice_" + ID;
        printInvoice.style.position = "absolute";
        printInvoice.style.top = "-1000000px";
        document.body.appendChild(printInvoice);
        let invoice = printInvoice.contentWindow
          ? printInvoice.contentWindow
          : printInvoice.contentDocument.document
            ? printInvoice.contentDocument.document
            : printInvoice.contentDocument;
        invoice.document.open();
        invoice.document.write(`<html lang="ar"><head><title>t${ID}</title>`);
        invoice.document.write(`</head><body><iframe src='${URL}'></iframe>`);
        // invoice.document.write(printContent.innerHTML);
        invoice.document.write("</body></html>");
        return false;
      },
    };
  },
};
