<template>
   <v-dialog v-model="dialog" overlay-opacity="0.75" persistent :max-width="step == 1 ? 950 : 550">
      <v-card relative class="pa-5">

         <CategoriesBar v-if="step == 1" :categories="categories" :gold_price="gold_price"
            :backValueMethod="setActiveCategory" :activeCategory="itemForm.category_id" :goldOnly="false" />

         <v-col cols="12" v-if="filteredSubCategory.length && step == 1" class="mt-3">
            <v-row>
               <v-col class="pa-1" v-for="(item, index) in filteredSubCategory" :key="index" cols="6" sm="4" md="3"
                  xl="auto">
                  <v-btn depressed @click="selectSubCategory(item)"
                     :class="itemForm.sub_category_id === item.id ? 'primary' : 'gradients'" class="rounded-md subtitle-1"
                     style="width: 100%" elevation="0" :height="50.06" max-height="100">
                     <p class="ma-0 py-1">
                        <v-icon size="14" class="d-md" v-if="itemForm.sub_category_id === item.id"
                           left>mdi-check-circle</v-icon>
                        <small>{{ item.title }} - {{ item.category_name }}</small>
                     </p>
                  </v-btn>
               </v-col>
            </v-row>
         </v-col>
         <v-col cols="12" class="pa-0 mt-5" v-if="filteredSubCategory.length == 0 && step == 1">
            <v-alert type="info" outlined>
               <span v-if="itemForm.category_id == 26">{{ $t("notAvail") }} {{ $t("services") }} {{ $t("here") }}</span>
               <span v-else>{{ $t("notAvail") }} {{ $t("items") }} {{ $t("here") }}</span>
            </v-alert>
         </v-col>

         <!-- Gold  -->
         <v-row
            v-if="itemForm.category_id == 18 || itemForm.category_id == 21 || itemForm.category_id == 22 || itemForm.category_id == 24">
            <v-col cols="12" class="d-block ma-auto px-10" v-if="step == 2">
               <label>{{ $t('gold weight') }}*</label>
               <NumberPad :backValueMethod="setGoldWeight" :value="String(itemForm.gold_weight)" />
            </v-col>
            <v-col cols="12" class="d-block ma-auto px-10" v-if="step == 3">
               <label>{{ $t('caliber price') }}*</label>
               <NumberPad :backValueMethod="setCaliberPrice" :value="String(itemForm.caliber_price)" />
            </v-col>
         </v-row>

         <!-- Diamond -->
         <v-row v-if="itemForm.category_id == 25">
            <v-col cols="12" class="d-block ma-auto px-10" v-if="step == 2">
               <label>{{ $t('diamond weight') }}*</label>
               <NumberPad :backValueMethod="setDiamondWeight" :value="String(itemForm.diamond_weight)" />
            </v-col>
            <v-col cols="12" class="d-block ma-auto px-10" v-if="step == 3">
               <label>{{ $t('gold weight') }}*</label>
               <NumberPad :backValueMethod="setGoldWeight" :value="String(itemForm.gold_weight)" />
            </v-col>
            <v-col cols="12" class="d-block ma-auto" v-if="step == 4">
               <v-col cols="12" class="mb-2">
                  <CategoriesBar :categories="categories" :gold_price="gold_price" :backValueMethod="setDiamondCaliber"
                     :activeCategory="itemForm.caliber_id" :goldOnly="true" />
               </v-col>
               <GenericInput type="select" :lookups="shapes" :value="itemForm.shap_id" @input="itemForm.shap_id = $event"
                  label="shape" :multi="false" :required="false" selected_label="title" selected_prop="id"
                  :isLoading="false" :cols="[12, 12, 12]">
               </GenericInput>
               <GenericInput type="select" :lookups="colors" :value="itemForm.color_id" @input="itemForm.color_id = $event"
                  label="color" :multi="false" :required="false" selected_label="color_code" selected_prop="color_id"
                  :isLoading="false" :cols="[12, 12, 12]">
               </GenericInput>
               <GenericInput type="select" :lookups="qualities" :value="itemForm.quality_id"
                  @input="itemForm.quality_id = $event" label="quality" :multi="false" :required="false"
                  selected_label="title" selected_prop="id" :isLoading="false" :cols="[12, 12, 12]">
               </GenericInput>
               <GenericInput type="select" :lookups="clarities" :value="itemForm.clarity_id"
                  @input="itemForm.clarity_id = $event" label="clarity" :multi="false" :required="false"
                  selected_label="clarity_code" selected_prop="clarity_id" :isLoading="false" :cols="[12, 12, 12]">
               </GenericInput>
            </v-col>
         </v-row>

         <!-- Stone  -->
         <v-row v-if="itemForm.category_id == 26">
            <v-col cols="12" class="d-block ma-auto px-10" v-if="step == 2">
               <label>{{ $t('stone weight') }}*</label>
               <NumberPad :backValueMethod="setStoneWeight" :value="String(itemForm.stone_weight)" />
            </v-col>
         </v-row>
         <!-- Silver  -->
         <v-row v-if="itemForm.category_id == 925">
            <v-col cols="12" class="d-block ma-auto px-10" v-if="step == 2">
               <label>{{ $t('silver weight') }}*</label>
               <NumberPad :backValueMethod="setSilverWeight" :value="String(itemForm.silver_weight)" />
            </v-col>
         </v-row>


         <v-col cols="12" class="d-flex" :class="step == 1 ? 'justify-end' : 'justify-center'">
            <v-btn color="error" @click="closeDialog" outlined width="120" depressed>
               {{ $t('close') }}
            </v-btn>
            <v-btn color="light" v-if="step != 1" @click="step--" class="primary--text mx-2" width="120" depressed>
               {{ $t('back') }}
            </v-btn>
            <v-btn color="primary" v-if="step != 1" @click="next" depressed width="120">
               {{ (step == 1 || step == 2 || (step == 3 && itemForm.category_id == 25)) ? $t('next') : $t('add') }}
            </v-btn>
         </v-col>
      </v-card>
   </v-dialog>
</template>

<script>
import GenericInput from "@/components/GenericInput.vue";
import CategoriesBar from "@/components/CategoriesBar.vue";
import NumberPad from "@/components/NumberPad.vue";
export default {
   name: "StocktakingAddNewLine",
   props: {
      closeDialog: { type: Function },
      addItem: { type: Function },
      dialog: { default: false },
      vats: { default: null },
      categories: { default: [] },
      subCategories: { default: [] },
      gold_price: { default: null },
      clarities: { default: [] },
      qualities: { default: [] },
      colors: { default: [] },
      shapes: { default: [] },

   },
   computed: {
      filteredSubCategory() {
         return this.subCategories.filter((item) => item.category_id == this.itemForm.category_id)
      },
   },
   components: {
      GenericInput,
      CategoriesBar,
      NumberPad
   },
   watch: {
      'itemForm.clarity_id'() {
         const clarity = this.clarities.find((clarity) => clarity.clarity_id == this.itemForm.clarity_id)
         this.itemForm.clarity_title = clarity.clarity_code
      },
      'itemForm.quality_id'() {
         const quality = this.qualities.find((quality) => quality.id == this.itemForm.quality_id)
         this.itemForm.quality_title = quality.title
      },
      'itemForm.shap_id'() {
         const shape = this.shapes.find((shape) => shape.id == this.itemForm.shap_id)
         this.itemForm.shape_title = shape.title
      },
      'itemForm.color_id'() {
         const color = this.colors.find((color) => color.color_id == this.itemForm.color_id)
         this.itemForm.color_title = color.color_code
      },
   },
   data: () => ({
      step: 1,
      itemForm: {
         id: 0,
         item_code: 0,
         category_id: null,
         category_name: null,
         department_name: null,
         sub_category_id: null,
         sub_category_name: null,
         gold_weight: 0,
         weight: 0,
         // diamond
         diamond_weight: 0,
         caliber_id: 0,
         caliber_price: 0,
         clarity_id: 0,
         quality_id: 0,
         color_id: 0,
         shap_id: 0,
         // stone
         stone_weight: 0,
         // silver
         silver_weight: 0,
      }
   }),
   methods: {
      setActiveCategory(cat) {
         this.itemForm.category_id = cat
         if (cat == 25) {
            this.itemForm.caliber_id = 18
         } else {
            this.itemForm.weight = 0;
            this.itemForm.gold_weight = 0;
            this.itemForm.caliber_id = 0;
            this.itemForm.caliber_price = 0;
            this.itemForm.clarity_id = 0;
            this.itemForm.diamond_weight = 0;
            this.itemForm.quality_id = 0;
            this.itemForm.color_id = 0;
            this.itemForm.shap_id = 0;
            this.itemForm.stone_weight = 0;
            this.itemForm.silver_weight = 0;
         }
      },
      setDiamondCaliber(cat) {
         this.itemForm.caliber_id = cat
      },
      selectSubCategory(item) {
         this.itemForm.category_id = item.category_id;
         this.itemForm.category_name = item.category_name;
         this.itemForm.department_name = item.department_name;
         this.itemForm.sub_category_name = `${item.name} - ${item.category_name}`;
         this.itemForm.sub_category_id = item.id;
         this.step = 2;
      },
      next() {
         if (this.itemForm.category_id >= 18 && this.itemForm.category_id <= 24 && (this.step == 1 || this.step == 2)) {
            this.step = this.step + 1
         }
         else if (this.itemForm.category_id == 25 && (this.step == 1 || this.step == 2 || this.step == 3)) {
            this.step = this.step + 1
         }
         else {
            console.log(this.itemForm);
            this.addItem(this.itemForm)
         }
      },
      setGoldWeight(returnedAmount) {
         this.itemForm.gold_weight = +returnedAmount;
         this.itemForm.weight = +returnedAmount;
      },
      setDiamondWeight(returnedAmount) {
         this.itemForm.diamond_weight = +returnedAmount;
         this.itemForm.weight = +returnedAmount;
      },
      setStoneWeight(returnedAmount) {
         this.itemForm.stone_weight = +returnedAmount;
         this.itemForm.weight = +returnedAmount;
      },
      setSilverWeight(returnedAmount) {
         this.itemForm.silver_weight = +returnedAmount;
         this.itemForm.weight = +returnedAmount;
      },
      setCaliberPrice(returnedAmount) {
         this.itemForm.caliber_price = +returnedAmount;
      },
   },
   mounted() {


   },
};
</script>
