import i18n from "../plugins/i18n";
import vuetify from "../plugins/vuetify";

export default {
  namespaced: true,
  state: {
    language: localStorage.getItem("language") == "ar" ? "ar" : "en",
    translation:
      localStorage.getItem("language") == "en"
        ? i18n.messages.en
        : i18n.messages.ar,
    isRTL: vuetify.rtl,
    windowSize: { x: 0, y: 0 },
    darkTheme: localStorage.getItem("darkTheme") == "true" ? true : false,
    requiredRule: [(v) => !!v || i18n.t("Field is required")],
    notRequiredRule: [],
    emailRule: [
      (v) => !!(v || "").match(/@/) || i18n.t("Please enter email address"),
    ],
    mobileRule: [
      (v) =>
        !!(v || "").match(/^(?:[+0]9)?[0-9]{10,12}$/) ||
        i18n.t("Please enter mobile number"),
    ],
    passwordRule: [
      (v) => !!v || i18n.t("Password is required"),
      // (v) =>
      //   (v && v.length >= 5) ||
      //   i18n.t("Password must be greater than 5 characters"),
    ],
  },
  mutations: {
    DARK_THEME(state, status) {
      state.darkTheme = status;
    },
    ACTIVE_LANGUAGE(state, lang) {
      state.language = lang;
      state.translation = lang == "ar" ? i18n.messages.ar : i18n.messages.en;
      i18n.locale = lang;
    },
  },
  actions: {
    changeLanguage({ state }) {
      if (state.language == "en") {
        // commit("ACTIVE_LANGUAGE", "ar");
        // vuetify.framework.lang.current = ar;
        localStorage.setItem("language", "ar");
        // return (vuetify.framework.rtl = true);
        window.location.reload(true);
      } else {
        // commit("ACTIVE_LANGUAGE", "en");
        // vuetify.framework.lang.current = en;
        localStorage.setItem("language", "en");
        // return (vuetify.framework.rtl = false);
        window.location.reload(true);
      }
    },

    darkTheme({ commit, state }) {
      localStorage.setItem("darkTheme", !state.darkTheme);
      commit("DARK_THEME", !state.darkTheme);
      return (vuetify.framework.theme.dark = state.darkTheme);
    },
  },
};
