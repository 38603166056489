<template>
  <v-dialog v-model="dialog" overlay-opacity="0.75" :persistent="persistent" :max-width="width">
    <v-card relative class="backgroundW py-3 overflow-hidden">
      <v-btn absolute class="" :style="$vuetify.rtl == true ? 'left: 5px' : 'right: 5px'" small fab color="grey lighten-1"
        text @click="closeDialog">
        <v-icon color="grey lighten-1">mdi-close</v-icon>
      </v-btn>
      <img :src="getImgUrl(dialogContent.img)" height="75" class="mx-auto mt-5 d-block" alt="Qarat"
        v-if="dialogContent.img" />

      <v-col cols="12" class="pa-0 text-center">
        <v-icon v-if="dialogContent.icon" class="" size="75" :color="dialogContent.buttonOneColor || 'primary'">
          {{ dialogContent.icon }}</v-icon>
      </v-col>

      <v-col cols="12" :class="{ 'mt-4' : !dialogContent.icon || !dialogContent.img}">
        <h5 v-if="dialogContent.title" class="text-center text-h6 font-weight-bold">{{ $t(dialogContent.title) | capitalize }}</h5>
        <slot name="dialogTitle"></slot>
      </v-col>

      <v-col cols="12" sm="12" v-if="dialogContent.details">
        <div class="body-2  px-5">
          <div class="text-center gray8--text subtitle-2 font-weight-bold">{{ $t(dialogContent.details) | capitalize }}
          </div>
        </div>
      </v-col>
      <slot name="dialogDetails"></slot>
      <slot name="content" />
      <v-col cols="12" class="py-4 px-6">
        <v-row justify="end">
          <v-col cols="auto">
            <v-btn @click="buttonTwoMethod" text class="" min-width="120" :class="dialogContent.buttonTwoClass"
              :color="dialogContent.buttonTwoColor" :loading="buttonTwoLoading" v-if="dialogContent.buttonTwoTitle"
              style="width: 100%; text-align: start">
              {{ $t(dialogContent.buttonTwoTitle) }}</v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn :color="dialogContent.buttonOneColor" min-width="120" @click="buttonOneMethod" class="shadow"
              :class="dialogContent.buttonOneClass" :loading="buttonOneLoading" v-if="dialogContent.buttonOneTitle"
              style="width: 100%; text-align: start">
              {{ $t(dialogContent.buttonOneTitle) }}
            </v-btn>
          </v-col>
          <slot name="dialogFooter"></slot>
        </v-row>
      </v-col>
    </v-card>
  </v-dialog>
</template>



<style scoped lang="scss"></style>



<script>

export default {
  name: "CustomModal",
  props: {
    width: { default: 500 },
    buttonOneMethod: { type: Function },
    buttonTwoMethod: { type: Function },
    dialog: { default: false },
    persistent: { default: false },
    buttonOneLoading: { default: false },
    buttonTwoLoading: { default: false },
    dialogContent: {
      default: {
        icon: '',
        title: '',
        details: '',
        img: 'watchOut.png',
        buttonOneTitle: '',
        buttonOneColor: 'primary',
        buttonOneClass: '',
        buttonTwoTitle: '',
        buttonTwoColor: 'error',
        buttonTwoClass: '',
      }
    },

  },
  computed: {},
  data() {
    return {

    };
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.$emit("close", false);
    },
    getImgUrl(img) {
      var images = require.context('@/assets/img/png/');
      return images('./' + img)
    },
  },
  mounted() {
  }
};
</script>
