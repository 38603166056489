// import summery from '@/plugins/summery.js';
export const EntityMixins = {
  data: () => ({
    endpoint: null,
    showEndpoint: null,
    extraHeaderParams:  new URLSearchParams(),
    tab_type: 1, // 1 => Master & Details, 2 => Iframe
    screen_type: 1, // 1 => Code, 2 => Transaction, 3 => Invoice, 4 => Report
    response: {},
    pageData: {
      entityMixin: true,
      screen_code: null,
      list_key: 'items',
      response: {},
      url: null,
      controlRoute: null,
      entityName: null,
      main: null,
      category: null,
      isLoading: false,
      reportIsLoading: false,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      queryParam: new URLSearchParams(),
      printLoading: {
        loading: false,
        id: null,
        key: 'id'
      },
      viewFilter: true,
      today: null,
      from_date: null,
      to_date: null,
      from: null,
      to: null,
      date: null,
    },
  }),
  computed: {
    calculatedTotals() {
      const calculateTotal = (property) => this.pageData.rows.reduce((prevValue, row) => {
        return prevValue + row[property];
      }, 0);
      let totalObj = {};
      this.tableHeader.forEach((header, index) => {
        switch (header.type) {
          case 'float':
          case 'non_zero_float':
          case 'weight':
          case 'number':
          case 'price':
            totalObj[header.key] = calculateTotal(header.key);
            break;
          default:
            totalObj[header.key] = !index ? this.$t('total') : '';
            break;
        }
      });
      return totalObj;
    },
  },
  watch: {
    $route() {
      this.pageData.queryParam = new URLSearchParams();
      this.queryParams();
      this.pageMainData();
      this.getData();
    },
  },
  mounted() {
    this.queryParams();
    this.pageMainData();
  },
  methods: {
    queryParams() {
      this.pageData.queryParam.set("active", 1)
      this.pageData.queryParam.set("page", this.pageData.page)
      this.pageData.queryParam.set("rows", this.pageData.rowsNumber)
      this.pageData.queryParam.set("word", this.pageData.search)
    },
    pageMainData() {
      let activeScreen = this.$global.FilterPermissions(this.screen_code)
      document.title = `${this.$i18n.t("Qarat - ")}${activeScreen.sub_title}`;
      this.pageData.main = activeScreen.main_title || null;
      this.pageData.category = activeScreen.cat_title || null;
      this.pageData.entityName = activeScreen.sub_title || null;
      this.pageData.url = activeScreen.url || null;
      const ROUTE = this.pageData.url.startsWith("/") ? "" : "/";
      this.pageData.controlRoute = `${ROUTE}${this.pageData.url}-control/`;
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
      };
    },
    getData() {
      if (this.$global.CheckAction(this.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(`${this.endpoint}?${this.pageData.queryParam.toString()}&${this.extraHeaderParams.toString()}`).then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.response = response.data;
              const listKeyData = response.data[this.pageData.list_key] || {};
              const {
                data: rows = [],
                current_page: page = 0,
                last_page: totalPages = 0,
                per_page: perPage = 0,
                total: totalRows = 0
              } = listKeyData;
              this.pageData.entityName = this.pageData.isTrashed === 0 ? this.$i18n.t("deleted from") + " " + this.pageData.entityName : this.pageData.entityName;
              this.pageData.rows = rows;
              this.pageData.page = page;
              this.pageData.pagination = { page, totalPages, per_page: perPage, totalRows, };
              this.setResponse(response);
            }
          });
      } else {
        this.$router.push("/");
      }
    },
    setResponse() { },
    getSearch(word) {
      this.pageData.queryParam.set("word", word || '')
      this.pageData.queryParam.set("page", 1)
      this.$router.push(this.pageData.url + '?' + this.pageData.queryParam.toString().replace(/\w+=&/g, '').replace(/&\w+=$/, ''))
    },
    changePage(page, limit) {
      this.pageData.queryParam.set("page", page || 1);
      this.pageData.queryParam.set("rows", limit || 15);
      this.$router.push(this.pageData.url + '?' + this.pageData.queryParam.toString().replace(/\w+=&/g, '').replace(/&\w+=$/, ''))
    },
    changeRoute() {
      this.$router.push(this.pageData.url + '?' + this.pageData.queryParam.toString().replace(/\w+=&/g, '').replace(/&\w+=$/, ''))
    },
    edit(row) {
      const CONTAINS_HASH = this.pageData.controlRoute.startsWith("/")
      this.$router.push(`${CONTAINS_HASH ? '' : '/'}${this.pageData.controlRoute}${row.id || row.m_id}`)
    },
    toggleActiveMethod(ID) {
      console.log(ID);
      if (ID) {
        this.$api.POST_METHOD(`${this.endpoint}_toggle_active/${typeof ID === 'object' ? ID.id || ID.m_id : ID}`, null).then((response) => {
          if (response.check) {
            this.getData();
          }
        });
      }
    },
    setDate() {
      this.pageData.today = this.pageData.to_date = this.pageData.to = this.$global.GetCurrentDate();
      this.pageData.from_date = this.pageData.from = this.$global.GetFirstDayOfMonth();
    },
  },
};
