<template>
  <v-card class="shadow overflow-hidden rounded-lg d-flex justify-center my-3 py-5">
    <v-row align="center">
      <v-col cols="12" md="6" class="px-sm-5">
        <h4 class="mb-4">{{ $t('money') }}</h4>
        <v-col cols="12">
          <v-row>
            <v-col cols="auto">
              <div class="d-block pa-2 light-blue accent-4 rounded-pill"></div>
            </v-col>
            <v-col cols="auto">
              <h5 class="text--disabled">{{$t('totalSalesCash') }}</h5>
              <p> {{$global.DecimalFormatter(summary.final.totalSalesCash.toFixed(2))}} {{ $t('ryal') }}</p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto">
          <v-row>
            <v-col cols="auto">
              <div class="d-block pa-2 green lighten-1 rounded-pill"></div>
            </v-col>
            <v-col cols="auto">
              <h5 class="text--disabled">{{ $t('totalUsedCash') }}</h5>
              <p> {{$global.DecimalFormatter(summary.final.totalUsedCash.toFixed(2))}} {{ translation.ryal }}</p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto">
          <v-row>
            <v-col cols="auto">
              <div class="d-block pa-2 light-blue darken-4 rounded-pill"></div>
            </v-col>
            <v-col cols="auto">
              <h5 class="text--disabled">{{ translation.totalSalesMachine }}</h5>
              <p> {{$global.DecimalFormatter(summary.final.totalSalesMachine.toFixed(2))}} {{ translation.ryal }}</p>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
      <v-col cols="12" class="d-flex justify-center" md="6">
        <div id="chart" v-if="series && chartOptions">
          <apexchart type="radialBar" width="280" :options="chartOptions" :series="series"></apexchart>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>








<script>
export default {
  name: "MoneyChart",
  computed: {
    translation() {
      return this.$store.state.Settings.translation;
    },
  },
  props: {
    summary: { default: null },
  },
  mounted() {
    this.total = this.summary.final.totalSalesCash + this.summary.final.totalUsedCash + this.summary.final.totalSalesMachine;
    this.series = [
      ((this.summary.final.totalSalesCash / this.total) * 100).toFixed(2),
      ((this.summary.final.totalUsedCash / this.total) * 100).toFixed(2),
      ((this.summary.final.totalSalesMachine / this.total) * 100).toFixed(2),
    ]
    this.chartOptions = {
      chart: {
        height: 300,
        type: "radialBar",

      },
      fill: {
        type: 'solid',
        colors: ['#0091EA', '#66bb6a', '#01579b']
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: "16px",
            },
            value: {
              fontSize: "14px",
            },
            total: {
              show: false,
              label: "الأجمالي",
      
            },
          },
        },
      },
      labels: [
        "اجمالي مبيعات الكاش",
        "اجمالي مشتريات الكاش",
        "اجمالي مبيعات الماكينة",
      ],
    }
  },
  data: () => ({
    series: [],
    total: 0,
    chartOptions: {},
  }),
};
</script>
