<template>
  <!-- <v-row justify="center"> -->
  <v-dialog v-model="dialog" persistent max-width="450">
    <v-card relative>
      <v-btn
          absolute
          class="ma-2"
          :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'"
          small
          fab
          color="grey lighten-1"
          text
          @click="close"
        >
          <v-icon color="grey lighten-1">mdi-close</v-icon>
        </v-btn>
      <v-col cols="10" class="ma-auto">
        <v-stepper v-model="activeStep" :key="key" elevation="0">
          <v-stepper-header>
            <v-stepper-step step="1">{{  $t("Weight return") }}</v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="2">{{ $t("Return Pieces") }}</v-stepper-step>
          </v-stepper-header>
        </v-stepper>
      </v-col>

      <div class="pa-5 pt-0">
        <v-col cols="12" class="ma-auto" sm="11" v-if="activeStep == 1">
          <v-row justify="center">
            <v-col cols="11" sm="11" class="pa-0">
              <h5 class="mb-5">
                {{ $t("Weight return") }} :
                <small
                  :class="Number(row.return_weight) > (Number(row.weight) - Number(row.returned_weight)) ?'error--text' : 'text--disabled'"
              
                >{{ $t("returnable") }} : {{   $global.DecimalFormatter(Number(row.weight) - Number(row.returned_weight)) }} {{ $t("gram") }}</small>
              </h5>
              <NumberPad
                :backValueMethod="setWeight"
                :value="Number(row.return_weight) == 0 ? '0' : String(row.return_weight)"
              ></NumberPad>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="ma-auto" sm="11" v-if="activeStep == 2">
          <v-row justify="center">
            <v-col cols="12" class="py-0">
              <div class="d-flex justify-space-between align-center light pa-2 rounded-lg shadow">
                <div>
                  <h5>
                    {{ $t('Weight return') }} :
                    <br />
                    <span class="text--disabled">{{ $global.DecimalFormatter(Number(row.return_weight).toFixed(2)) }} {{ $t('gram') }}</span>
                  </h5>
                </div>
                <v-btn small color="error" @click="activeStep = 1">
                  {{ $t('modify') }}
                  <img src="../../assets/img/svg/editIcon.svg" alt="qarat" />
                </v-btn>
              </div>
            </v-col>
            <v-col cols="11" sm="11" class="pa-0">
              <h5 class="my-2">
                {{ $t("Return Pieces") }} :
                <!-- <small
                  class="error--text"
                  v-if="Number(row.return_count_pieces) > Number(row.count_pieces)"
                >{{ $t("returnable") }}: {{  $global.DecimalFormatter(Number(row.count_pieces).toFixed(2)) }}</small> -->
              </h5>
              <NumberPad
                :backValueMethod="setCount"
                :disabledDot="true"
                :value="Number(row.return_count_pieces) == 0 ? '0' : String(row.return_count_pieces)"
              ></NumberPad>
            </v-col>
          </v-row>
        </v-col>

        <v-row justify="space-around" class="my-1">
          <v-col cols="10" class="py-0 ma-auto" v-if="activeStep == 1">
            <v-btn
              color="success"
              large
              @click="activeStep = 2"
              :disabled="(Number(row.return_weight) > (Number(row.weight) - Number(row.returned_weight)))"
              class="shadow"
              style="width: 100%"
            >{{  $t("next") }}</v-btn>
          </v-col>
          <!--
                    <v-col cols="4" class="py-0" v-if="activeStep == 2">
            <v-btn
              color="error"
              large
              outlined
              @click="activeStep = 1"
              class="shadow"
              style="width: 100%"
            >{{ translation.prev }}</v-btn>
          </v-col>
          -->
            <!-- :disabled="(Number(row.return_count_pieces) > Number(row.count_pieces))" -->
          <v-col cols="10" class="py-0" v-if="activeStep == 2">
            <v-btn
              color="success"
              large
              block
              @click="add()"
              class="shadow"
            
            >{{ $t("add") }}</v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
  <!-- </v-row> -->
</template>



<style scoped lang="scss">
</style>



<script>
import NumberPad from "../NumberPad.vue";

export default {
  name: "ReturnSalesModal",
  props: {
    backValueMethod: { type: Function },
    row: { default: {} },
    loading: { default: false },
    dialog: { default: false },
  },
  computed: {
    key(){
      return  this.row.m_id
    },
    isRTL() {
      return this.$store.state.Settings.isRTL;
    },
    translation() {
      return this.$store.state.Settings.translation;
    },
  },
    mounted() {
    window.addEventListener("keydown", e => {
      if (e.key == 'Enter' && this.dialog == true) {
        if (Number(this.row.return_weight) <= (Number(this.row.weight) - Number(this.row.returned_weight)) && this.activeStep == 1) { // weight active
          this.activeStep = 2  //activeStep 2
        }
        else if(this.activeStep == 2 ){ // price active
          this.add() //Done
        }

      }
    });
  },
  components: {
    NumberPad,
  },
  methods: {
    setWeight(returnedWeight) {
      // console.log("weight:", returnedWeight);
      this.row.return_weight = Number(returnedWeight);
    },
    setCount(returnedCount) {
      // console.log("Count:", returnedCount);
      this.row.return_count_pieces = Number(returnedCount);


    },
    add() {
      this.activeStep = 1
      this.backValueMethod(true);
    },
    close() {
      this.setWeight(0);
      this.setCount(0);
      this.activeStep = 1
      this.backValueMethod(true);
    },
  },
  data() {
    return {
      weight: "",
      count: "",
      tab: null,
      activeStep: 1
    };
  },
};
</script>
