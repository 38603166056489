<template>
  <v-container fluid class="bgAuth d-flex align-center justify-center pa-0" style="height: 100vh">
    <v-col cols="11" lg="4" md="6" sm="7">
      <v-card v-if="!startShift" class="login-card">
        <div class="header">
          <img src="../../assets/img/png/qaratHr.png" alt="Qarat-POS" />
        </div>
        <v-col cols="11" class="ma-auto mt-n6 rounded-lg d-block white">
          <h3 class="text-center mt-2 mb-4">{{ $t("logintoQaratPOSSystem") }}</h3>
          <v-form ref="form" class="row align-items-center justify-end flex-row" v-model="valid" lazy-validation>
            <v-col cols="12" class="pb-0 px-6">
              <h5 class="mb-3">{{ $t("company number") }}</h5>
              <v-text-field hide-details="auto" name="company_number" class="rounded-lg" background-color="#e8f0fe" filled
                :rules="companyIDRule" v-model="companyNumber" rounded dense @keydown.enter="login()"
                prepend-inner-icon="mdi-shield-check" type="text"></v-text-field>
            </v-col>
            <v-col cols="12" class="pb-0 px-6">
              <h5 class="mb-3">{{ $t("userName") }}</h5>
              <v-text-field hide-details="auto" class="rounded-lg" background-color="#e8f0fe" filled :rules="usernameRule"
                v-model="username" rounded @keydown.enter="login()" dense prepend-inner-icon="mdi-account-check"
                type="text"></v-text-field>
            </v-col>
            <v-col cols="12" class="px-6">
              <h5 class="mb-3">{{ $t("password") }}</h5>
              <v-text-field hide-details="auto" class="rounded-lg" background-color="#e8f0fe" filled required
                :rules="passwordRule" v-model="password" @keydown.enter="login()" rounded dense
                :append-icon="show ? 'mdi-eye-outline' : 'mdi-eye-off-outline'" :type="show ? 'text' : 'password'"
                prepend-inner-icon="mdi-account-key" @click:append="show = !show"></v-text-field>
            </v-col>
            <v-col cols="6" sm="6" class=" px-4 pa-0 d-flex align-items-center">
              <v-btn plain height="auto" color="primary--text" to="/forget-password">
                {{ $t("Forget Password") }} {{ $t("?") }}
              </v-btn>
            </v-col>
            <v-col cols="6" sm="6" class="py-5 px-6">
              <v-btn @click="login" :loading="loading" :disabled="!valid" class="d-block mi-start-auto shadow"
                color="secondary">{{ $t("login") }}</v-btn>
            </v-col>
          </v-form>
        </v-col>
      </v-card>
      <StartShift v-if="startShift" :branches="branches" />
    </v-col>
  </v-container>
</template>
<style scoped lang="scss">
.bgAuth {
  background-image: url("../../assets/img/png/goldBG.png");
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;

  .login-card {
    background: #efefef;
    padding-bottom: 1.5rem;
    border-radius: 15px;
    overflow: hidden;

    .header {
      background-color: #262525;
      min-height: 176px;
      border-radius: 0 !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
<script>

import StartShift from "../../components/modals/StartShift.vue";
export default {
  name: "LoginPage",
  computed: {
  },
  watch: {
    companyNumber() {
      this.$store.commit('UPDATE_CLIENT', this.companyNumber || null)
    },
  },
  mounted() {
    this.$api.LOGOUT(false);
    localStorage.setItem('app_version', this.$store.state.app_version);
    document.title = this.$i18n.t("Qarat - Login");
  },
  components: {
    StartShift
  },
  data() {
    return {
      show: false,
      loading: false,
      valid: false,
      companyNumber: null,
      username: null,
      password: null,
      startShift: false,
      branches: [],
      usernameRule: [
        v => !!v || this.$i18n.t('Username is required')
      ],
      passwordRule: [
        v => !!v || this.$i18n.t('Password is required'),
        // v => (v && v.length >= 5) || this.$i18n.t('Password must be greater than 5 characters'),
      ],
      companyIDRule: [
        v => !!v || this.$i18n.t('Company number is required'),
        v => (v && v.length >= 3 && v.length <= 5) || this.$i18n.t('Company ID not valid'),
      ],
    }
  },
  methods: {
    login() {
      this.loading = true;
      this.$refs.form.validate()


      if (this.valid == true && this.$store.state.clientID) {
        const requestBody = {
          username: this.username,
          password: this.password,
        }
        this.$api.LOGIN_AUTH(requestBody).then((response) => {
          this.loading = false;
          localStorage.setItem("clientID", this.$store.state.clientID)
          if (response?.check) {
            if (response.data.user.shift == null) {
              this.startShift = true;
              this.$global.sendNotification('Qarat - POS', 'Welcome Back')
              this.branches = response.data.user.branchs
            } else {
              this.$router.push('/');
            }
          } else {
            this.$store.commit('UPDATE_CLIENT', this.companyNumber || null)
          }
        })

      }
      else {
        this.loading = false;
      }


    },

  },
};
</script>
