<template>
  <section :key="key" :class="dashboardAccess ? '' : 'dashboardContainer'">
    <div>
      <GoldPrice  v-if="dashboardAccess" :gold_price="gold_price" :gold_price_direction="gold_price_direction"
        :gold_price_sales="gold_price_sales" :gold_price_purchase="gold_price_purchase" />
        <div v-else>
          <div class="d-flex mb-16 align-center">
            <img src="@/assets/logo.svg" style="opacity: 1.51;" height="230" class="d-block ma-auto"
              alt="qarat" />
          </div>
        </div>
      <v-col cols="auto" class="pa-0 mx-auto">
        <v-card class=" pa-0 transparent shadow" elevation="0">
          <v-row justify="center">
            <v-col cols="auto" class="pa-0 mb-2">
              <v-btn :min-width="150" :large="!dashboardAccess" small v-if="salesPermission" to="/sales/create-sales-invoice" color="#fff"
                depressed class="shadow mx-2" exact-active-class="primary">
                <v-icon size="13">mdi-plus</v-icon> {{ $t("createSalesInvoice") }}
              </v-btn>
            </v-col>
            <v-col cols="auto" class="pa-0 mb-2">
              <v-btn :min-width="150" :large="!dashboardAccess" small v-if="purchasePermission" to="/used-purchases/create-purchases-invoice"
                color="#fff" depressed class="shadow mx-2" exact-active-class="primary">
                <v-icon size="13">mdi-plus</v-icon> {{ $t('createaPurchaseInvoice') }}
              </v-btn>

            </v-col>
            <v-col cols="auto" class="pa-0 mb-2">
              <v-btn :min-width="150" :large="!dashboardAccess" small v-if="noticeDebtor" to="/notice/create-notice-debtor" color="#fff" depressed
                class="shadow mx-2" exact-active-class="primary">
                <v-icon size="13">mdi-plus</v-icon> {{ $t('create notice debtor') }}
              </v-btn>

            </v-col>
            <v-col cols="auto" class="pa-0 mb-2">
              <v-btn :min-width="150" :large="!dashboardAccess" small v-if="noticeCreditor" to="/notice/create-notice-creditor" color="#fff"
                depressed class="shadow mx-2" exact-active-class="primary">
                <v-icon size="13">mdi-plus</v-icon> {{ $t("create notice creditor") }}
              </v-btn>

            </v-col>
            <v-col cols="auto" class="pa-0 mb-2">
              <v-btn :min-width="150" :large="!dashboardAccess" small v-if="returnPermission"
                :to="isQty ? '/return-sales/create-return-sales' : '/return-sales/create-return-sales-piece'"
                color="#fff" depressed class="shadow mx-2" exact-active-class="primary">
                <v-icon size="13">mdi-plus</v-icon> {{ $t("create ruturn sales") }}
              </v-btn>
            </v-col>
            <v-col cols="auto" class="pa-0 mb-2">
              <v-btn :min-width="150" :large="!dashboardAccess" small v-if="expenses" :to="'/expenses/create-expenses'" color="#fff" depressed
                class="shadow mx-2" exact-active-class="primary">
                <v-icon size="13">mdi-plus</v-icon> {{ $t("create expenses invoice") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <template v-if="dashboardAccess">
        <StatisticsBar :summary="summary"></StatisticsBar>
        <v-container fluid class="pa-0">
          <v-row class="d-md-flex d-block" justify="center">
            <v-col col="12" lg="5" md="6" sm="12" class="py-0">
              <RecipeChart :summary="summary"></RecipeChart>
            </v-col>
            <v-col col="12" lg="5" md="6" sm="12" class="py-0">
              <MoneyChart :summary="summary"></MoneyChart>
            </v-col>
          </v-row>
        </v-container>
        <v-container fluid class="pa-0">
          <v-col col="12" lg="10" md="12" xs="12" class="d-block ma-auto px-0">
            <v-tabs active-class="primary white--text" color="primary" v-model="tab">
              <v-tabs-slider></v-tabs-slider>

              <v-tab v-for="item in items" :key="item" class="font-weight-bold" @click="activeTab = item">{{
                $t(item)
                }}</v-tab>
            </v-tabs>

            <SimpleTable :summary="summary" :tableTitle="activeTab"></SimpleTable>
          </v-col>
        </v-container>
      </template>
    </div>
  </section>
</template>

<script>
import GoldPrice from "../components/GoldPrice";
import StatisticsBar from "../components/StatisticsBar";
import MoneyChart from "../components/MoneyChart";
import RecipeChart from "../components/RecipeChart";
import SimpleTable from "../components/SimpleTable";
// import SkeletonLoader from "../components/SkeletonLoader";
export default {
  name: "Home",
  computed: {
    dashboardAccess() {
      return this.$store.state.userData?.dashboard || 0;
    },
    salesPermission() {
      return this.$global.CheckAction("06-001", 1) || this.$global.CheckAction("06-008", 1)
    },
    purchasePermission() {
      return this.$global.CheckAction("04-008", 1)
    },
    returnPermission() {
      return this.$global.CheckAction("06-500", 1) || this.$global.CheckAction("06-500", 1)
    },
    noticeDebtor() {
      return this.$global.CheckAction("06-003", 1)
    },
    noticeCreditor() {
      return this.$global.CheckAction("06-002", 1)
    },
    expenses() {
      return this.$global.CheckAction("06-101", 1)
    }
  },
  components: {
    GoldPrice,
    StatisticsBar,
    MoneyChart,
    RecipeChart,
    SimpleTable,
    // SkeletonLoader,
  },

  data() {
    return {
      key: 0,
      isLoading: true,
      gold_price_direction: {
        "18_price": "=",
        "21_price": "=",
        "22_price": "=",
        "24_price": "=",
        "ounce_price": "=",
        "ounce_price_sar": "="
      },
      gold_price: {
        "add_date": "0000-00-00 00:00:00",
        "ounce_price": 0.00,
        "24_price": 0.00,
        "22_price": 0.00,
        "21_price": 0.00,
        "18_price": 0.00,
        "price_24": 0.00,
        "price_22": 0.00,
        "price_21": 0.00,
        "price_18": 0.00,
        "ounce": 0.00
      },
      summary: {
        "countUsedInvoices": 0.00,
        "countSalesInvoices": 0.00,
        "countReturnInvoices": 0.00,
        "countTransCash": 0.00,
        "used": {
          "caliber18Weight": 0.00,
          "caliber21Weight": 0.00,
          "caliber22Weight": 0.00,
          "caliber24Weight": 0.00,
          "totalWeight": 0.00,
          "caliber18Price": 0.00,
          "caliber21Price": 0.00,
          "caliber22Price": 0.00,
          "caliber24Price": 0.00,
          "totalPrice": 0.00
        },
        "sales": {
          "caliber18Weight": 0.00,
          "caliber21Weight": 0.00,
          "caliber22Weight": 0.00,
          "caliber24Weight": 0.00,
          "totalWeight": 0.00,
          "caliber18Price": 0.00,
          "caliber21Price": 0.00,
          "caliber22Price": 0.00,
          "caliber24Price": 0.00,
          "totalPrice": 0.00
        },
        "return": {
          "caliber18Weight": 0.00,
          "caliber21Weight": 0.00,
          "caliber22Weight": 0.00,
          "caliber24Weight": 0.00,
          "totalWeight": 0.00
        },
        "final": {
          "totalSalesCash": 0.00,
          "totalSalesMachine": 0.00,
          "totalUsedCash": 0.00,
          "totalRetuenCash": 0.00,
          "totalDeposits": 0.00,
          "totalExpense": 0.00,
          "finalCash": 0.00
        }
      },
      gold_price_purchase: {
        gold_price_purchase_18: 0,
        gold_price_purchase_21: 0,
        gold_price_purchase_22: 0,
        gold_price_purchase_24: 0,
      },
      gold_price_sales: {
        gold_price_sales_18: 0,
        gold_price_sales_21: 0,
        gold_price_sales_22: 0,
        gold_price_sales_24: 0,
      },
      tab: null,
      activeTab: "salesInvoices",
      items: [],
    };
  },
  mounted() {
    document.title = this.$i18n.t("Qarat - Home");
    if (this.$store.state.isAuth && this.dashboardAccess) {
      this.$api.GET_METHOD("get_home_data").then((response) => {
        this.gold_price = response.data.gold_price || this.gold_price;
        this.gold_price_direction = response.data.gold_price_direction || this.gold_price_direction;
        this.gold_price_purchase = response.data.gold_price_purchase || this.gold_price_purchase;
        this.gold_price_sales = response.data.gold_price_sales || this.gold_price_sales;
        this.summary = response.data.summary;
        this.isLoading = false;
        this.items = [
          'salesInvoices', 'usedInvoices'
        ];
        this.key = this.key + 1
      })
    }

  },
};
</script>
