<template>
  <v-dialog v-model="dialog" persistent :max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <button v-bind="attrs" v-on="on"  v-ripple class="itemBtn">
        <p class="ma-0 py-1">
          <small>{{ $vuetify.rtl == true ? goldType.name_ar : goldType.name_en }}</small>
        </p>
      </button>
    </template>
    <v-card relative>
      <v-btn absolute class="mt-2" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
        color="grey lighten-1" text @click="dialog = false">
        <v-icon color="grey lighten-1">mdi-close</v-icon>
      </v-btn>
      <!-- Title -->
      <v-card-title>
        <v-row class="justify-space-between py-0" align="center">
          <v-col cols="auto">
            <v-row>
              <v-col cols="auto">
                <v-icon small color="blue accent-4" left>mdi-check-circle</v-icon>
              </v-col>
              <v-col cols="auto">
                <h6 class="text--disabled body-2">{{ $t('silver') }}</h6>
                <h6 class="my-0 body-2">
                  <strong>{{ goldType.name_ar }}</strong>
                </h6>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider class="my-1"></v-divider>
      <!-- / Title -->

      <!-- weight -->
      <div class="pa-5" v-if="activeStep == 1">
        <v-row align="center" justify="center">
          <v-col cols="10" class="ma-auto">
            <h5 class="mb-5">{{ $t('silver weight') }}</h5>
            <NumberPad :backValueMethod="setWeight" :value="silverWeight"></NumberPad>
          </v-col>
          <v-col cols="10" class="pa-0 d-flex justify-end">
            <v-btn color="success" large :disabled="!weightValid" @click="() => activeStep = 2"
              class="shadow mb-3 mx-2">{{ $t('next') }}</v-btn>
          </v-col>
        </v-row>
      </div>
      <!-- / weight -->
      <!-- Price -->
      <div class="pa-5" v-if="activeStep == 2">
        <v-row align="center" justify="center">
          <v-col cols="10" class="ma-auto">
            <h5 class="mb-5">{{ $t('Total including tax') }}</h5>
            <NumberPad :backValueMethod="setPrice" :value="price"></NumberPad>
          </v-col>
          <v-col cols="10" class="pa-0 d-flex justify-end">
            <v-btn color="error" large outlined @click="() => activeStep = activeStep - 1" class="shadow mb-3 mx-2">{{
                $t('back')
            }}</v-btn>
            <v-btn color="success" large :disabled="!priceValid" @click="() => setSummary()" class="shadow mb-3 mx-2">{{
                $t('add')
            }}</v-btn>
          </v-col>
        </v-row>
      </div>
      <!-- / Price -->
    </v-card>
    <InvoiceSummery :backValueMethod="setData" :dialog="summaryDialog" :summery="summary" :loading="loading">
    </InvoiceSummery>
  </v-dialog>
</template>


<script>
import NumberPad from "../NumberPad.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("InvoiceStore");
import InvoiceSummery from "./InvoiceSummery.vue";
export default {
  name: "SilverModal",
  props: {
    goldType: { type: {} },
    caliber: { default: [] },
    vat: { default: {} },
    vats: { default: {} },
    gold_price: { default: {} },
    silvers: { default: [] },
    stock_watch: { default: null },
  },
  computed: {
    ...mapState(["bill", "customerType"]),

    priceValid() {
      if (Number(this.price) > 0) {
        return true
      }
      else {
        return false
      }
    },
    weightValid() {
      if (Number(this.silverWeight) > 0) {
        return true
      }
      else {
        return false
      }
    },
  },
  watch: {
    price() {
      let goldPrice = this.vats[925];
      this.totalPriceWithoutVat = Number(this.price) / (1 + Number(goldPrice));
      this.totalVat = Number(this.price) - (Number(this.price) / (1 + Number(goldPrice)));
      this.minPrice = 0
      console.log("------------------------------------------------------------------------------------");

      console.log("totalPriceWithoutVat", this.totalPriceWithoutVat);
      console.log("totalVat", this.totalVat);
      console.log("goldPrice", goldPrice);
      console.log("minPrice", this.minPrice);
    }
  },
  components: {
    NumberPad,
    InvoiceSummery
  },
  data() {
    return {
      summaryDialog: false,
      totalVat: 0,
      minPrice: 0,
      dialog: false,
      checkPricePayed: false,
      totalPriceWithoutVat: 0,
      totalPriceWithVat: 0,
      price: "",
      silverWeight: "",
      silver: null,
      setActive: false,
      activeStep: 1,
    };
  },
  mounted() {
    window.addEventListener("keydown", e => {
      if (e.key == 'Enter' && this.dialog == true) {
        if (this.weightValid == true && this.activeStep == 1) {
          this.activeStep = 2
        }
        else if (this.priceValid == true && this.activeStep == 2) {
          this.setSummary()
        }
      }
    });
  },
  methods: {
    ...mapActions(["addBillAction"]),
    setPrice(returnedPrice) {
      this.price = returnedPrice
    },
    setWeight(returnedWeight) {
      this.silverWeight = returnedWeight
    },
    setSummary() {
      this.summary = {
        d_id: 0,
        categoryId: this.goldType.category_id,
        subCategoryId: this.goldType.sub_category_id,
        itemTitle: this.goldType.sub_category_title,
        minPrice: this.minPrice,
        gold_weight: 0,
        priceWithoutVat: this.totalPriceWithoutVat,
        vat: this.totalVat,
        price: Number(this.price),
        countPieces: 1,
        silver_weight: Number(this.silverWeight),
      }
      this.setData(true)
    },

    setData(status) {

      if (status == true) {
        this.summaryDialog = false
        this.addBillAction(this.summary).finally(() => {
          this.price = '';
          this.silver_weight = '';
          this.silver = null;
          this.summary = {};
          this.activeStep = 1;
          return (this.dialog = false);
        });

      } else {
        this.summaryDialog = false
      }
    },
    openSummary() {

    },
  },

}

</script>
