<template>
  <v-dialog v-model="dialog" persistent :max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <button v-bind="attrs"  v-if="!stock_watch" v-on="on" v-ripple class="itemBtn">
        {{ $vuetify.rtl == true ? goldType.name_ar : goldType.name_en }}
      </button>
      <button v-bind="attrs" v-on="on" depressed :disabled="goldType.stock <= 0"
        :class="goldType.stock <= 0 ? 'light' : 'gradients'" class="rounded-md subtitle-1" style="width: 100%"
        elevation="0" :height="50.06" max-height="100" v-if="stock_watch">
        <p class="ma-0" v-if="goldType.stock > 0">
          <small>{{ $vuetify.rtl == true ? goldType.name_ar : goldType.name_en }}</small>
        </p>
        <span v-if="goldType.stock <= 0">
          <small>
            <p class="ma-0 error--text">{{ $t('notAvail') }}</p>
            <p class="ma-0" style="color: #888">{{ $vuetify.rtl == true ? goldType.name_ar : goldType.name_en }}
            </p>
          </small>
        </span>
      </button>

    </template>
    <v-card relative>
      <v-btn absolute class="mt-2" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
        color="grey lighten-1" text @click="dialog = false">
        <v-icon color="grey lighten-1">mdi-close</v-icon>
      </v-btn>
      <!-- Title -->
      <v-card-title>
        <v-row class="justify-space-between py-0" align="center">
          <v-col cols="auto">
            <v-row>
              <v-col cols="auto">
                <v-icon small color="blue accent-4" left>mdi-check-circle</v-icon>
              </v-col>
              <v-col cols="auto">
                <h6 class="gray6--text body-2">{{ $t('Diamond') }}</h6>
                <h6 class="my-0 body-2">
                  <strong>{{ goldType.name_ar }}</strong>
                </h6>
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col cols="6" class="mx-4">
            <v-row>
              <v-col cols="12" class="py-0" v-if="activeStep != 1">
                <div class="d-flex justify-space-between align-center light pa-2 rounded-lg shadow">
                  <div>
                    <h6 class="mx-2 body-2 font-weight-bold">
                      {{ $t('caliber') }} :
                      <span
                        class="gray6--text font-weight-regular"
                      >{{ goldCaliber }}</span>
                    </h6>
                  </div>
                  <v-btn small color="error" @click="() => activeStep = 1">
                    {{ $t('modify') }}
                    <img src="../../assets/img/svg/editIcon.svg" alt="qarat" />
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="12" class="py-0 my-1" v-if="activeStep == 3">
                <div class="d-flex justify-space-between align-center light pa-2 rounded-lg shadow">
                  <div>
                    <h6 class="mx-2 my-0 body-2 font-weight-bold">{{ $t('diamond') }} :</h6>
                    <p
                      class="gray6--text my-0 caption"
                    >{{ $global.DecimalFormatter(Number(weight).toFixed(2)) }} {{ $t('gram') }}</p>
                  </div>
                  <v-btn small color="error" @click="() => activeStep = 2">
                    {{ $t('modify') }}
                    <img src="../../assets/img/svg/editIcon.svg" alt="qarat" />
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-col>-->
        </v-row>
      </v-card-title>
      <v-divider class="my-1"></v-divider>
      <!-- / Title -->

      <!-- Additions -->
      <div class="pa-5 pt-0" v-if="activeStep == 1">
        <v-row align="center" justify="end">
          <v-col cols="6">
            <label class="mx-2 body-2">{{ $t('clarity') }}</label>
            <v-select :items="clarities" dense v-model="clarity" background-color="#E8E8EE" hide-details
              class="my-2 rounded grey lighten-5 box-shadow-none" :item-text="'clarity_code'" :item-value="'clarity_id'"
              solo></v-select>
          </v-col>
          <v-col cols="6" class="py-0">
            <label class="mx-2 body-2">{{ $t('quality') }}</label>
            <v-select :items="qualities" dense v-model="quality" background-color="#E8E8EE" hide-details
              class="my-2 rounded grey lighten-5 box-shadow-none" :item-text="'title'" :item-value="'id'" solo>
            </v-select>
          </v-col>
          <v-col cols="6" class="py-0">
            <label class="mx-2 body-2">{{ $t('color') }}</label>
            <v-select :items="colors" dense v-model="color" background-color="#E8E8EE" hide-details
              class="my-2 rounded grey lighten-5 box-shadow-none" :item-text="'color_code'" :item-value="'color_id'"
              solo></v-select>
          </v-col>
          <v-col cols="6" class="py-0">
            <label class="mx-2 body-2">{{ $t('shape') }}</label>
            <v-select :items="shapes" dense v-model="shape" background-color="#E8E8EE" hide-details
              class="my-2 rounded grey lighten-5 box-shadow-none" :item-text="'title'" :item-value="'id'" solo>
            </v-select>
          </v-col>
          <v-col cols="6" class="py-0">
            <label class="mx-2 body-2">{{ $t('stone type') }}</label>
            <v-text-field  dense v-model="stone_type" background-color="#E8E8EE" hide-details
              class="my-2 rounded grey lighten-5 box-shadow-none" solo>
            </v-text-field>
          </v-col>
          <v-col cols="6" class="py-0">
            <label class="mx-2 body-2">{{ $t('stone weight') }}</label>
            <GenericInput type="float" :outlined="false" :solo="true" :value="stone_weight" background="#E8E8EE" @input="stone_weight = $event" label=""
            :required="true" paddingX="px-0"  classes="rounded grey lighten-5 box-shadow-none" :isLoading="false" :cols="[12, 12, 12]" />
          </v-col>

<!--  <GenericInput type="text" :value="stone_type" background="#E8E8EE" @input="stone_type = $event" label="stone type"
          :required="true"  classes="my-2 rounded grey lighten-5 box-shadow-none" :isLoading="false" :cols="[12, 6, 6]" /> -->





          <v-col cols="12" class="py-0">
            <label class="mx-2 body-2">{{ $t('notes') }}</label>
            <v-textarea background-color="#e8e8ee" solo :label="''" auto-grow hide-details rows="3" clearable
              clear-icon="mdi-close-circle" v-model="notes"></v-textarea>
          </v-col>
          <!-- <v-col cols="6" class="py-0">
            <label class="mx-2 body-2">{{ $t('lobes') }}</label>
            <v-text-field suffix="%" min="1" max="100" v-model="lobes" :rules="percentageRule" background-color="#E8E8EE"
              class="my-2 rounded grey lighten-5 box-shadow-none" dense solo  type="number"></v-text-field>
          </v-col> -->
          <v-col cols="6"></v-col>
          <v-col cols="12" class>
            <v-btn color="success" large block  @click="() => activeStep = 2"
              class="shadow d-block mi-start-auto mb-3">{{ $t('next') }}</v-btn>
          </v-col>
        </v-row>
      </div>
      <!-- / Additions -->

      <!--  Diamond Weight -->
      <div class="pa-5" v-if="activeStep == 2">
        <v-row align="center" justify="center">
          <v-col cols="10" class="ma-auto">
            <h5 class="mb-5">
              {{ $t('please enter diamond weight') }}
              <small class="gray6--text">({{ $t("weight by caliber") }})</small>
            </h5>
            <NumberPad :backValueMethod="setDiamondWeight" :value="diamondWeight"></NumberPad>
          </v-col>

          <v-col cols="10" class="py-0 d-flex justify-end">
            <v-btn color="error" large outlined @click="() => activeStep = activeStep - 1" class="shadow mb-3 mx-2">{{
                $t('back')
            }}</v-btn>
            <v-btn color="success" large :disabled="!diamondWeightValid" @click="() => activeStep = 3"
              class="shadow mb-3">{{ $t('next') }}</v-btn>
          </v-col>
        </v-row>
      </div>
      <!--  /  Diamond Weight -->

      <!-- Diamond Count  -->
      <div class="pa-5" v-if="activeStep == 3">
        <v-row align="center" justify="center">
          <v-col cols="10" class="ma-auto">
            <h5 class="mb-5">{{ $t('countPices') }}</h5>
            <NumberPad :backValueMethod="setDiamondCount" :value="countDiamond"></NumberPad>
          </v-col>
          <v-col cols="10" class="py-0 d-flex justify-end">
            <v-btn color="error" large outlined @click="() => activeStep = activeStep - 1" class="shadow mb-3 mx-2">{{
                $t('back')
            }}</v-btn>
            <v-btn color="success" large :disabled="!diamondCountValid" @click="() => activeStep = 4"
              class="shadow mb-3">{{ $t('next') }}</v-btn>
          </v-col>
        </v-row>
      </div>
      <!--  / Diamond Count -->

      <!-- Caliber -->
      <div class="pa-5 pt-0" v-if="activeStep == 4">
        <v-row align="center" justify="center">
          <v-col cols="12">
            <h5 class="mb-1">{{ $t('caliber') }}</h5>
            <v-row align="center">
              <v-col cols="6" md="3" class="px-1">
                <div class="light rounded-lg cursor_pointer caption px-1" v-on:click="goldCaliber = 18"
                  :class="goldCaliber != 18 ? 'gray6--text' : 'font-weight-bold'">
                  <div class="d-flex align-center">
                    <img src="../../assets/img/svg/dollar.svg" height="13px" class="mx-1" alt="Qarat-POS" />
                    <span class="gray6--text">{{ gold_price['price_18'].toFixed(2) }} {{ $t('ryal') }}</span>
                  </div>
                  <v-icon color="#0A6ADA" size="14" :disabled="goldCaliber != 18">mdi-check-circle</v-icon>
                  {{ $t('gold') }} {{ $t('caliber') }} 18
                </div>
              </v-col>
              <v-col cols="6" md="3" class="px-1">
                <div class="light rounded-lg cursor_pointer caption px-1" v-on:click="goldCaliber = 21"
                  :class="goldCaliber != 21 ? 'gray6--text' : 'font-weight-bold'">
                  <div class="d-flex align-center">
                    <img src="../../assets/img/svg/dollar.svg" height="13px" class="mx-1" alt="Qarat-POS" />
                    <span class="gray6--text">{{ gold_price['price_21'].toFixed(2) }} {{ $t('ryal') }}</span>
                  </div>
                  <v-icon color="#0A6ADA" size="14" :disabled="goldCaliber != 21">mdi-check-circle</v-icon>
                  {{ $t('gold') }} {{ $t('caliber') }} 21
                </div>
              </v-col>
              <v-col cols="6" md="3" class="px-1">
                <div class="light rounded-lg cursor_pointer caption px-1" v-on:click="goldCaliber = 22"
                  :class="goldCaliber != 22 ? 'gray6--text' : 'font-weight-bold'">
                  <div class="d-flex align-center">
                    <img src="../../assets/img/svg/dollar.svg" height="13px" class="mx-1" alt="Qarat-POS" />
                    <span class="gray6--text">{{ gold_price['price_22'].toFixed(2) }} {{ $t('ryal') }}</span>
                  </div>
                  <v-icon color="#0A6ADA" size="14" :disabled="goldCaliber != 22">mdi-check-circle</v-icon>
                  {{ $t('gold') }} {{ $t('caliber') }} 22
                </div>
              </v-col>
              <v-col cols="6" md="3" class="px-1">
                <div class="light rounded-lg cursor_pointer caption px-1" v-on:click="goldCaliber = 24"
                  :class="goldCaliber != 24 ? 'gray6--text' : 'font-weight-bold'">
                  <div class="d-flex align-center">
                    <img src="../../assets/img/svg/dollar.svg" height="13px" class="mx-1" alt="Qarat-POS" />
                    <span class="gray6--text">{{ gold_price['price_24'].toFixed(2) }} {{ $t('ryal') }}</span>
                  </div>
                  <v-icon color="#0A6ADA" size="14" :disabled="goldCaliber != 24">mdi-check-circle</v-icon>
                  {{ $t('gold') }} {{ $t('caliber') }} 24
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="10" class="py-0">
            <h5 class="mb-5">{{ $t('pleaseEnterWeight') }}</h5>
            <NumberPad :backValueMethod="setWeight" :value="weight"></NumberPad>
          </v-col>
          <v-col cols="10" class="pa-0 d-flex justify-end">
            <v-btn color="error" large outlined @click="() => activeStep = activeStep - 1" class="shadow mb-3 mx-2">{{
                $t('back')
            }}</v-btn>
            <v-btn color="success" large :disabled="!weightValid" @click="() => activeStep = 5"
              class="shadow mb-3 mx-2">{{ $t('next') }}</v-btn>
          </v-col>
        </v-row>
      </div>
      <!-- / Caliber -->

      <!-- Price -->
      <div class="pa-5" v-if="activeStep == 5">
        <v-row align="center" justify="center">
          <v-col cols="10" class="ma-auto">
            <h5 class="mb-5">{{ $t('Total including tax') }}</h5>
            <NumberPad :backValueMethod="setPrice" :value="price"></NumberPad>
          </v-col>
          <v-col cols="10" class="pa-0 d-flex justify-end">
            <v-btn color="error" large outlined @click="() => activeStep = activeStep - 1" class="shadow mb-3 mx-2">{{
                $t('back')
            }}</v-btn>
            <v-btn color="success" large :disabled="!priceValid" @click="() => setSummary()" class="shadow mb-3 mx-2">{{
                $t('add')
            }}</v-btn>
          </v-col>
        </v-row>
      </div>
      <!-- / Price -->
    </v-card>
    <InvoiceSummery :backValueMethod="setData" :dialog="summaryDialog" :summery="summary" :loading="loading" :sales_note="sales_note" />
  </v-dialog>
</template>


<script>
import NumberPad from "../NumberPad.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("InvoiceStore");
import InvoiceSummery from "./InvoiceSummery.vue";
import GenericInput from "../GenericInput.vue";
export default {
  name: "DiamondModal",
  props: {
    goldType: { type: {} },
    caliber: { default: [] },
    vat: { default: {} },
    vats: { default: {} },
    gold_price: { default: {} },
    clarities: { default: [] },
    qualities: { default: [] },
    shapes: { default: [] },
    colors: { default: [] },
    stock_watch: { default: null },
    sales_note: { default: 0 },
  },
  computed: {
    ...mapState(["bill", "customerType"]),
    settingsValid() {
      if (this.clarity && this.color && this.quality && this.shape) {
        return true
      }
      else {
        return false
      }
    },
    diamondCountValid() {
      if (Number(this.countDiamond) > 0) {
        return true
      }
      else {
        return false
      }
    },
    diamondWeightValid() {

      switch (this.stock_watch) {
        case 1:
          if (Number(this.diamondWeight) > 0 && Number(this.goldType.stock) >= Number(this.diamondWeight)) {
            return true
          }
          else {
            return false
          }
        default:
          if(Number(this.diamondWeight) > 0){
            return true
          }
          else{
            return false;
          }
      }
    },
    weightValid() {
      if (Number(this.weight) > 0) {
        return true
      }
      else {
        return false
      }
    },
    priceValid() {
      if (Number(this.price) > 0) {
        return true
      }
      else {
        return false
      }
    },

  },
  watch: {
    price() {
      let goldPrice = this.vats[25];
      this.totalPriceWithoutVat = Number(this.price) / (1 + Number(goldPrice));
      this.totalVat = Number(this.price) - (Number(this.price) / (1 + Number(goldPrice)));
      this.minPrice = 0;

      console.log("------------------------------------------------------------------------------------");
      console.log("totalPriceWithoutVat", this.totalPriceWithoutVat);
      console.log("totalVat", this.totalVat);
      console.log("goldPrice", goldPrice);
      console.log("minPrice", this.minPrice);
    }
  },
  components: {
    NumberPad,
    InvoiceSummery,
    GenericInput
},
  data() {
    return {
      summaryDialog: false,
      summary: Object,
      loading: false,
      totalVat: 0,
      minPrice: 0,
      dialog: false,
      goldCaliber: 18,
      checkPricePayed: false,
      totalPriceWithoutVat: 0,
      totalPriceWithVat: 0,
      weight: "",
      diamondWeight: "",
      countDiamond: "",
      price: "",
      clarity: 0,
      quality: 0,
      lobes: null,
      notes: null,
      percentageRule: null,
      shape: 0,
      stone_weight: 0,
      stone_type: null,
      setActive: false,
      activeStep: 1,
    };
  },
  mounted() {
    this.percentageRule = [v => (!isNaN(parseFloat(v)) && v >= 1 && v <= 100) || this.$i18n.t('percentage has to be between 1% and 100%')]
    window.addEventListener("keydown", e => {
      if (e.key == 'Enter' && this.dialog == true) {
        if (this.settingsValid == true && this.activeStep == 1) {
          this.activeStep = 2
        }
        else if (this.diamondWeightValid == true && this.activeStep == 2) {
          this.activeStep = 3
        }
        else if (this.diamondCountValid == true && this.activeStep == 3) {
          this.activeStep = 4
        }
        else if (this.weightValid == true && this.activeStep == 4) {
          this.activeStep = 5
        }
        if (this.priceValid == true && this.activeStep == 5) {
          // this.setSummary()
          this.setData(true);
        }
      }
    });
  },
  methods: {
    ...mapActions(["addBillAction"]),
    setWeight(returnedWeight) {
      this.weight = returnedWeight;
    },
    setDiamondWeight(returnedWeight) {
      this.diamondWeight = returnedWeight;
    },
    setPrice(returnedPrice) {
      this.price = returnedPrice
    },
    setDiamondCount(returnedCount) {
      this.countDiamond = returnedCount
    },
    setSummary() {
      this.summary = {
        d_id: 0,
        categoryId: this.goldType.category_id,
        subCategoryId: this.goldType.sub_category_id,
        itemTitle: this.goldType.sub_category_title,
        minPrice: this.minPrice,
        gold_weight: Number(this.weight),
        priceWithoutVat: this.totalPriceWithoutVat,
        vat: this.totalVat,
        price: Number(this.price),
        countPieces: 1,
        count_diamonds: Number(this.countDiamond),
        quality_id: this.quality,
        clarity_id: this.clarity,
        color_id: this.color,
        shap_id: this.shape,
        lobes: this.lobes || 0,
        notes: this.notes,
        stone_weight: this.stone_weight,
        stone_type: this.stone_type,
        diamond_weight: Number(this.diamondWeight),
        caliber_id: this.goldCaliber
      }
      this.summaryDialog = true
    },

    setData(status) {

      if (status == true) {
        this.summaryDialog = false
        this.addBillAction(this.summary).finally(() => {
          this.weight = '';
          this.price = '';
          this.diamondWeight = '';
          this.countDiamond = '';
          this.clarity = null;
          this.quality = null;
          this.shape = null;
          this.color = null;
          this.lobes = null;
          this.notes = null;
          this.stone_type = null;
          this.stone_weight = 0;
          this.summary = {};
          this.activeStep = 1;
          return (this.dialog = false);
        });

      } else {
        this.summaryDialog = false
      }
    },
    openSummary() {

    },
  },

}

</script>
