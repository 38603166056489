<template>
  <v-card class="shadow rounded-lg recipeChart my-3 py-5">
    <v-row justify="center">
      <v-col cols="12" class="py-0" id="chart">
        <apexchart
          key="key"
          height="255"
          id="apexRecipeChart"
          type="bar"
          :options="options"
          :series="series"
        ></apexchart>
      </v-col>
      <v-col cols="12">
        <v-row justify="center">
          <v-col cols="auto">
            <v-row>
              <v-col cols="auto">
                <div class="d-block pa-2 lightBlue  rounded-pill"></div>
              </v-col>
              <v-col cols="auto">
                <h5 class="text--disabled">{{ $t('sales') }}</h5>
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col cols="auto">
            <v-row>
              <v-col cols="auto">
                <div class="d-block pa-2 grey darken-4 rounded-pill"></div>
              </v-col>
              <v-col cols="auto">
                <h5 class="text--disabled">{{ $t('return sales') }}</h5>
              </v-col>
            </v-row>
          </v-col> -->
          <v-col cols="auto">
            <v-row>
              <v-col cols="auto">
                <div class="d-block pa-2 blue rounded-pill"></div>
              </v-col>
              <v-col cols="auto">
                <h5 class="text--disabled">{{ $t('purchase') }}</h5>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>







<script>
export default {
  name: "RecipeChart",
  computed: {
  },
  props: ["summary"],
  data: () => ({
    key: 1,
    width: 0,
    options: {
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: 40,
          endingShape: 'rounded',
          borderRadius: 9,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      chart: {

        toolbar: {
          show: false,
        },

      },
      stroke: {
        show: true,
        width: 4,
        colors: ['transparent']
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val > 0? val : '';
        },
        offsetY: -18,
        style: {
          fontSize: '10px',
          colors: ["#8D8D8D"],
        }
      },
      // dataLabels: {
      //   enabled: true,
      //   style: {
      //     fontSize: '10px',
      //     colors: ["#fff"]
      //   }
      // },
      colors: ["#A3A1FB", "#262525", "#002FC8"],
      xaxis: {
        categories: ["عيار 18", "عيار 21", "عيار 22", "عيار 24"],
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
      },
      yaxis: {
        axisBorder: {
          show: false,

        },
        axisTicks: {
          show: false,

        },
        labels: {
          show: true,
          style: {
            fontSize: '10px',
            colors: ["#000"],
          }
        }
      },
    },

    series: [
      {
        name: "فاتورة مبيعات",
        data: [0, 0, 0],
      },
      // {
      //   name: "مرتد",
      //   data: [0, 0, 0],
      // },
      {
        name: "فاتورة مشتريات",
        data: [0, 0, 0],
      },
    ],
  }),
  mounted() {

    this.width =
      document.getElementById("chart").offsetWidth < 490
        ? document.getElementById("chart").offsetWidth - 50
        : document.getElementById("chart").offsetWidth - 210;
    if (this.width > 0) {
      this.options.xaxis.categories = [
        this.$i18n.t('caliber 18'), this.$i18n.t('caliber 21'), this.$i18n.t('caliber 22'), this.$i18n.t('caliber 24')
      ]
      this.series = [
        {
          name: this.$i18n.t('salesInvoice'),
          data: [this.summary.sales.caliber18Weight.toFixed(2), this.summary.sales.caliber21Weight.toFixed(2), this.summary.sales.caliber22Weight.toFixed(2), this.summary.sales.caliber24Weight.toFixed(2)],
        },
        // {
        //   name: this.$i18n.t('return sales'),
        //   data: [this.summary.return.caliber18Weight.toFixed(2), this.summary.return.caliber21Weight.toFixed(2), this.summary.return.caliber22Weight.toFixed(2), this.summary.return.caliber24Weight.toFixed(2)],
        // },
        {
          name: this.$i18n.t('purchaseInvoice'),
          data: [this.summary.used.caliber18Weight.toFixed(2), this.summary.used.caliber21Weight.toFixed(2), this.summary.used.caliber22Weight.toFixed(2), this.summary.used.caliber24Weight.toFixed(2)],
        }
      ];
      this.key = this.key + 1
    }


  }

};
</script>
