<template>
   <v-container
      fluid
      class="d-flex justify-center align-center backgroundW"
      style="height: 100vh;position: fixed; top: 0;left:0; width:100vw ; z-index: 1000;"
   >
      <div>
         <img src="@/assets/logo.svg" alt="qarat erp" height="200px" />
         <v-progress-circular
            :size="40"
            :width="5"
            style="margin: auto; "
            class="d-block"
            color="primary"
            indeterminate
         ></v-progress-circular>
      </div>
   </v-container>
</template>
<style  scoped>
</style>


<script>

export default {
   name: "SplashLoader",
   computed: {

   },
   components: {},
   data: () => ({

   }),
   mounted() {

   },

   methods: {

   },
};
</script>
