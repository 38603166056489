<template>
  <v-dialog v-model="dialog" persistent :max-width="500" @click:outside="setFocus">
    <v-card relative :key="dialogKey" @click="setFocus">
      <v-btn absolute class="mt-2" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
        color="grey lighten-1" text @click="closeDialog">
        <v-icon color="grey lighten-1">mdi-close</v-icon>
      </v-btn>
      <!-- Title -->
      <v-card-title>
        <v-row class="justify-space-between py-0" align="center">
          <v-col cols="auto">
            <v-row>
              <v-col cols="auto">
                <v-icon small color="blue accent-4" left>mdi-check-circle</v-icon>
              </v-col>
              <v-col cols="auto">
                <h6 class="text--disabled body-2">{{ $t('caliber') }} {{ detail.category_id }}</h6>
                <h6 class="my-0 body-2">
                  <strong>{{ detail.sub_category_title }}</strong>
                </h6>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="auto" class="pe-5">
            <v-row>
              <v-col cols="auto">
                <v-icon small color="blue accent-4" left>mdi-check-circle</v-icon>
              </v-col>
              <v-col cols="auto">
                <h6 class="text--disabled body-2">{{ $t('purchase price') }}</h6>
                <h6 class="my-0 body-2">
                  <strong>
                    {{ gold_price[`${detail.category_id}_price`] | float }} {{ $t('ryal') }}
                  </strong>
                </h6>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider class="my-1"></v-divider>
      <!-- / Title -->
      <!-- Price -->
      <div class="pa-5">
        <v-row align="center" justify="start">
          <v-col cols="6" class="py-1" v-if="activeStep > 1 && !isEdit">
            <div class="d-flex justify-space-between align-center light pa-2 rounded-lg shadow">
              <div>
                <h5>
                  {{ $t('price') }} :
                  <br />
                  <span class="text--disabled">{{ price | float }} {{ $t('ryal') }}</span>
                </h5>
              </div>
              <v-btn small color="error" @click="activeStep = 1">
                {{ $t('modify') }}
                <img src="@/assets/img/svg/editIcon.svg" alt="qarat" />
              </v-btn>
            </div>
          </v-col>
          <v-col cols="6" class="py-1" v-if="activeStep > 2 && !isEdit">
            <div class="d-flex justify-space-between align-center light pa-2 rounded-lg shadow">
              <div>
                <h5>
                  {{ $t('weight') }} :
                  <br />
                  <span class="text--disabled">{{ weight | float }} {{ $t('gram') }}</span>
                </h5>
              </div>
              <v-btn small color="error" @click="activeStep = 2">
                {{ $t('modify') }}
                <img src="@/assets/img/svg/editIcon.svg" alt="qarat" />
              </v-btn>
            </div>
          </v-col>
          <v-col cols="6" class="py-1" v-if="activeStep > 3 && !isEdit">
            <div class="d-flex justify-space-between align-center light pa-2 rounded-lg shadow">
              <div>
                <h5>
                  {{ $t('lobes weight') }} :
                  <br />
                  <span class="text--disabled">{{ other_materials_weight | float }}</span>
                </h5>
              </div>
              <v-btn small color="error" @click="activeStep = 3">
                {{ $t('modify') }}
                <img src="@/assets/img/svg/editIcon.svg" alt="qarat" />
              </v-btn>
            </div>
          </v-col>
          <v-col cols="6" class="py-1" v-if="activeStep > 4 && !isEdit">
            <div class="d-flex justify-space-between align-center light pa-2 rounded-lg shadow">
              <div>
                <h5>
                  {{ $t('description') }} :
                  <br />
                  <span class="text--disabled">{{ description }}</span>
                </h5>
              </div>
              <v-btn small color="error" @click="activeStep = 4">
                {{ $t('modify') }}
                <img src="@/assets/img/svg/editIcon.svg" alt="qarat" />
              </v-btn>
            </div>
          </v-col>



          <v-col cols="10" class="ma-auto" v-if="activeStep == 1">
            <h5 class="mb-5">{{ $t('Price') }} :

              <span dir="ltr" class="ms-2 success--text" v-if="additionalPrice > 0">
                {{ $t('additional price') }}
                ( {{ additionalPrice || 0 | float }} )
              </span>
            </h5>
            <NumberPad :backValueMethod="setPrice" :value="String(price)"></NumberPad>
          </v-col>
          <v-col cols="10" class="ma-auto" v-if="activeStep == 2 && !use_balance">
            <h5 class="mb-5">{{ $t('weight before cracking') }}</h5>
            <NumberPad :backValueMethod="setBeforeWeight" :value="String(weight)"></NumberPad>
          </v-col>
          <v-col cols="10" class="ma-auto position-relative" v-if="activeStep == 2 && use_balance">
            <div class="balance_weight_input">
              <v-text-field :autofocus="true" type="number" @keydown="(e) => setBalanceWeight(e)"
                v-model="balance_weight" />
            </div>
            <div class="position-relative text-center">
              <h5 class="mb-5">{{ $t('please place the piece on the balance') }}</h5>
              <!-- <v-icon class="d-block mb-5 ma-auto" size="100" color="primary">mdi-scale-balance</v-icon> -->
              <img src="@/assets/img/svg/weighing_machine.svg" height="200px" style="opacity : 0.30" alt="qarat">
            </div>
          </v-col>
          <v-col cols="10" class="ma-auto" v-if="activeStep == 3">
            <h5 class="mb-5 d-flex justify-space-between">
              <div>{{ $t('lobes weight') }}</div>
              <div class="ms-2 body-2" v-if="other_materials_weight">
                {{ $t('estimated weight') }} : {{ diff_weight | float }}</div>
            </h5>
            <NumberPad :backValueMethod="setLobesWeight" :value="String(other_materials_weight)"></NumberPad>
          </v-col>
          <v-col cols="10" class="ma-auto" v-if="activeStep == 4">
            <GenericInput rowsNumber="11" type="textarea" :solo="false" :outlined="true" :dense="true"
              classes="rounded-lg" name="description" label="description" :value="description"
              @input="description = $event" :required="false" :isLoading="false" :cols="[12, 12, 12]" />
          </v-col>
          <v-col cols="10" class="ma-auto" v-if="activeStep == 5">
            <h5 class="mb-5">{{ $t('weight after cracking') }} <span v-if="!isEdit" class="caption">
                ({{ $t('you can add it later') }}) </span></h5>
            <NumberPad :backValueMethod="setAfterWeight" :value="String(used_weight)"></NumberPad>
          </v-col>
          <v-col cols="12" class="pa-0 px-11 d-flex justify-end">
            <v-btn color="error" large v-if="activeStep != 1 && !isEdit && !(activeStep == 2 && use_balance)"
              @click="() => activeStep--" text class="mb-3 mx-2">
              {{ $t('prev') }}</v-btn>
            <v-btn color="success" v-if="activeStep != 5 && !(activeStep == 2 && use_balance)" large
              @click="() => setData()" class="shadow mb-3 mx-2">
              {{ $t('next') }}</v-btn>
            <v-btn v-if="activeStep == 5" color="success" :disabled="!(weight && price)" large @click="() => setData()"
              class="shadow mb-3 mx-2">
              {{ $t('save') }}</v-btn>
          </v-col>
        </v-row>
      </div>
      <!-- / Price -->
    </v-card>
  </v-dialog>
</template>


<script>
import GenericInput from "../GenericInput.vue";
import NumberPad from "../NumberPad.vue";
export default {
  name: "UsedItemModal",
  props: {
    isEdit: { default: false },
    editDetail: { default: false },
    dialog: { default: false },
    use_balance: { default: false },
    detail: { default: Object },
    setDetail: { default: Function },
    gold_price: { default: {} },
    closeDialog: { default: Function },
  },
  computed: {
    diff_weight() {
      return +this.weight - +this.other_materials_weight
    },
    additionalPrice() {
      return +this.price - +this.gold_price[`${this.detail.category_id}_price`]
    }
  },
  watch: {
    weight() {
      this.other_materials_weight = 0
    },
    activeStep() {
      if (this.use_balance && this.activeStep == 2) {
        this.weight = 0;
        this.balance_weight = 0;
        this.use_balance_focus = true;
      }
    },
    other_materials_weight() {
      this.other_materials_weight = this.weight <= this.other_materials_weight ? this.weight : this.other_materials_weight
    },
    'detail.sub_category_id'() {
      this.setBeforeWeight(0);
      this.setAfterWeight(0);
      this.setPrice(0);
    },
    dialog() {
      this.dialogKey++
      this.activeStep = 1;
      this.setBeforeWeight(0);
      this.setAfterWeight(0);
      this.setPrice(0);
      this.description = null
      if (this.editDetail) {
        this.weight = this.detail.weight || 0;
        this.used_weight = this.detail.used_weight || 0;
        this.description = this.detail.description || null;
        this.price = this.detail.price || 0;
      }
      if (this.isEdit) {
        this.activeStep = 4;
      }
    }
  },
  components: {
    NumberPad,
    GenericInput
  },
  data: () => ({
    dialogKey: 1,
    weight: 0,
    used_weight: 0,
    balance_weight: 0,
    other_materials_weight: 0,
    price: 0,
    description: null,
    activeStep: 1,
  }),
  mounted() {
  },
  methods: {
    setBeforeWeight(weight) {
      this.weight = +weight
    },
    setAfterWeight(weight) {
      this.used_weight = +weight
    },
    setBalanceWeight(e) {
      console.log('e', e);
      console.log('balance_weight', this.balance_weight);
      if (e.key == 'Enter' && this.balance_weight) {
        this.weight = +this.balance_weight;
        this.activeStep = this.activeStep + 1
      }
      // else {
      //   let numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
      //   if (numbers.some(i => i == e.key)) {
      //     this.balance_weight = Number(String(this.balance_weight) + e.key);
      //   }
      // }
    },
    setPrice(price) {
      this.price = +price
    },
    setLobesWeight(lobes_weight) {
      this.other_materials_weight = +lobes_weight
    },
    setData() {
      if (this.activeStep == 5) {
        let detail = Object;
        detail.d_id = this.detail.d_id || 0;
        detail.category_id = this.detail.category_id;
        detail.sub_category_id = this.detail.sub_category_id;
        detail.sub_category_title = this.detail.sub_category_title;
        detail.weight = +this.weight || 0;
        detail.used_weight = +this.used_weight || 0;
        detail.other_materials_weight = +this.other_materials_weight || 0;
        detail.diff_weight = +this.diff_weight || 0;
        detail.price = +this.price || 0;
        detail.description = this.description;
        if (this.editDetail) {
          detail.index = this.detail.index
        }
        if (this.isEdit) {
          detail.id = this.detail.id
          detail.status = this.detail.status
        }
        if (detail.weight && detail.price) {
          this.setDetail({ ...detail })
          this.dialogKey++
          this.activeStep = 1;
          this.weight = 0;
          this.used_weight = 0;
          this.other_materials_weight = 0;
          this.diff_weight = 0;
          this.price = 0;
        }
      }
      else {
        this.activeStep++
      }
    },
    setFocus() {
      if (this.activeStep == 2 && this.use_balance) {
        this.activeStep = 1;
        setTimeout(() => {
          this.activeStep = 2;
        }, 100);
      }
    },

  },
  destroyed() {
    this.activeStep = 1;
    this.weight = 0;
    this.used_weight = 0;
    this.other_materials_weight = 0;
    this.diff_weight = 0;
    this.price = 0;
  },
}

</script>
