<template>

  <v-autocomplete auto-select-first :solo="solo" outlined :reverse="reverse" :dense="dense" :name="name"
    @keydown.enter="keydownEnter()" @keydown="keydown" :class="classes" v-model="input" :items="lookups ? lookups : []"
    :item-text="selected_label" :item-value="selected_prop" :append-icon="icon ? icon : 'mdi-menu-down'"
    :item-disabled="checkIsItemDisabled" :color="color" :background-color="background"
    :rules="required == true ? $global.requiredRule : $global.notRequiredRule"
    :multiple="multi ? true : false" :no-data-text="$i18n.t('no selects')" :loading="isLoading" :disabled="disabled"
    :chips="multi" :small-chips="multi" :hide-details="hideDetails == true ? 'auto' : false"
    :clearable="clearable || multi" :deletable-chips="multi">
    <template v-slot:label>
      <label v-if="labelInline == false">
        {{ $t(label) }}
        <span class="error--text" v-if="required == true && label">*</span>
      </label>
    </template>
    <template v-slot:append-outer>
      <slot name="append-outer"></slot>
    </template>
    <template v-slot:append>
      <slot name="append"></slot>
    </template>
    <template v-slot:prepend-inner>
      <slot name="prepend-inner"></slot>
    </template>
    <template v-slot:prepend>
      <slot name="prepend"></slot>
    </template>
    <template v-if="has_slot" v-slot:selection="{ item ,index ,select ,selected ,disabled}">
      <slot name="selection" :item="item" :index="index" :select="select" :selected="selected" :disabled="disabled"   />
    </template>
    <template v-if="has_slot" v-slot:item="{ item }">
      <slot name="item" :item="item" />
    </template>
  </v-autocomplete>
</template>


<script>
export default {
  name: "AutoCompleteInput",
  props: {
    name: { default: null },
    label: { default: null },
    type: { default: null },
    value: { default: null },
    lookups: { default: null },
    required: { default: false },
    dense: { default: true },
    lookups_value: { default: null },
    multi: { default: false },
    selected_label: { default: null },
    selected_prop: { default: null },
    onChange: { type: Function },
    isLoading: { default: null },
    disabled: { default: null },
    color: { default: "primary" },
    background: { default: null },
    icon: { default: "" },
    hideDetails: { default: false },
    labelInline: { default: false },
    clearable: { default: false },
    hideImage: { default: false },
    onClickOuter: { type: Function },
    keydownEnter: { type: Function },
    disableItem: { default: null },
    placeholder: { default: null },
    solo: { default: false },
    classes: { default: null },
    dir: { default: null },
    reverse: { default: false },
    has_slot: { default: false },
    tabKey: { default: Function },
  },
  data: () => ({

  }),
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {

  },
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {
    checkIsItemDisabled(item) {
      if (this.disableItem) {
        if (item[this.disableItem.key] == this.disableItem.value && typeof this.disableItem !== "string") {
          return true;
        }
        if (typeof this.disableItem === "string") {
          return item.disable
        }
        return false;
      }
      else {
        return false;
      }

    },
    keydown(e) {
      console.log(e);
      if (e.code == "Tab") {
        this.tabKey(true)
      }
    }
  },
};
</script>



<style scoped lang="scss">

</style>
