<template>
  <section>
    <v-container fluid>
      <v-row justify="space-between">
        <v-col cols="12" sm="auto" class="d-flex justify-center align-center pt-0 pb-2">
          <v-row align="center" justify="center" justify-md="start">
            <v-col cols="12" md="auto">
              <div class="subtitle-2 text-center text--disabled">
                <strong>{{ $t('sales') | capitalize }} . {{ $t('reports') | capitalize }}</strong>
              </div>
            </v-col>
            <v-col cols="auto" class="px-1" v-if="$global.CheckAction('06-014', 1)">
              <v-btn to="/reports/shift-report" color="primary" outlined rounded :min-width="120" style="height: 40px"
                small depressed>{{ $global.FilterPermissions("06-014").sub_title }}</v-btn>
            </v-col>
            <v-col cols="auto" class="px-1" v-if="$global.CheckAction('06-018', 1)">
              <v-btn to="/reports/customer-supplier-statement" outlined rounded :min-width="120" style="height: 40px"
                small color="primary" depressed>{{ $global.FilterPermissions("06-018").sub_title }}</v-btn>
            </v-col>
            <v-col cols="auto" class="px-1" v-if="$global.CheckAction('02-020', 1)">
              <v-btn to="/reports/supplier-statement" color="primary" outlined rounded :min-width="120"
                style="height: 40px" small depressed>{{ $global.FilterPermissions("02-020").sub_title }}</v-btn>
            </v-col>
            <v-col cols="auto" class="px-1" v-if="$global.CheckAction('06-012', 1)">
              <v-btn to="/reports/full_report" active-class="primary" rounded :min-width="120" style="height: 40px"
                small color="transparent" depressed>{{ $global.FilterPermissions("06-012").sub_title
                }}</v-btn>
            </v-col>
            <v-col cols="auto" class="px-1" v-if="$global.CheckAction('06-010', 1)">
              <v-btn to="/reports/sales_notice_report" color="primary" outlined rounded :min-width="120"
                style="height: 40px" small depressed>{{ $global.FilterPermissions("06-010").sub_title }}</v-btn>
            </v-col>
            <v-col cols="auto" class="px-1" v-if="$global.CheckAction('03-004', 1)">
              <v-btn to="/reports/barcode_report" color="primary" outlined rounded :min-width="120" style="height: 40px"
                small depressed>{{ $global.FilterPermissions("03-004").sub_title }}</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" class="py-0">
          <DynamicTable :searchBtn="false" :tooltipTableHeader="tooltipTableHeader" :searchMethod="getReports"
            :tooltipRow="false" :maxPDFRows="9" :searchByDateRange="false" :viewSearch="false"
            :isLoading="pageData.isLoading" :footerData="footerData" noDataText="please select to view reports"
            :data="reportsRow" :header="tableHeader" :tableHeight="$store.state.Settings.windowSize.y - 280">
            <template v-slot:td="{ row, header }">
              <span class="d-flex justify-center" v-if="header.key !== 's_m_id' && header.key !== 'r_m_id'">
                <span v-if="row.sumtion == 2"> ({{ row[header.key] || 0 | float }})</span>
                <span v-else> {{ row[header.key] || 0 | float }}</span>
              </span>
            </template>

            <template v-slot:inputs>
              <v-row align="center" class="mt-n7">

                <GenericInput type="date" :maxDate="to_date || today" :clearable="true" :value="from_date"
                  @input="from_date = $event" label="from" :required="false" paddingY="pt-3" :hide-details="true"
                  :isLoading="false" :cols="[12, 'auto', 2]" />

                <GenericInput type="date" :minDate="from_date" :maxDate="today" :clearable="true" :value="to_date"
                  @input="to_date = $event" label="to" :required="false" paddingY="pt-3" :hide-details="true"
                  :isLoading="false" :cols="[12, 'auto', 2]" />

                <GenericInput type="select" :clearable="true" :lookups="types" :value="type" @input="type = $event"
                  label="type" :multi="false" :required="false" paddingY="pt-3" selected_label="title"
                  selected_prop="id" :hide-details="true" :isLoading="false" :cols="[12, 'auto', 2]" />


                <GenericInput type="select" :clearable="true" :lookups="branches" :value="branch_id"
                  @input="branch_id = $event" label="branch" :multi="false" :required="false" paddingY="pt-3"
                  selected_label="name" selected_prop="id" :hide-details="true" :isLoading="false"
                  :cols="[12, 'auto', 2]" />

                <GenericInput type="text" :clearable="true" :value="invoice_code" @input="invoice_code = $event"
                  :outlined="true" label="invoice code" :required="false" paddingY="pt-3" :hide-details="true"
                  :isLoading="false" :cols="[12, 'auto', 2]" />


                <GenericInput type="select" :clearable="true"
                  :lookups="[{ id: 1, name: $i18n.t('simplified') }, { id: 2, name: $i18n.t('taxed') }]"
                  :value="customer_type" @input="customer_type = $event" label="invoice type" :multi="false"
                  :required="false" paddingY="pt-3" selected_label="name" selected_prop="id" :hide-details="true"
                  :isLoading="false" :cols="[12, 'auto', 2]" />



                <GenericInput type="select" :clearable="true"
                  :lookups="[{ id: 1, name: $t('cash') }, { id: 2, name: $t('network') }, { id: 3, name: $t('transform') }, { id: 7, name: $t('tabby') }, { id: 8, name: $t('tamara') }]"
                  :value="payment_type" @input="payment_type = $event" label="payment type" :multi="false"
                  :required="false" paddingY="pt-3" selected_label="name" selected_prop="id" :hide-details="true"
                  :isLoading="false" :cols="[12, 'auto', 2]" />


                <GenericInput type="select" :clearable="true" :lookups="sales_agents" :value="sales_agent_id"
                  @input="sales_agent_id = $event" label="delegate" :multi="false" :required="false" paddingY="pt-3"
                  selected_label="name" selected_prop="id" :hide-details="true" :isLoading="false"
                  :cols="[12, 'auto', 2]" />

                <v-col cols="12" md="8" class="py-0 d-flex justify-end">
                  <ExportBtn :exportData="reportsRow" :openOnHover="false" :header="tableHeader"
                    :footerData="footerData" :groupHeader="[]" :block="true" classes="gray3 white--text"
                    :attachmentFileName="pageData.entityName" :printTitle="pageData.entityName" :maxPDFRows="24"
                    v-if="reportsRow.length > 0 && $global.CheckAction(screen_code, 5)">
                    <template v-slot:td="{ row, header }">
                      <span class="d-flex justify-center" v-if="header.key !== 's_m_id' && header.key !== 'r_m_id'">
                        <span v-if="row.sumtion == 2"> ({{ row[header.key] || 0 | float }})</span>
                        <span v-else> {{ row[header.key] || 0 | float }}</span>
                      </span>
                    </template>
                  </ExportBtn>
                  <v-btn :loading="pageData.isLoading" @click="getReports()" :height="38" :width="100"
                    class="ms-2 shadow" color="success">
                    {{ $t("search") }}
                    <v-icon size="18" right>mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>

            <template v-slot:afterTable>
              <v-row v-if="reportsRow.length > 0" class="mt-3" align="center">
                <v-col cols="12" md="6" lg="auto" class="pt-0">
                  <div class="d-flex justify-space-between align-center">
                    <div class="font-weight-bold subtitle-1 mx-2">{{ $t('total cash') }} :</div>
                    <div class="font-weight-bold gray5--text subtitle-1 mx-2" dir="ltr">{{
                  totalPriceCalculation.paidCash }}
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="6" lg="auto" class="pt-0">
                  <div class="d-flex justify-space-between align-center">
                    <div class="font-weight-bold subtitle-1 mx-2">{{ $t('total bank') }} :</div>
                    <div class="font-weight-bold gray5--text subtitle-1 mx-2" dir="ltr">{{
                  totalPriceCalculation.paidBank }}
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="6" lg="auto" class="pt-0">
                  <div class="d-flex justify-space-between align-center">
                    <div class="font-weight-bold subtitle-1 mx-2">{{ $t('total transfer') }} :</div>
                    <div class="font-weight-bold gray5--text subtitle-1 mx-2" dir="ltr">{{
                  totalPriceCalculation.paidTransfer }}
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="6" lg="auto" class="pt-0">
                  <div class="d-flex justify-space-between align-center">
                    <div class="font-weight-bold subtitle-1 mx-2">{{ $t('total tamara') }} :</div>
                    <div class="font-weight-bold gray5--text subtitle-1 mx-2">{{ totalPriceCalculation.paidTamara }}
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="6" lg="auto" class="pt-0">
                  <div class="d-flex justify-space-between align-center">
                    <div class="font-weight-bold subtitle-1 mx-2">{{ $t('total tabby') }} :</div>
                    <div class="font-weight-bold gray5--text subtitle-1 mx-2">{{ totalPriceCalculation.paidTabby }}
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="6" lg="auto" class="pt-0">
                  <div class="d-flex justify-space-between align-center">
                    <div class="font-weight-bold subtitle-1 mx-2">{{ $t('total late') }} :</div>
                    <div class="font-weight-bold gray5--text subtitle-1 mx-2">{{ totalPriceCalculation.totalLate }}
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="6" lg="auto" class="pt-0">
                  <div class="d-flex justify-space-between align-center">
                    <div class="font-weight-bold subtitle-1 mx-2">{{ $t('total') }} :</div>
                    <div class="font-weight-bold gray5--text subtitle-1 mx-2">{{ totalPriceCalculation.totalPaid }}
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="6" lg="auto" class="pt-0">
                  <div class="d-flex justify-space-between align-center">
                    <div class="font-weight-bold subtitle-1 mx-2">{{ $t('total invoices') }} :</div>
                    <div class="font-weight-bold gray5--text subtitle-1 mx-2">{{ reportsRow.length }}
                    </div>
                  </div>
                </v-col>
              </v-row>
            </template>


          </DynamicTable>
        </v-col>

      </v-row>
    </v-container>
  </section>
</template>



<script>
import DynamicTable from "@/components/DynamicTable";
import GenericInput from "@/components/GenericInput";
import ExportBtn from "@/components/ExportBtn.vue";
import { EntityMixins } from "@/mixins/EntityMixins";
import { SalesReportMixins } from "@/mixins/SalesReportMixins";
export default {
  name: "FullReportEntity",
  mixins: [EntityMixins, SalesReportMixins],
  data: () => ({
    screen_code: '06-012'
  }),
  components: {
    DynamicTable,
    GenericInput,
    ExportBtn
  },
  computed: {
    totalPriceCalculation() {
      if (this.reportsRow.length > 0) {
        const paidBank = this.reportsRow.reduce((oldValue, newValue) => {
          switch (newValue.sumtion) {
            case 1:
              return Number(+oldValue) + Number(+newValue.paid_bank);
            case 2:
              return Number(+oldValue) - Number(+newValue.paid_bank);
          }
        }, 0);
        const paidCash = this.reportsRow.reduce((oldValue, newValue) => {
          switch (newValue.sumtion) {
            case 1:
              return Number(+oldValue) + Number(+newValue.paid_cash);
            case 2:
              return Number(+oldValue) - Number(+newValue.paid_cash);
          }
        }, 0);
        const paidTransfer = this.reportsRow.reduce((oldValue, newValue) => {
          switch (newValue.sumtion) {
            case 1:
              return Number(+oldValue) + Number(+newValue.paid_trans);
            case 2:
              return Number(+oldValue) - Number(+newValue.paid_trans);
          }
        }, 0);
        const paidTamara = this.reportsRow.reduce((oldValue, newValue) => {
          switch (newValue.sumtion) {
            case 1:
              return Number(+oldValue) + Number(+newValue.paid_tamara);
            case 2:
              return Number(+oldValue) - Number(+newValue.paid_tamara);
          }
        }, 0);
        const paidTabby = this.reportsRow.reduce((oldValue, newValue) => {
          switch (newValue.sumtion) {
            case 1:
              return Number(+oldValue) + Number(+newValue.paid_tabby);
            case 2:
              return Number(+oldValue) - Number(+newValue.paid_tabby);
          }
        }, 0);
        const totalLate = this.reportsRow.reduce((oldValue, newValue) => {
          switch (newValue.sumtion) {
            case 1:
              return Number(+oldValue) + Number(+newValue.not_paid || 0);
            case 2:
              return Number(+oldValue) - Number(+newValue.not_paid || 0);
          }
        }, 0);
        const totalGrandPrice = this.reportsRow.reduce((oldValue, newValue) => {
          switch (newValue.sumtion) {
            case 1:
              return Number(+oldValue) + Number(+newValue.total_with_vat);
            case 2:
              return Number(+oldValue) - Number(+newValue.total_with_vat);
          }
        }, 0);
        const totalWeight = this.reportsRow.reduce((oldValue, newValue) => {
          switch (newValue.sumtion) {
            case 1:
              return Number(+oldValue) + Number(+newValue.weight);
            case 2:
              return Number(+oldValue) - Number(+newValue.weight);
          }
        }, 0);
        const totalPrice = this.reportsRow.reduce((oldValue, newValue) => {
          switch (newValue.sumtion) {
            case 1:
              return Number(+oldValue) + Number(+newValue.total_without_vat);
            case 2:
              return Number(+oldValue) - Number(+newValue.total_without_vat);
          }
        }, 0);
        const totalVat = this.reportsRow.reduce((oldValue, newValue) => {
          switch (newValue.sumtion) {
            case 1:
              return Number(+oldValue) + Number(+newValue.total_vat);
            case 2:
              return Number(+oldValue) - Number(+newValue.total_vat);
          }
        }, 0);

        return {
          paidBank: this.$global.NumberWithCommas(paidBank.toFixed(2)),
          paidCash: this.$global.NumberWithCommas(paidCash.toFixed(2)),
          paidTransfer: this.$global.NumberWithCommas(paidTransfer.toFixed(2)),
          paidTamara: this.$global.NumberWithCommas(paidTamara.toFixed(2)),
          paidTabby: this.$global.NumberWithCommas(paidTabby.toFixed(2)),
          totalLate: this.$global.NumberWithCommas(totalLate.toFixed(2)),
          totalPaid: this.$global.NumberWithCommas((paidBank + paidCash + paidTransfer + totalLate + paidTamara + paidTabby).toFixed(2)),
          totalGrandPrice: this.$global.NumberWithCommas(totalGrandPrice.toFixed(2)),
          totalWeight: this.$global.NumberWithCommas(totalWeight.toFixed(2)),
          totalPrice: this.$global.NumberWithCommas(totalPrice.toFixed(2)),
          totalVat: this.$global.NumberWithCommas(totalVat.toFixed(2)),
        }
      } else {
        return {
          paidBank: 0,
          paidCash: 0,
          paidTransfer: 0,
          totalPaid: 0,
          totalLate: 0,
          totalGrandPrice: 0,
          totalWeight: 0,
          totalPrice: 0,
          totalVat: 0,
        }
      }
    },
  },
  watch: {

  },
  mounted() {
    this.endpoint = "full_report";
    this.getData();
  },
  methods: {

  },
};
</script>
