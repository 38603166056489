<template>
  <section>
    <v-container fluid>
      <v-row justify="space-between">
        <v-col cols="12" sm="auto" class="d-flex justify-center align-center pt-0 pb-2">
          <v-row align="center" justify="center" justify-md="start">
            <v-col cols="12" md="auto">
              <div class="subtitle-2 text-center  text--disabled">
                <strong>{{ $t('sales') | capitalize }} . {{ $t('reports') | capitalize }}</strong>
              </div>
            </v-col>
            <v-col cols="auto" class="px-1" v-if="$global.CheckAction('06-014', 1)">
              <v-btn to="/reports/shift-report" active-class="primary" rounded :min-width="120" style="height: 40px" small
                color="transparent" depressed>{{ $global.FilterPermissions("06-014").sub_title }}</v-btn>
            </v-col>
            <v-col cols="auto" class="px-1" v-if="$global.CheckAction('06-018', 1)">
              <v-btn to="/reports/customer-supplier-statement" color="primary" outlined rounded :min-width="120"
                style="height: 40px" small depressed>{{ $global.FilterPermissions("06-018").sub_title }}</v-btn>
            </v-col>
            <v-col cols="auto" class="px-1" v-if="$global.CheckAction('02-020', 1)">
              <v-btn to="/reports/supplier-statement" outlined rounded :min-width="120" style="height: 40px" small color="primary"
                depressed>{{
                  $global.FilterPermissions("02-020").sub_title }}</v-btn>
            </v-col>
            <v-col cols="auto" class="px-1" v-if="$global.CheckAction('06-012', 1)">
              <v-btn to="/reports/full_report" color="primary" outlined rounded :min-width="120"
                style="height: 40px" small depressed>{{ $global.FilterPermissions("06-012").sub_title }}</v-btn>
            </v-col>
            <v-col cols="auto" class="px-1" v-if="$global.CheckAction('06-010', 1)">
              <v-btn to="/reports/sales_notice_report" color="primary" outlined  rounded :min-width="120" style="height: 40px"
                small  depressed>{{ $global.FilterPermissions("06-010").sub_title }}</v-btn>
            </v-col>
            <v-col cols="auto" class="px-1" v-if="$global.CheckAction('03-004', 1)">
              <v-btn to="/reports/barcode_report" color="primary" outlined  rounded :min-width="120" style="height: 40px"
                small  depressed>{{ $global.FilterPermissions("03-004").sub_title }}</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" class="py-0">
          <DynamicTable :isLoading="isLoading" :searchMethod="searchMethod" :searchByDateRange="true" :viewTitle="false"
            :option="options" :editValueMethod="edit" printURL="print_request" :buttonValueMethod="showDialog"
            :data="rows" :header="header" :tableHeight="$store.state.Settings.windowSize.y - 270">
            <template v-slot:inputs>

              <GenericInput type="select" paddingY="pt-3" :clearable="true" :lookups="branches" selected_label="name"
                selected_prop="id" :value="filter.branch_id" @input="filter.branch_id = $event" label="branches"
                :isLoading="false" :hideDetails="true" :cols="[12, 2, 2]"></GenericInput>

              <GenericInput type="select" paddingY="pt-3" :clearable="true" :lookups="users"
                selected_label="user_full_name" selected_prop="id" :value="filter.user_id"
                @input="filter.user_id = $event" label="employee" :isLoading="false" :hideDetails="true"
                :cols="[12, 2, 2]"></GenericInput>

            </template>
            <template v-slot:td="{ row, header }">
              <div v-if="header.key == 'actions'">
                <v-btn @click="edit(row)" x-small icon fab>
                  <v-icon>mdi-eye-outline</v-icon>
                </v-btn>
              </div>
            </template>

          </DynamicTable>
        </v-col>
        <v-col cols="12" sm="12" class="py-0" v-if="pagination">
          <Pagination :limit="rowsNumber" :backValueMethod="changePage" :pagination="pagination" :isLoading="isLoading">
          </Pagination>
        </v-col>
      </v-row>


    </v-container>
  </section>
</template>



<script>
import DynamicTable from "@/components/DynamicTable";
import Pagination from "@/components/Pagination";
import GenericInput from "@/components/GenericInput.vue";
export default {
  name: "ShiftReportsTable",

  data: () => ({
    isLoading: false,
    controlRoute: null,
    optionDialog: false,
    selectedRow: null,
    options: null,
    rows: [],
    header: [],
    pagination: null,
    page: 1,
    rowsNumber: 25,
    searchText: '',
    today: null,
    branches: [],
    users: [],
    filter: {
      branch_id: null,
      user_id: null,
      from_date: null,
      to_date: null,
    }
  }),
  components: {
    DynamicTable,
    Pagination,
    GenericInput,
  },
  computed: {},
  mounted() {
    this.pageMainData();
    this.getData();
  },
  methods: {
    pageMainData() {
      document.title = `${this.$i18n.t("Qarat - ")}${this.$global.FilterPermissions("06-014").sub_title}`;
      this.header = [
        { text: "employee", key: "user_full_name", type: 'text', classes: "", },
        { text: "start date", key: "start_date", type: 'date', classes: "", },
        { text: "end date", key: "end_date", type: 'text', classes: "", },
        { text: "branch", key: "branch_name", type: 'text', classes: "", },
        { text: "start cash", key: "start_cash", type: 'non_zero_float', classes: "", },
        { text: "sales cash", key: "total_sales_cash", type: 'non_zero_float', classes: "", },
        { text: "credit notice", key: "total_nc", type: 'non_zero_float', classes: "", },
        { text: "debit notice", key: "total_nd", type: 'non_zero_float', classes: "", },
        { text: "expenses", key: "total_expenses", type: 'non_zero_float', classes: "", },
        { text: "deposits", key: "total_deposits", type: 'non_zero_float', classes: "", },
        { text: "total cash", key: "total_cash", type: 'non_zero_float', classes: "", },
        { text: "end cash", key: "end_cash", type: 'non_zero_float', classes: "", },
        { text: "difference", key: "total_diff", type: 'non_zero_float', classes: "red2--text font-weight-bold", },
        { text: "details", key: "actions", type: 'slot', classes: "", },

      ];
      this.options = {
        print: true,
        delete: false,
        edit: false,
        restore: false,
        view: false,
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    showDialog(row) {
      // this.optionDialog = true;
      this.selectedRow = row
    },
    optionBackMethod(status) {
      console.log(status);
      this.optionDialog = false;
      this.selectedRow = null;
    },
    getData() {
      this.isLoading = true;
      this.$api.GET_METHOD(`shift?rows=${this.rowsNumber}&page=${this.page}&word=${this.searchText || ''}&user_id=${this.filter.user_id || ''}&branch_id=${this.filter.branch_id || ''}&from_date=${this.filter.from_date || ''}&to_date=${this.filter.to_date || ''}`)
        .then((response) => {
          this.isLoading = false;
          this.users = response.data.users;
          this.branches = response.data.branchs;
          this.rows = response.data.items.data;
          this.page = response.data.items.current_page;
          this.pagination = {
            page: response.data.items.current_page,
            totalPages: response.data.items.last_page,
            per_page: response.data.items.per_page,
            totalRows: response.data.items.total,
          };
        })

    },
    edit(edit) {
      console.log(edit);
      this.$router.push(`/reports/shift-report/` + edit.id)
    },

    searchMethod(text, date, from, to) {
      this.searchText = text;
      this.filter.from_date = from;
      this.filter.to_date = to;
      if (!this.isLoading) {
        this.getData()
      }
    },
    changePage(page, limit) {
      this.page = page;
      this.rowsNumber = limit;
      this.getData();
    },
  },
};
</script>
