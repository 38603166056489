<template>
  <section>
    <v-container fluid>
      <v-row justify="space-between">
        <v-col cols="12" class="d-flex justify-center align-center py-0 mb-6">
          <v-row align="center" justify="space-between">
            <v-col cols="12" md="auto">
              <div class="subtitle-2 text-center me-3 text--disabled">
                <strong>{{ $t('transfers') | capitalize }} . {{ $t('transfer metal') }} . {{ $t('add') | capitalize }} {{
                  $t('transfer metal') }}</strong>
              </div>
            </v-col>
            <v-col cols="12" md="auto">
              <v-btn color="red12" class="error--text mx-2" :width="120" depressed @click="exitDialog = true">
                {{ $t('cancel') }}
                <v-icon right>mdi-close</v-icon>
              </v-btn>
              <v-btn color="success" :width="120" :loading="saveIsLoading" :disabled="!(valid && tableRows.length)"
                depressed @click="save">
                {{ $t('save') }}
                <v-icon right>mdi-check</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-card class="shadow">

        <v-row justify="center" v-if="isLoading">
          <v-col cols="auto">
            <v-progress-circular :size="70" :width="5" style="margin: auto; " class="d-block" color="primary"
              indeterminate></v-progress-circular>
            <p class="text-center">{{ $t('Loading data') }}</p>
          </v-col>
        </v-row>

        <v-form ref="form" v-model="valid" class="pa-5" v-if="!isLoading">
          <v-sheet class="backgroundW  pa-5">
            <v-row justify="center" align="center">

              <GenericInput type="date" :solo="false" :dense="true" classes="rounded-lg" :value="form.action_date"
                @input="form.action_date = $event" label="date" :required="false" :hide-details="true" :cols="[12, 6, 3]">
              </GenericInput>

              <GenericInput type="select" :lookups="branches" :value="form.to_branch_id"
                @input="form.to_branch_id = $event" label="transfer to branch" :multi="false" :required="true"
                selected_label="name" selected_prop="id"
                :disableItem="{ key: 'id', value: $store.state.userData.user.branch_data.id }" :hide-details="true"
                :isLoading="false" :cols="[12, 6, 3]">
              </GenericInput>

              <GenericInput type="text" :keydownEnter="searchByBarcode" :solo="false" :dense="true" classes="rounded-lg"
                :value="code" @input="code = $event" label="barcode" :isLoading="isSearchLoading" :required="false"
                :hide-details="true" :cols="[12, 6, 3]" icon="mdi-barcode">
              </GenericInput>
              <v-col cols="12" md="6" lg="3" class="d-flex align-center">
                <v-btn color="primary" width="48%" :loading="isSearchLoading" @click="searchByBarcode" :disabled="!code"
                  :height="39" depressed>
                  <v-icon left>mdi-magnify</v-icon>
                  {{ $t('search') }}
                </v-btn>
                <v-divider vertical class="mx-2" style="height: 37px;"></v-divider>
                <v-btn color="blue2" @click="newLineDialog = true" class="white--text" width="48%" :height="39" depressed>
                  {{ $t('add manual line') }}
                </v-btn>
              </v-col>
              <GenericInput type="textarea" :solo="false" :dense="true" classes="rounded-lg" :value="form.description"
                @input="form.description = $event" label="description" :required="false" :hide-details="true"
                :cols="[12, 12, 12]">
              </GenericInput>



              <v-col cols="12" class="px-0" v-if="tableRows.length">
                <DynamicTable :isLoading="isLoading" classes="shadow-none" :searchByDateRange="false" :showHeader="false"
                  :viewTitle="false" :data="tableRows" :header="tableHeader">
                  <template v-slot:td="{ header, index }">
                    <div v-if="header.key == 'actions'">
                      <v-btn @click="removeLine(index)" color="error" x-small icon fab>
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </div>
                  </template>

                </DynamicTable>
              </v-col>

              <v-col cols="12" v-if="tableRows.length == 0">
                <v-alert width="fit-content" class="d-block ma-auto" :dense="true" text type="info">
                  {{ $t('enter item that you want to transfer from') | capitalize }}
                  <strong>
                    {{ $vuetify.rtl ? $store.state.userData.user.shift.name_ar : $store.state.userData.user.shift.name_en }}
                  </strong>
                  <span v-if="form.to_branch_id"> {{ $t('to') | capitalize }} </span>
                  <strong v-if="form.to_branch_id">{{ selectedBranch.name }}</strong>
                </v-alert>
              </v-col>


            </v-row>
          </v-sheet>
        </v-form>


      </v-card>

    </v-container>
    <TransferAddItem :closeDialog="() => newLineDialog = false" :addItem="addItem" :vats="vats" :categories="categories"
      :subCategories="sub_categories" :gold_price="gold_price" v-if="newLineDialog == true" :dialog="newLineDialog"
      :clarities="clarities" :qualities="qualities" :shapes="shapes" :colors="colors" :tableLength="tableRows.length" />

    <ExitConfirmationModal :dialog="exitDialog" :closeDialog="() => exitDialog = false" exit_to="/transfers" />

  </section>
</template>
 
 
 
<script>
import TransferAddItem from "./TransferAddItem.vue";
import GenericInput from "@/components/GenericInput.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import ExitConfirmationModal from "@/components/modals/ExitConfirmationModal.vue";
export default {
  name: "CashTransfer",
  data: () => ({
    valid: false,
    isEdit: false,
    isLoading: false,
    isSearchLoading: false,
    saveIsLoading: false,

    branches: [],
    banks: [],
    categories: [],
    sub_categories: [],
    clarities: [],
    colors: [],
    qualities: [],
    shapes: [],
    gold_price: {
      price_18: 0,
      price_21: 0,
      price_22: 0,
      price_24: 0,
    },
    vats: {
      "18": 0.15,
      "21": 0.15,
      "22": 0.15,
      "24": 0,
      "25": 0,
      "26": 0,
      "50": 0
    },
    activeTab: 1,
    key: 1,
    code: null,
    form: {
      to_branch_id: null,
      action_date: null,
      description: null,
      items: [],
    },
    today: null,
    newLineDialog: false,
    tableRows: [],
    exitDialog: false,
  }),
  components: {
    TransferAddItem,
    GenericInput,
    DynamicTable,
    ExitConfirmationModal
  },
  computed: {
    selectedBranch() {
      return this.branches.find((branch) => branch.id == this.form.to_branch_id) || Object
    },
    tableHeader() {
      const hasDiamond = this.tableRows.some((row) => row.category_id == 25)
      const hasStone = this.tableRows.some((row) => row.category_id == 26)
      const hasSilver = this.tableRows.some((row) => row.category_id == 925)
      let header = [
        { text: "serial", key: "item_code", type: 'text', classes: "", },
        { text: "type", key: "department_name", type: 'text', classes: "" },
        { text: "item by caliber", key: "sub_category_name", type: 'text', classes: "" },
        { text: "gold weight", key: "gold_weight", type: 'float', classes: "" },
        { text: "caliber price", key: "caliber_price", type: 'float', classes: "" },
        { text: "", key: "actions", type: 'slot', hideInPrint : false, classes: "", },
      ];
      if (hasDiamond) {
        header.splice(5, 0,
          { text: "diamond weight", key: "diamond_weight", type: 'float', classes: "" },
          { text: "shape", key: "shape_title", type: 'text', classes: "" },
          { text: "color", key: "color_title", type: 'text', classes: "" },
          { text: "clarity", key: "clarity_title", type: 'text', classes: "" },
          { text: "quality", key: "quality_title", type: 'text', classes: "" },
        )
      }
      if (hasStone) {
        header.splice(4, 0,
          { text: "stone weight", key: "stone_weight", type: 'float', classes: "" },
        )
      }
      if (hasSilver) {
        header.splice(4, 0,
          { text: "silver weight", key: "silver_weight", type: 'float', classes: "" },
        )
      }


      return header
    }
  },
  watch: {},
  mounted() {
    this.pageMainData();
  },
  methods: {
    pageMainData() {
      this.today = this.$global.GetCurrentDate();
      this.form.action_date = this.$global.GetCurrentDate();
      if (this.$route.params.type == 'metal') {
        this.branches = this.$route.params.responseData.branchs || []
        this.sub_categories = this.$route.params.responseData.sub_categorys || [];
        this.categories = this.$route.params.responseData.categorys || [];
        this.$route.params.responseData.colors.unshift({ color_id: 0, color_code: '-' })
        this.$route.params.responseData.claritys.unshift({ clarity_id: 0, clarity_code: '-' })
        this.$route.params.responseData.shaps.unshift({ id: 0, title: '-' })
        this.$route.params.responseData.qualitys.unshift({ quality_id: 0, title: '-' })
        this.clarities = this.$route.params.responseData.claritys;
        this.shapes = this.$route.params.responseData.shaps;
        this.colors = this.$route.params.responseData.colors;
        this.qualities = this.$route.params.responseData.qualitys;
        this.tableRows = this.$route.params.details || [];
        this.form = this.$route.params.master;
        this.isEdit = true
      } else {
        this.getData();
      }
    },
    getData() {
      if (this.$route.params.master) {
        this.form = this.$route.params.master;
        this.tableRows = this.$route.params.details;
      }
      this.isLoading = true;
      this.$api.GET_METHOD(`metal_transfer/create`).then((response) => {
        this.isLoading = false;
        if (response.check) {
          this.branches = response.data.branchs || []
          this.sub_categories = response.data.sub_categorys || [];
          this.categories = response.data.categorys || [];
          response.data.colors.unshift({ color_id: 0, color_code: '-' })
          response.data.claritys.unshift({ clarity_id: 0, clarity_code: '-' })
          response.data.shaps.unshift({ id: 0, title: '-' })
          response.data.qualitys.unshift({ quality_id: 0, title: '-' })
          this.clarities = response.data.claritys;
          this.shapes = response.data.shaps;
          this.colors = response.data.colors;
          this.qualities = response.data.qualitys;
        }
      });
    },
    save() {
      this.saveIsLoading = true;
      this.form.items = this.tableRows;
      if (this.isEdit) { 
        this.form._method = 'PUT';
        this.$api.POST_METHOD(`metal_transfer/${this.form.id}`, this.form).then((response) => {
          this.saveIsLoading = false;
          if (response.check) {
            this.$router.push(`/transfers/${this.form.id}?type=metal`)
          }
        })
      }
      else {
        this.$api.POST_METHOD(`metal_transfer`, this.form).then((response) => {
          this.saveIsLoading = false;
          if (response.check) {
            this.$router.push(`/transfers/${response.data.id}?type=metal`)
          }
        })
      }
    },
    addItem(item) {
      this.tableRows.push(item)
      this.newLineDialog = false
    },
    searchByBarcode() {
      if (this.tableRows.some((item) => item.item_code == this.code) == true) {
        this.$api.ShowAlert("info", this.$i18n.t("item is used"), null)
      } else {
        this.isSearchLoading = true;
        this.$api.GET_METHOD(`search_item?code=${this.code}`).then((response) => {
          this.isSearchLoading = false;
          if (response.check == true) {
            this.code = null;
            let newItem = response.data || Object
            newItem.item_code = response.data.id
            newItem.sub_category_id = response.data.subCategoryId
            newItem.weight = response.data.weight
            switch (newItem.categoryId) {
              case 25:
                newItem.department_name = this.$i18n.t('diamond');
                break;
              case 26:
                newItem.department_name = this.$i18n.t('stone');
                break;
              case 925:
                newItem.department_name = this.$i18n.t('silver');
                break;
              default:
                newItem.department_name = this.$i18n.t('gold');
                break;
            }
            const clarity = this.clarities.find((clarity) => clarity.clarity_id == newItem.clarity_id)
            newItem.clarity_title = clarity.clarity_code
            const quality = this.qualities.find((quality) => quality.quality_id == newItem.quality_id)
            newItem.quality_title = quality.title
            const shape = this.shapes.find((shape) => shape.id == newItem.shap_id)
            newItem.shape_title = shape.title
            const color = this.colors.find((color) => color.color_id == newItem.color_id)
            newItem.color_title = color.color_code
            newItem.sub_category_name = newItem.itemTitle
            newItem.caliber_price = newItem.caliber_price || 0;
            newItem.category_id = newItem.categoryId
            this.tableRows.push(newItem)
          } else {
            this.$api.ShowAlert("info", this.$i18n.t("No Results"), '')
          }
        })

      }
    },
    removeLine(index) {
      this.tableRows.splice(index, 1);
    },
  }

};
</script>
 
 