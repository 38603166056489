import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueFullScreen from "@/plugins/VueFullScreen";
import axios from "./plugins/custom-axios";
import i18n from "./plugins/i18n";
import VueCryptojs from "./plugins/cryptojs";
import VueLazyload from "./plugins/lazyload";
import otp from "./plugins/otp";
import "@/plugins/apexcharts";
import VueSweetalert2 from "./plugins/sweetalert";
import apiPlugin from "./plugins/api";
import global from "./plugins/global";
import hijriCalendar from "./plugins/hijriCalendar";
import filters from "./filters/filters";
import "./registerServiceWorker";
import issues from "@/plugins/issues";
Vue.prototype.$http = axios;
Vue.use(apiPlugin);
Vue.use(global);
Vue.use(issues);
Vue.filter(filters);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  VueSweetalert2,
  i18n,
  VueCryptojs,
  VueFullScreen,
  VueLazyload,
  hijriCalendar,
  otp,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
