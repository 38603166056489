<template>
  <v-checkbox  v-model="input" :disabled="disabled" hide-details :color="color" class="ma-0"
    :rules="required == true ? $global.requiredRule : $global.notRequiredRule" :true-value="1"
    :false-value="0">
    <template v-slot:label>
      <label v-if="labelInline == false">
        {{ $t(label) }}
        <span class="error--text" v-if="required == true">*</span>
      </label>
    </template>
  </v-checkbox>
</template>


<script>
export default {
  name: "CheckBoxInput",
  props: {
    name: { default: null },
    label: { default: null },
    type: { default: null },
    value: { default: null },
    required: { default: false },
    dense: { default: true },
    onChange: { type: Function },
    isLoading: { default: null },
    disabled: { default: null },
    color: { default: "primary" },
    icon: { default: "" },
    hideDetails: { default: false },
    labelInline: { default: false },
    clearable: { default: false },
    hideImage: { default: false },
    onClickOuter: { type: Function },
    keydownEnter: { type: Function },
    placeholder: { default: null },
    solo: { default: false },
    classes: { default: null },
    dir: { default: null },
    reverse: { default: false }
  },
  data: () => ({

  }),
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {

  },
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {

  },
};
</script>



<style scoped lang="scss">

</style>
