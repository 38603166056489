<template>
  <!-- <v-row justify="center"> -->
  <v-dialog v-model="dialog" persistent max-width="450">
    <v-card relative>
      <v-btn
        absolute
        class="ma-2"
        :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'"
        small
        fab
        color="grey lighten-1"
        text
        @click="close"
      >
        <v-icon color="grey lighten-1">mdi-close</v-icon>
      </v-btn>

      <div class="pa-8">
        <v-row align="center" class="mt-1" justify="center">
          <v-col cols="6" sm="3" class="px-1">
            <div
              class="light rounded-lg cursor_pointer caption px-1"
              @click="setGoldType(18)"
              :class="goldCaliber != 18 ? 'text--disabled' : 'font-weight-bold'"
            >
              <div class="d-flex align-center">
                <img
                  src="../../assets/img/svg/dollar.svg"
                  height="13px"
                  class="mx-1"
                  alt="Qarat-POS"
                />
                <span
                  class="text--disabled"
                >{{ gold_price['price_18'] | float }} {{ $t('ryal') }}</span>
              </div>
              <v-icon color="#0A6ADA" size="14" :disabled="goldCaliber != 18">mdi-check-circle</v-icon>
              {{ $t('gold') }} {{ $t('caliber') }} 18
            </div>
          </v-col>
          <v-col cols="6" sm="3" class="px-1">
            <div
              class="light rounded-lg cursor_pointer caption px-1"
              @click="setGoldType(21)"
              :class="goldCaliber != 21 ? 'text--disabled' : 'font-weight-bold'"
            >
              <div class="d-flex align-center">
                <img
                  src="../../assets/img/svg/dollar.svg"
                  height="13px"
                  class="mx-1"
                  alt="Qarat-POS"
                />
                <span
                  class="text--disabled"
                >{{ gold_price['price_21'] | float }} {{ $t('ryal') }}</span>
              </div>
              <v-icon color="#0A6ADA" size="14" :disabled="goldCaliber != 21">mdi-check-circle</v-icon>
              {{ $t('gold') }} {{ $t('caliber') }} 21
            </div>
          </v-col>
          <v-col cols="6" sm="3" class="px-1">
            <div
              class="light rounded-lg cursor_pointer caption px-1"
              @click="setGoldType(22)"
              :class="goldCaliber != 22 ? 'text--disabled' : 'font-weight-bold'"
            >
              <div class="d-flex align-center">
                <img
                  src="../../assets/img/svg/dollar.svg"
                  height="13px"
                  class="mx-1"
                  alt="Qarat-POS"
                />
                <span
                  class="text--disabled"
                >{{ gold_price['price_22'] | float }} {{ $t('ryal') }}</span>
              </div>
              <v-icon color="#0A6ADA" size="14" :disabled="goldCaliber != 22">mdi-check-circle</v-icon>
              {{ $t('gold') }} {{ $t('caliber') }} 22
            </div>
          </v-col>
          <v-col cols="6" sm="3" class="px-1">
            <div
              class="light rounded-lg cursor_pointer caption px-1"
              @click="setGoldType(24)"
              :class="goldCaliber != 24 ? 'text--disabled' : 'font-weight-bold'"
            >
              <div class="d-flex align-center">
                <img
                  src="../../assets/img/svg/dollar.svg"
                  height="13px"
                  class="mx-1"
                  alt="Qarat-POS"
                />
                <span
                  class="text--disabled"
                >{{ gold_price['price_24'] | float }} {{ $t('ryal') }}</span>
              </div>
              <v-icon color="#0A6ADA" size="14" :disabled="goldCaliber != 24">mdi-check-circle</v-icon>
              {{ $t('gold') }} {{ $t('caliber') }} 24
            </div>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="11" v-if="activeStep == 1">
            <h5 class="mb-5">{{ $t("weight") }} :</h5>
            <NumberPad :backValueMethod="setWeight" :value="weight == 0 ? '' : String(weight)"></NumberPad>
          </v-col>
          <v-col cols="11" v-if="activeStep == 2">
            <h5 class="mb-5">
              {{ $t("price") }} :
              <small
                v-if="minPrice > 0"
                class="text--disabled"
              >{{ $t('minimum price') }} : {{ $global.DecimalFormatter(minPrice.toFixed(2)) }}</small>
            </h5>
            <NumberPad :backValueMethod="setPrice" :value="price == 0 ? '' : String(price)"></NumberPad>
          </v-col>
          <v-col cols="11" class="pt-0" v-if="activeStep == 1">
            <v-btn
              color="success"
              min-width="200"
              large
              class="d-block shadow mi-start-auto"
              @click="activeStep = 2"
              :disabled="!weight"
            >{{ $t("next") }}</v-btn>
          </v-col>
          <v-col cols="11" class="pt-0" v-if="activeStep == 2">
            <v-btn
              color="success"
              min-width="200"
              large
              class="d-block shadow mi-start-auto"
              @click="add()"
              :disabled="!valid"
            >{{ $t("add") }}</v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
  <!-- </v-row> -->
</template>



<style scoped lang="scss">
</style>



<script>
import NumberPad from "../NumberPad.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("InvoiceStore");
export default {
  name: "UsedModal",
  props: {
    backValueMethod: { type: Function },
    loading: { default: false },
    gold_price: { default: {} },
    dialog: { default: false },
    vats: { default: {} },
  },

  mounted() {
    window.addEventListener("keydown", e => {
      if (e.key == 'Enter' && this.dialog == true) {
        if (Number(this.weight) > 0 && this.activeStep == 1) { // weight active
          this.activeStep = 2  //activeStep 2
        }
        else if (this.valid == true && this.activeStep == 2) { // price active
          this.add() //Done
        }

      }
    });
  },
  computed: {
    ...mapState(["bill", "customerType"]),
    valid() {
      if (this.customerType == 1) {

        if (Number(this.price) > this.minPrice) {
          return true
        }
        else {
          return false
        }
      }
      else {
        return true
      }
    },
    goldPrice() {
      switch (this.goldCaliber) {
        case 18:
          return this.gold_price.price_18

        case 21:
          return this.gold_price.price_21

        case 22:
          return this.gold_price.price_22

        case 24:
          return this.gold_price.price_24
        default:
          return 0;

      }
    },
    totalPriceWithoutVat() {
      return (Number(this.price) / (1 + Number(this.vats[this.goldCaliber])));
    },
    totalPriceWithVat() {
      return Number(this.price)
    },
    totalVat() {
      return Number(this.price) - (Number(this.price) / (1 + Number(this.vats[this.goldCaliber])));
    },
    minPrice() {
      return (Number(this.weight) * this.goldPrice) * (1 + Number(this.vats[this.goldCaliber]))
    },
  },
  components: {
    NumberPad,
  },
  methods: {
    ...mapActions(["addBillAction"]),
    setWeight(returnedWeight) {
      this.weight = returnedWeight;
    },
    setGoldType(type) {
      this.goldCaliber = type;
    },
    setPrice(returnedPrice) {
      this.price = returnedPrice;
    },
    add() {
      let summary = {
        subCategoryId: 50,
        itemTitle: "كسر",
        categoryId: this.goldCaliber,
        gold_weight: this.weight,
        countPieces: 0,
        minPrice: this.totalPriceWithoutVat,
        priceWithoutVat: Number(+this.totalPriceWithoutVat),
        vat: this.totalVat,
        price: this.price,
        countPiecesPrice: 0,
        itemCode: 'used'
      }
      this.addBillAction(summary).finally(() => {
        this.weight = '';
        this.price = '';
        this.activeStep = 1
        this.backValueMethod(true)
      });
    },


    close() {
      this.setWeight(0);
      this.setPrice(0);
      this.backValueMethod(true);
      this.activeStep = 1
    },
  },
  data() {
    return {
      weight: "",
      price: "",
      tab: null,
      goldCaliber: 18,
      activeStep: 1,

    };
  },
};
</script>
