<template>
  <v-form ref="form" v-model="valid" onsubmit="return false;" lazy-validation>
    <v-row justify="space-between">
      <v-col cols="12" md="9">
        <v-card class="shadow rounded-lg justify-center my-2 pa-5">
          <v-row justify="center">
            <v-col cols="12" lg="10" xs="12">
              <v-stepper v-model="activeStep" elevation="0">
                <v-stepper-header>
                  <v-stepper-step step="1" :complete="activeStep > 1">{{ translation['write the item name']
                  }}</v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step step="2" :complete="activeStep > 2">{{ translation['weight'] }}</v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step step="3" :complete="activeStep > 3">{{ translation['price'] }}</v-stepper-step>
                  <v-divider></v-divider>

                  <v-stepper-step step="4">{{ translation['customer details'] }}</v-stepper-step>
                </v-stepper-header>
              </v-stepper>
            </v-col>

            <!-- Fields -->
            <v-col cols="12" lg="9" xs="12" v-if="activeStep == 1">
              <h5>{{ translation.items }}</h5>
              <v-textarea class="my-2 rounded-lg grey lighten-5" v-model="form.items" :hide-details="true" filled
                autofocus rounded></v-textarea>
            </v-col>
            <v-col cols="12" lg="5" xs="6" v-if="activeStep == 2">
              <h5>{{ translation.pleaseEnterWeight }}</h5>
              <NumberPad :backValueMethod="setWeight" :value="form.weight"></NumberPad>
            </v-col>
            <v-col cols="12" lg="5" xs="6" v-if="activeStep == 3">
              <h5>{{ translation.pleaseEnterThePrice }}</h5>
              <NumberPad :backValueMethod="setPrice" :value="form.price"></NumberPad>
            </v-col>
            <v-col cols="12" v-if="activeStep == 4">
              <v-row align="center">
                <v-col cols="12" sm="6" md="4" class="py-2">
                  <h5>{{ translation.PleaseEnterThecustomerName }}</h5>
                  <v-text-field :hide-details="true" class="my-2 rounded-lg grey lighten-5" filled autofocus
                    :rules="fieldRule" v-model="form.customerName" rounded dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-2">
                  <h5>{{ translation.qty }}</h5>
                  <v-text-field :hide-details="true" class="my-2 rounded-lg grey lighten-5" filled :rules="fieldRule"
                    v-model="form.countPices" rounded type="number" dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-2">
                  <h5>{{ translation.mobileNumber }}</h5>
                  <v-text-field :hide-details="true" class="my-2 rounded-lg grey lighten-5" filled v-model="form.mobile"
                    rounded type="number" dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-2">
                  <h5>{{ translation.idNumber }}</h5>
                  <v-text-field :hide-details="true" class="my-2 rounded-lg grey lighten-5" filled
                    v-model="form.customerID" rounded type="number" dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-2">
                  <h5>{{ $t('identity image') }}</h5>
                  <v-file-input :hide-details="true" class="my-2 rounded-lg grey lighten-5" filled v-model="form.id_photo"
                    rounded dense></v-file-input>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-2">
                  <h5>{{ $t('item image') }}</h5>
                  <v-file-input :hide-details="true" class="my-2 rounded-lg grey lighten-5" filled
                    v-model="form.item_photo" rounded dense></v-file-input>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-2">
                  <h5>{{ $t('date of birth') }}</h5>
                  <div class="d-flex align-center">
                    <label class="cursor_pointer" @click="form.birth_date_type = 0">{{ $t('georgian') }}</label>
                    <v-switch v-model="form.birth_date_type" class="mx-2" inset />
                    <label class="cursor_pointer" @click="form.birth_date_type = 1">{{ $t('hijri') }}</label>
                  </div>
                </v-col>


                <v-col cols="12" sm="6" md="4" class="py-2" v-if="form.birth_date_type == 0">
                  <h5>{{ $t('georgian date') }}</h5>
                  <v-menu ref="menu" offset-x v-model="menu" :close-on-content-click="false" transition="scale-transition"
                    min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field @blur="date = parseDate(dateFormatted)" v-model="form.birth_date_m" filled rounded
                        dense @change="menu = false" class="my-2 rounded-lg grey lighten-5" prepend-icon="mdi-calendar"
                        readonly v-bind="attrs" v-on="on">
                        <template v-slot:append>
                          <div class="mt-1" v-if="form.birth_date_h">{{ form.birth_date_h }}</div>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker reactive show-adjacent-months locale="ar-eg" v-model="form.birth_date_m"
                      :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                      @change="setDate" />
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-2" v-if="form.birth_date_type == 1">
                  <h5>{{ $t('hijri date') }}</h5>
                  <v-menu ref="menu" v-model="menuHijri" :close-on-content-click="false" transition="scale-transition"
                    offset-x min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="form.birth_date_h" filled rounded dense @change="menuHijri = false"
                        class="my-2 rounded-lg grey lighten-5" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                        v-on="on">
                        <template v-slot:append>
                          <div class="mt-1" v-if="form.birth_date_m">{{ form.birth_date_m }}</div>
                        </template>
                      </v-text-field>
                    </template>
                    <v-hijri-date-picker v-model="form.birth_date_h" @change="menuHijri = false"
                      :active-picker.sync="activePicker" locale="ar" />
                    <!-- <v-date-picker reactive show-adjacent-months locale="ar-sa" v-model="form.birth_date_h"
                      :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                      @change="setDate" /> -->

                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="px-5">
                  <div class="mt-4">
                    <SellarModal block :height="40" :sales_agents="sales_agents" :sellar="salesAgent"
                      :backValueMethod="setAgent" :checkChangeAgent="false" />
                  </div>
                </v-col>
              </v-row>
            </v-col>



            <!-- Buttons  -->
            <v-col cols="12" xs="12" class="d-flex justify-end align-center">
              <v-btn depressed class="d-block mi-start-auto" color="light" v-if="activeStep != 1" @click="activeStep--">{{
                translation.prev }}</v-btn>
              <v-btn v-if="activeStep == 1" depressed :class="activeStep != 1 ? 'mx-2' : 'd-block mi-start-auto'"
                color="success" @click="activeStep++" :disabled="!form.items">{{ translation.next }}</v-btn>
              <v-btn v-if="activeStep == 2" depressed :class="activeStep != 1 ? 'mx-2' : 'd-block mi-start-auto'"
                color="success" @click="activeStep++" :disabled="!form.weight">{{ translation.next }}</v-btn>
              <v-btn v-if="activeStep == 3" depressed :class="activeStep != 1 ? 'mx-2' : 'd-block mi-start-auto'"
                color="success" @click="activeStep++" :disabled="!form.price">{{ translation.next }}</v-btn>

              <v-btn v-if="activeStep == 4" elevation="0" :class="activeStep != 1 ? 'mx-2' : 'd-block mi-start-auto'"
                color="success" @click="openDialog" :loading="loading" :disabled="loading">{{ translation['save invoice']
                }}</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card class="shadow rounded-lg justify-center my-2 py-5 px-5">
          <div class="d-flex align-center">
            <v-icon :color="form.items ? 'primary' : '#9e9e9e'">mdi-check-circle-outline</v-icon>
            <p class="my-0 mx-2 font-weight-medium">{{ $t('items') }}</p>
          </div>
          <p v-if="form.items">{{ form.items }} - {{ translation.gold }} {{ translation.caliber }} {{ categoryID }}</p>
          <v-divider class="my-3"></v-divider>

          <div class="d-flex align-center">
            <v-icon :color="form.weight ? 'primary' : '#9e9e9e'">mdi-check-circle-outline</v-icon>
            <p class="my-0 mx-2 font-weight-medium">{{ translation['weight'] }}</p>
          </div>
          <p v-if="form.weight">{{ $global.DecimalFormatter(Number(form.weight).toFixed(2)) }} {{ translation['gram'] }}
          </p>
          <v-divider class="my-3"></v-divider>

          <div class="d-flex align-center">
            <v-icon :color="form.price ? 'primary' : '#9e9e9e'">mdi-check-circle-outline</v-icon>
            <p class="my-0 mx-2 font-weight-medium">{{ translation['price'] }}</p>
          </div>
          <p v-if="form.price">{{ $global.DecimalFormatter(Number(form.price).toFixed(2)) }} {{ translation['ryal'] }}</p>
          <v-divider class="my-3"></v-divider>

          <div class="d-flex align-center">
            <v-icon
              :color="form.customerID && form.customerName && form.mobile ? 'primary' : '#9e9e9e'">mdi-check-circle-outline</v-icon>
            <p class="my-0 mx-2 font-weight-medium">{{ translation['customer details'] }}</p>
          </div>
          <p v-if="form.customerID && form.customerName && form.mobile">{{ form.customerName }}</p>
          <p v-if="form.customerID && form.customerName && form.mobile">{{ form.mobile }}</p>
          <p v-if="form.customerID && form.customerName && form.mobile">{{ form.customerID }}</p>
        </v-card>
      </v-col>
    </v-row>
    <UsedInvoiceSummery :backValueMethod="makeInvoice" :dialog="dialog" :summery="form" :loading="loading" />
  </v-form>
</template>





<script>
import NumberPad from "./NumberPad.vue";
import UsedInvoiceSummery from "./modals/UsedInvoiceSummery.vue";
import SellarModal from "@/components/modals/SellarModal.vue";


export default {
  name: "PurchaseUsedForm",
  components: {
    NumberPad,
    UsedInvoiceSummery,
    SellarModal,
  },
  computed: {
    translation() {
      return this.$store.state.Settings.translation;
    },
  },
  props: {
    categoryID: { default: 18 },
    sub_category_id: { default: null },
    isLoading: { default: false },
    sales_agents: { default: [] },
    subCategories: { default: [] },
    selectedItem: { default: {} },
    selectedAgent: { default: {} },
  },
  data() {
    return {
      dateIsLoading: false,
      dialog: false,
      valid: false,
      menu: false,
      menuHijri: false,
      activePicker: null,
      activeStep: 1,
      salesAgent: Object,
      form: {
        weight: "",
        price: "",
        customerID: null,
        mobile: null,
        customerName: null,
        items: null,
        countPices: null,
        subCategory: null,
        sales_agent_id: null,
        notes: null,
        id_photo: null,
        birth_date_type: 0,
        birth_date_h: null,
        birth_date_m: null,
        item_photo: null,
      },
      loading: false,
      fieldRule: [
        v => !!v || this.translation['Field is required']
      ],
      printID: 0,
      printContent: {},
      printLoading: false,
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    // 'form.birth_date_type'() {
    //   this.form.birth_date_h = null
    //   this.form.birth_date_m = null
    // },
    'form.birth_date_m'() {
      if (this.form.birth_date_type == 0) {
        this.form.birth_date_h = this.form.birth_date_m ? this.$global.GregorianToHijri(this.form.birth_date_m) : null;
      }
    },
    'form.birth_date_h'() {
      if (this.form.birth_date_type == 1) {
        this.form.birth_date_m =null;
        this.dateIsLoading = true
        this.$api.GET_METHOD(`get_gregorian_data/${this.form.birth_date_h}`).then((response) => {
          this.dateIsLoading = false;
          if(response.check){
            this.form.birth_date_m = response.data.hijri_date
          }
        })

      }
    },
  },
  mounted() {
    this.salesAgent = this.selectedAgent
    this.form.sales_agent_id = this.selectedAgent.id
    window.addEventListener("keydown", e => {
      if (e.key == 'Enter') {
        if (this.form.items && this.activeStep == 1) {
          this.activeStep = 2
        }
        else if (Number(this.form.weight) > 0 && this.activeStep == 2) {
          this.activeStep = 3
        }
        else if (Number(this.form.price) > 0 && this.activeStep == 3) {
          this.activeStep = 4;
          this.$refs.form.validate();
        }
        else if (this.form.price && this.activeStep == 4) {
          this.openDialog()
        }
      }
    });
  },
  methods: {
    openDialog() {
      this.dialog = true
    },
    setWeight(returnedWeight) {
      this.form.weight = returnedWeight;
    },
    setPrice(returnedPrice) {
      this.form.price = returnedPrice;
    },

    makeInvoice(status) {
      this.dialog = false;
      if (status == true) {
        this.$refs.form.validate();
        let requestBody = {
          category: Number(+this.categoryID),
          countPices: Number(+this.form.countPices),
          idNumber: Number(+this.form.customerID),
          items: this.form.items,
          mobile: Number(+this.form.mobile),
          name: this.form.customerName,
          weight: Number(+this.form.weight),
          price: Number(+this.form.price),
          sub_category_id: Number(+this.sub_category_id),
          sales_agent_id: this.form.sales_agent_id,
          notes: this.form.notes,
          id_photo: this.form.id_photo,
          birth_date_type: this.form.birth_date_type,
          birth_date_h: this.form.birth_date_h,
          birth_date_m: this.form.birth_date_m,
          item_photo: this.form.item_photo,
        }
        // let requestBody = this.$global.FORM_DATA(form)

        if (this.$refs.form.validate() && this.form.weight && this.form.price && this.sub_category_id) {
          this.loading = true;
          this.$api.POST_METHOD_MULTIPART("create_used_invoice", requestBody, true, this.$i18n.t('Invoice Created Successfully')).then((response) => {
            this.loading = false;
            if (response.check) {
              this.dialog = false
              this.$refs.form.reset();
              this.form.weight = " ";
              this.form.price = " ";
              this.form.items = "";
              this.form.birth_date_type = 0;
              this.form.birth_date_h = null;
              this.form.birth_date_m = null;
              this.activeStep = 1;
              // this.$api.DOWNLOAD_METHOD(`export_invoice_used_purchase/${response.data}?type=pdf`)
              const src = `src="${this.$api.serverUrl}export_invoice_used_purchase/${response.data}?type=image&clientID=${this.$store.state.clientID}"`;
              this.$api.PrintInvoice(src, response.data);
            }
          })
        }
        else {
          this.$swal.fire({
            title: this.translation["Please Check"],
            text: this.translation["Required Fields"],
            icon: "info",
            showConfirmButton: false,
            timer: 1500,
          });
        }

      } else {
        this.dialog = false
      }
    },
    parseDate(date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${year}-${month}-${day}`
    },
    setAgent(returnedValue) {
      this.salesAgent = returnedValue;
      this.form.sales_agent_id = returnedValue.id
    },
    setDate(date) {
      this.$refs.menu.save(date)
    },

  }
};
</script>



<style  lang="scss">
.v-expansion-panel::before {
  box-shadow: 0px 4px 28px rgb(0 0 0 / 5%) !important;
}

.v-expansion-panel-content {
  max-height: 235px;
  overflow-y: scroll;
}

#printInvoice {
  @media screen {
    display: none;
  }

  @media print {
    display: block;
  }
}
</style>

