<template>
  <section>
    <v-container fluid>
      <v-row justify="space-between">
        <v-col cols="12" sm="auto" class="d-flex justify-center align-center pt-0 pb-2">
          <v-row align="center" justify="center" justify-md="start">
            <v-col cols="12" md="auto">
              <div class="subtitle-2 text-center  text--disabled">
                <strong>{{ $t('sales') | capitalize }} . {{ $t('notices') | capitalize }}</strong>
              </div>
            </v-col>
            <v-col cols="auto" class="px-1">
              <v-btn to="/notice/creditor" color="primary" outlined rounded :min-width="120" style="height: 40px" small
                depressed>{{ $t("notice creditor") }}</v-btn>
            </v-col>
            <v-col cols="auto" class="px-1">
              <v-btn to="/notice/debtor" active-class="primary" rounded :min-width="120" style="height: 40px" small
                color="transparent" depressed>{{ $t("notice debtor") }}</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" class="py-0">
          <DynamicTable :isLoading="isLoading" :searchMethod="searchMethod" :controlRoute="returnBy"
            createBtnLabel="create notice debtor" :printURL="'export_notice_debtor'" :data="rows" :header="header">
            <template v-slot:td="{ row, header }">
              <div v-if="header.key == 'sales_invoice_m_id'">
                <v-btn
                  @click="() => { salesInvoiceDialog = true; invoice_id = row.sales_invoice_m_id; sales_invoice_m_id = row.sales_invoice_m_id }"
                  class="text-decoration-underline body-2 font-weight-bold info--text ma-1" small text>
                  {{ row.sales_invoice_m_id }}
                </v-btn>
              </div>
            </template>
          </DynamicTable>
        </v-col>
        <v-col cols="12" sm="12" class="py-0" v-if="pagination">
          <Pagination :limit="rowsNumber" :backValueMethod="changePage" :pagination="pagination" :isLoading="isLoading">
          </Pagination>
        </v-col>
      </v-row>
    </v-container>
    <SalesInvoiceModal :dialog="salesInvoiceDialog" :canEdit="false" :backValueMethod="closeDialog"
      :invoice_id="invoice_id" :doc_code="sales_invoice_m_id" />
  </section>
</template>



<script>
import DynamicTable from "@/components/DynamicTable";
import Pagination from "@/components/Pagination";
import SalesInvoiceModal from "@/components/modals/SalesInvoiceModal.vue";
export default {
  name: "DebtorTable",

  data: () => ({
    isLoading: true,
    rows: [],
    header: [],
    pagination: null,
    page: 1,
    rowsNumber: 25,
    searchText: '',
    from: null,
    to: null,
    date: null,
    returnBy: "/notice/create-notice-debtor",
    salesInvoiceDialog: false,
    sales_invoice_m_id: null,
    invoice_id: null,
  }),
  components: {
    DynamicTable,
    Pagination,
    SalesInvoiceModal
  },
  computed: {

  },
  mounted() {
    document.title = this.$i18n.t("Qarat - notice debtor");
    this.pageMainData();
    this.getData();
  },
  methods: {
    pageMainData() {
      this.header = [
        { text: "invoice number", key: "m_id", type: 'text', classes: "" },
        { text: "associated sales invoice", key: "sales_invoice_m_id", type: 'slot', classes: "" },
        { text: "customerName", key: "customer_name", type: 'text', classes: "" },
        { text: "customerMobile", key: "customer_mobile", type: 'text', classes: "" },
        { text: "date", key: "invoice_date", type: 'text', classes: "" },
        { text: "payment type", key: "payment_type_title", type: 'text', classes: "" },
        { text: "branch", key: "branch_name", type: 'text', classes: "" },
        { text: "added by", key: "user_full_name", type: 'text', classes: "" },
        { text: "print", key: "m_id", type: 'print', classes: "" },
      ]
    },
    getData() {
      this.isLoading = true;
      if (this.$global.CheckAction("06-003", 1)) {
        this.returnBy = "/notice/create-notice-debtor"
        this.$api.GET_METHOD(`get_notice_debtor_invoices?rows=${this.rowsNumber}&page=${this.page}&word=${this.searchText}&date=${this.date || ''}`).then((response) => {
          this.isLoading = false;
          // this.rows = response.data.data
          this.rows = response.data.data.data;
          this.page = response.data.data.current_page;
          this.pagination = {
            page: response.data.data.current_page,
            totalPages: response.data.data.last_page,
            per_page: response.data.data.per_page,
            totalRows: response.data.data.total,
          };
        })
      }
      else {
        this.$router.push('/')
      }
    },
    searchMethod(text, date, from, to) {
      this.searchText = text;
      this.from = from;
      this.to = to;
      this.date = date;
      this.getData()
    },
    changePage(page, limit) {
      this.page = page;
      this.rowsNumber = limit;
      this.getData();
    },
    closeDialog() {
      this.salesInvoiceDialog = false;
      this.invoice_id = null;
      this.sales_invoice_m_id = null;
    },
  },
};
</script>
