<template>
  <div v-if="$store.state.isAuth == true">
    <v-app-bar :height="$store.state.userData?.warning_note ? 120 : 65"  color="backgroundW" class="shadow pa-0"  app>
      <Appbar />
    </v-app-bar>

    <v-main>
      <v-container v-if="$store.state.splashScreen == false" fluid>
        <router-view></router-view>
          <SpeedDial />
      </v-container>
    </v-main>


  </div>
</template>


<script>
import Appbar from "../components/Appbar.vue";
import SpeedDial from "../components/SpeedDial.vue";
export default {
  name: "Dashboard",
  components: {
    Appbar,
    SpeedDial
},
  data: () => ({
    //
  }),
  mounted() {
    this.$store.dispatch("checkAppVersion");
    this.$store.dispatch("checkAuth")
  },
};
</script>
