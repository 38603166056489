<template>
   <div>
      <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="false" :preview-modal="true"
         :paginate-elements-by-height="$store.state.Settings.windowSize.x" :filename="'multi_voucher'" :pdf-quality="2"
         :manual-pagination="true" pdf-format="a4" pdf-orientation="portrait"
         :pdf-content-width="$store.state.Settings.windowSize.y" ref="html2Pdf">
         <section slot="pdf-content" :dir="$vuetify.rtl ? 'rtl' : 'ltr'">
            <PrintPDF :header="tableHeader" :tableHeight="'auto'" :maxPDFRows="40" :data="[{}]" :header_slot="false"
               :content_slot="false" :footer_slot="false" :showGroupHeader="false" :groupHeader="false" :footerData="[]"
               :customize_header_footer="false" :printStaticData="printStaticData"
               :printTitle="$t('deposit') + ' ( ' + master.id + ' )'">
               <template v-slot:pdf_content="{ page }">
                  <table width="100%" v-if="page == 1" id="master_table_voucher">
                     <tr>
                        <td width="25%">{{ $t('invoice number') }} : <span>{{ master.id }}</span> </td>
                        <td width="25%">{{ $t('action date') }} : <span>{{ master.action_date }}</span> </td>
                        <td width="25%">{{ $t('deposit code') }} : <span>{{ master.code_name }}</span> </td>
                     </tr>

                     <tr>
                        <td width="25%">{{ $t('total with vat') }} : <span>
                              {{ master.amount | float }}</span> </td>
                        <td v-if="master.tax_name" width="25%">{{ $t('tax') }} : <span>
                              {{ master.tax_name || '-' }} ( {{ master.amount_without_vat | float }} )</span> </td>
                        <td width="25%">{{ $t('branch') }} : <span>
                              {{ master.branch_name || '-' }}</span> </td>
                        <td v-if="master.treasury_name_ar" width="25%">{{ $t('treasury') }} : <span>
                              {{ master.treasury_name_ar || '-' }}</span> </td>
                     </tr>

                     <tr>
                        <td colspan="4" style="background : #eee !important; text-align: end !important;">
                           {{ $t('description') }} :
                           <span>{{ master.description }}</span>
                        </td>
                     </tr>
                  </table>
               </template>
            </PrintPDF>
         </section>
      </vue-html2pdf>
   </div>
</template>

<script>
import PrintPDF from "@/components/PrintPDF.vue";
import VueHtml2pdf from 'vue-html2pdf'
export default {
   name: "DepositsPrint",
   props: {
      printAction: { type: Function },
      print: { default: false },
      printData: { default: [] },
      printStaticData: { default: Object },
   },
   computed: {
      master() {
         let master = this.printStaticData?.data || {};
         master.tax_name = this.printStaticData?.taxes?.find(tax => tax.id == master?.tax_id)?.name || null;
         master.code_name = this.printStaticData?.deposits_codes?.find(tax => tax.id == master?.deposit_code_id)?.name || null;
         return master
      }
   },
   components: {
      PrintPDF,
      VueHtml2pdf,
   },
   watch: {
      print() {
         if (this.print) {
            this.$refs.html2Pdf.generatePdf();
         }
      },

   },
   data: () => ({
      dis_types: [],
      tableHeader: [],
      voucher_types: [],
   }),
   methods: {

   },
   mounted() { },
};
</script>

<style lang="scss" scoped>
.summary_row {
   padding: 10px 0.2rem;
   border-bottom: 2px solid #eee;
   font-size: 12px !important;
   display: flex !important;
   justify-content: space-between !important;
}

#master_table_voucher {
   border: 1px solid #000;
   margin-bottom: 10px;
   //-webkit-box-shadow: 4px 4px 0px -1px rgba(0, 0, 0, 0.75) !important;
   //-moz-box-shadow: 4px 4px 0px -1px rgba(0, 0, 0, 0.75) !important;
   //box-shadow: 4px 4px 0px -1px rgba(0, 0, 0, 0.75) !important;

   tr {
      background: #fff !important;

      &:last-of-type {
         td {
            //          border-top: 1px solid #000 !important;
         }
      }
   }

   td {

      text-align: start !important;
      padding: 5px !important;
      font-weight: 800;
      background: #fff !important;

      span {
         color: blue !important;
      }

   }
}

#print_title {
   width: fit-content;
   text-align: center;
   font-size: 12px !important;
   color: var(--blue1);
   background: var(--blue12);
   display: block;
   padding: 1.2rem;
   margin: auto;
   margin-bottom: 20px;
}
</style>