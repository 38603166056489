import Vue from "vue";
import Vuex from "vuex";
import Settings from "./Settings";
import InvoiceStore from "./InvoiceStore";
import router from "../router";
import axios from "axios";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // APP Settings
    app_version: process.env.VUE_APP_VERSION, //last app update Year.Month.Day.UploadNumber 
    splashScreen: false,
    sidebarIsOpen: false,
    endpointURL: process.env.VUE_APP_SERVER || 'https://new-api.qaraterp.com/', // Server
    apiAccess:
      "xcVBybDQhtDRqPxFUkpe1JVsZqyGGBR1v1KqendQ2qcLbqXB9hgqin41uRJ3qGTwD0L1hmRS29a0gXbKf8EBVcBy7ofvhKviDzJ88u8p6Hs9mNncXZSXOxeSQ7Ka7tlEhCnBMFtVSgZbuYf9sBubou5pWBxu1okFMC2ksCfk3jxdTQmeHq5xBlOL3pmkqq32L26LX1EB",
    //
    clientID: localStorage.getItem("clientID")
      ? localStorage.getItem("clientID")
      : null,
    lastUpdate:
      localStorage.getItem("lastUpdate") !== "undefined"
        ? localStorage.getItem("lastUpdate")
        : null,
    updated: new Date(),
    screens: [],
    permissions: [],
    shift: {},
    current_branch: "",
    branches: [],
    current_year: "",
    years: [],
    isAuth: false,
    showSnackbar: false,
    snackbarType: 'info',
    snackbarTime: 4500,
    snackbarTitle: null,
    snackbarMessages: [],
    requestLoader: {
      isLoading: false,
      isDialog: true,
      loadingMessage: 'is loading',
      successMessage: 'loading done',
      errorMessage: null,
    },
    shiftOpen: false,
    tableLarge: localStorage.getItem("tableLarge")
      ? localStorage.getItem("tableLarge")
      : true,
    userData: null,
  },
  mutations: {
    UPDATE_CLIENT(state, id) {
      state.clientID = id;
      axios.defaults.headers.common["clientID"] = id;
    },
    UPDATE_TOKEN(state, token) {
      state.accessToken = token;
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    },
    LAST_UPDATE(state, date) {
      state.lastUpdate = new Date(date);
    },
    SET_USER(state, user) {
      state.userData = user;
      localStorage.setItem(
        Vue.prototype.$global.CRYPT("cloud", "user"),
        Vue.prototype.$global.CRYPT("cloud", JSON.stringify(user))
      );
    },
  },

  actions: {
    checkAppVersion() {
      localStorage.setItem("app_version", process.env.VUE_APP_VERSION);
    },
    checkAuth({ state }) {
      state.isAuth = localStorage.getItem(Vue.prototype.$global.CRYPT("cloud", "access-token")) ? true : false;
      if (!state.isAuth) {
        router.push("/login");
      } else {
        state.accessToken = Vue.prototype.$global.DECRYPT("cloud", localStorage.getItem(Vue.prototype.$global.CRYPT("cloud", "access-token"))|| null);
        axios.defaults.headers.common["Authorization"] = "Bearer " + state.accessToken;
      }
    },
    setUser({ commit }, user) {
      commit("SET_USER", user);
    },
    setLastUpdate({ commit }, date) {
      commit("LAST_UPDATE", date);
    },
    getUserData({ state }) {
      state.splashScreen = true;
      let yearId = localStorage.getItem("yearid");
      let branchId = localStorage.getItem("branchid");
      if (yearId) {
        Vue.prototype.$api.SET_Branch_Year(null, yearId, false);
      }
      if (branchId) {
        Vue.prototype.$api.SET_Branch_Year(branchId, null, false);
      }
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.GET_METHOD(`get_my_profile`).then((response) => {
          if (response.check) {
            state.splashScreen = false;
            Vue.prototype.$api.SET_AUTH(response.data);
            resolve(response);
          }
        }, error => { reject(error) });
      })
    },
  },

  modules: { Settings, InvoiceStore },
});
