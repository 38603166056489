<template>
  <v-card class="rounded-lg shadow justify-center pa-0" :max-height="height >= 300 ? height : 300" style="
    overflow-y: auto;
    background-color:  transparent ;
">
    <v-col cols="12" sm="12" class="pa-0">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th>{{ $t('item') }}</th>
              <th>{{ $t('caliber') }}</th>
              <th>{{ $t('weight') }}</th>
              <th>{{ $t('pieces') }}</th>
              <th>{{ $t('gram price without vat') }}</th>
              <th v-if="hasDiamond">{{ $t('diamond count') }}</th>
              <th v-if="hasDiamond">{{ $t('diamond weight') }}</th>
              <th v-if="hasStone">{{ $t('stone weight') }}</th>
              <th v-if="hasSilver">{{ $t('silver weight') }}</th>
              <th>{{ $t('price') }}</th>
              <th>{{ $t('delete') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in bill" :key="index">
              <td>{{ row.itemTitle }} <v-icon color="red5" size="15" class="ms-1"
                  v-if="row.has_discount && discount.discount_amount">mdi-sale</v-icon></td>
              <td>
                <span v-if="row.categoryId == 25">{{ $t('diamond') }}</span>
                <span v-else-if="row.categoryId  >= 26 && row.categoryId  <= 49">{{ row.category_title }}</span>
                <span v-else-if="row.categoryId == 50">{{ $t('services') }}</span>
                <span
                  v-else-if="row.categoryId == 18 || row.categoryId == 21 || row.categoryId == 22 || row.categoryId == 24">
                  {{ $t('caliber') }} {{ row.categoryId }}</span>
                <span v-else>{{ row.category_title }}</span>
              </td>
              <td>{{ $global.DecimalFormatter(Number(row.gold_weight).toFixed(2)) + " " + $t('gram') }}</td>
              <td>{{ row.countPieces }}</td>
              <td>{{ row.priceWithoutVat / (row.diamond_weight || row.stone_weight || row.silver_weight || row.gold_weight || row.weight  || 0 ) | float }}</td>
              <td v-if="hasDiamond">{{ row.categoryId == 25 ? row.count_diamonds : '-' }}</td>
              <td v-if="hasDiamond">{{ row.categoryId == 25 ?
                $global.DecimalFormatter(Number(row.diamond_weight).toFixed(2)) + " " + $t('gram') : '-' }}</td>
              <td v-if="hasStone">{{ row.categoryId == 26 ?
                $global.DecimalFormatter(Number(row.stone_weight).toFixed(2)) + " " + $t('gram') : '-' }}</td>
              <td v-if="hasSilver">{{ row.categoryId == 925 ?
                $global.DecimalFormatter(Number(row.silver_weight).toFixed(2)) + " " + $t('gram') : '-' }}</td>

              <td>{{ $global.DecimalFormatter(Number(row.price).toFixed(2)) + " " + $t('ryal') }}</td>

              <td>
                <v-btn elevation="0" color="transparent" fab @click="deleteItem(index)" x-small>
                  <v-icon color="error">mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>


            <tr v-if="bill.length != 0">
              <th colspan="2">{{ translation.total }}</th>
              <th>{{ $global.DecimalFormatter(Number(totalWeight).toFixed(2)) + " " + $t('gram') }}</th>

              <th>{{ totalPieces }}</th>
              <th colspan="7" class="text-center">{{ $global.DecimalFormatter(Number(totalPrice).toFixed(2)) + " " +
                $t('ryal') }}</th>
            </tr>
            <tr v-if="bill.length == 0" class="white">
              <th colspan="10" class="py-3" style="border : 0">
                <v-alert class="d-block ma-auto" dense outlined text type="info">{{ $t('noItemsAdded') }}</v-alert>
              </th>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("InvoiceStore");
export default {
  name: "InvoiceData",
  data: () => ({
    height: 0,
    hasDiamond: false,
    hasStone: false,
    hasSilver: false,
  }),
  mounted() {
    this.height = window.innerHeight - 400
  },
  watch: {
    bill() {
      this.hasDiamond = false;
      this.hasStone = false;
      this.bill.forEach(row => {
        switch ((Number(row.categoryId) == 25 && this.hasDiamond == false)) {
          case true:
            this.hasDiamond = true
            break;
        }
        switch ((Number(row.categoryId) == 26 && this.hasStone == false)) {
          case true:
            this.hasStone = true
            break;
        }
        switch ((Number(row.categoryId) == 925 && this.hasSilver == false)) {
          case true:
            this.hasSilver = true
            break;
        }
      });


    }
  },
  computed: {
    ...mapState(["bill", "totalPrice", "totalPieces","discount", 'totalPricePieces', "totalWeight"]),
    translation() {
      return this.$store.state.Settings.translation;
    },
  },
  methods: {
    ...mapActions(["deleteBillAction"]),
    deleteItem(index) {
      this.deleteBillAction(index).finally(() => { });
    },
  },
};
</script>



<style scoped lang="scss">
thead {
  tr {
    background-color: #e8e8ee !important;

    &:hover {
      background-color: #e8e8ee !important;
    }
  }
}

tr {
  &:hover {
    background: transparent !important;
  }

  &:nth-of-type(even) {
    background-color: #fff;

    &:hover {
      background-color: #fff !important;
    }
  }

  &:nth-of-type(odd) {
    background-color: #f1f1f3;

    &:hover {
      background-color: #f1f1f3 !important;
    }
  }

  &:last-of-type {
    background-color: #e8e8ee;

    &:hover {
      background-color: #e8e8ee !important;
    }
  }
}

td,
th {
  border-left: 1px solid #e0e0e0;
  border-bottom: 0 !important;
  text-align: center !important;
}

td:last-of-type,
th:last-of-type {
  border-left: 0px solid #e0e0e0;
}
</style>

