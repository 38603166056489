<template>
  <!-- style="margin-top: 3rem !important;" -->
  <v-card class="shadow rounded-lg justify-center my-4 pa-5 py-14 rounded-xl" style="margin-top: 3rem !important;    width: 600px;    margin: auto;">
    <!-- <v-form ref="form" v-model="valid" lazy-validation> -->
    <v-row justify="center">
      <v-col cols="9" md="7" sm="10" class="pb-0">
        <div class="d-flex align-center justify-space-between">
          <h5>{{ $t("code") }}</h5>
          <div class="d-flex align-center justify-space-between">
            <h5
              v-if="noResults"
              class="rounded py-1 px-2 mx-1 caption blue-grey lighten-2 white--text"
            >{{ $t("No Results") }}</h5>
            <h5
              v-if="exist == true"
              class="rounded py-1 px-2 mx-1 caption blue-grey lighten-2 white--text"
            >{{ $t("item is used") }}</h5>
            <v-btn
              small
              @click="searchByCode"
              class="mx-1 my-0"
              :loading="isLoading"
              :disabled="!code"
              color="primary"
              elevation="0"
            >{{ $t("search") }}</v-btn>
          </div>
        </div>
        <NumberPad
          :backValueMethod="setCode"
          :disabled="true"
          :disabledDot="true"
          :clickEnter="searchByCodeMachine"
          :value="code"
          :autofocus="autofocus"
          :number="true"
        ></NumberPad>
      </v-col>
      <v-col cols="9" md="7" sm="10" class="py-0 mt-n2 mb-7" style="position: relative;">
        <v-divider class="my-4 mx-10 mb-6" style="border-color: #cccccc"></v-divider>
        <span
          class="px-5 text--disabled"
          style="    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0px;
    right: 0px;
    top: 0.2rem;
    text-align: center;
    background-color: rgb(255, 255, 255);
    width: fit-content;"
        >{{ $t("or") }}</span>
        <v-btn
          class="rounded"
          @click="usedModal = true"
          depressed
          block
          color="success"
          large
          :class="'font-weight-bold'"
        >{{ $t('add used') }}</v-btn>
      </v-col>
    </v-row>
    <!-- </v-form> -->

    <ItemModalPieces
      :gold="{
        caliber: this.caliber,
        sub_category_id: this.sub_category_id,
        item: this.item,
        item_code: this.item_code,
        vat: this.vat,
        weight: this.weight,
        price: this.price,
        basePrice: this.basePrice,
        lessPrice: this.lessPrice,
        sellPrice: this.sellPrice,
        sellCost: this.sellCost,
        minPrice: this.minPrice,
        goldPrice: this.goldPrice,
      }"
      :vats="vats"
      :dialog="dialog"
      :closeDialog="closeDialog"
    ></ItemModalPieces>
    <UsedModal
      :vats="vats"
      :dialog="usedModal"
      :gold_price="gold_price"
      :backValueMethod="usedModalMethod"
    ></UsedModal>
  </v-card>
</template>




<style scoped lang="scss">
</style>



<script>
import NumberPad from "./NumberPad.vue";
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapState } = createNamespacedHelpers("InvoiceStore");
import ItemModalPieces from "./modals/ItemModalPieces.vue"
import UsedModal from "./modals/UsedModal.vue";
export default {
  name: "SalesForm",
  components: {
    NumberPad,
    ItemModalPieces,
    UsedModal
  },
  computed: {
    ...mapState(["bill", "customerType"]),
  },

  props: ["vats", "gold_price"],
  data: () => ({
    code: '',
    caliber: '',
    sub_category_id: '',
    item: '',
    item_code: 0,
    dialog: false,
    vat: '',
    weight: '',
    price: '',
    basePrice: '',
    lessPrice: '',
    sellPrice: '',
    sellCost: '',
    minPrice: 0,
    isLoading: false,
    noResults: false,
    goldPrice: 0,
    autofocus: true,
    exist: false,
    usedModal: false
  }),
  methods: {
    ...mapActions(["addBillAction"]),
    setCode(returnedPrice) {
      this.code = returnedPrice;
      this.noResults = false;
      this.exist = false
    },
    searchByCodeMachine(code) {
      if (Number(code) !== 0) {
        this.noResults = false;
        if (this.bill.some((item) => item.itemCode == code) == true) {
          this.exist = true
        } else {
          this.isLoading = true;
          this.exist = false;
          this.$api.GET_METHOD(`search_gold_items_by_piece?item_code=${code}`).then((response) => {
            this.isLoading = false;
            if (response.data.length !== 0) {
              this.caliber = response.data[0].category_id;
              this.item = response.data[0].sub_name;
              this.item_code = response.data[0].item_code;
              this.weight = response.data[0].weight;
              this.price = ((response.data[0].price * this.vats[response.data[0].category_id]) + response.data[0].price).toFixed(2);
              this.basePrice = ((response.data[0].base_price * this.vats[response.data[0].category_id]) + response.data[0].base_price).toFixed(2);
              this.vat = this.vats[response.data[0].category_id];
              this.lessPrice = ((response.data[0].min_price * this.vats[response.data[0].category_id]) + response.data[0].min_price).toFixed(2);
              this.sellCost = ((response.data[0].price * this.vats[response.data[0].category_id]) + response.data[0].price).toFixed(2);
              this.sub_category_id = response.data[0].sub_category_id;
              this.dialog = true
              switch (response.data[0].category_id) {
                case 18:
                  this.goldPrice = this.gold_price.price_18;
                  break;

                case 21:
                  this.goldPrice = this.gold_price.price_21;
                  break;

                case 22:
                  this.goldPrice = this.gold_price.price_22;
                  break;

                case 24:
                  this.goldPrice = this.gold_price.price_24;
                  break;
                default:
                  this.goldPrice = 0
                  break;

              }
              this.minPrice = (Number(response.data[0].weight) * this.goldPrice) * (1 + Number(response.data[0].category_id))
              this.noResults = false
            } else {
              this.noResults = true;
              this.caliber = '',
                this.sub_category_id = '',
                this.item = '',
                this.weight = '',
                this.vat = '',
                this.price = '',
                this.basePrice = '',
                this.lessPrice = '',
                this.sellPrice = '',
                this.sellCost = ''
            }
          })

        }
      }

    },
    searchByCode() {

      if (this.bill.some((item) => item.itemCode == this.code) == true) {
        this.exist = true
      } else {
        this.exist = false;
        if (this.code && this.code != 0) {

          this.isLoading = true;
          this.$api.GET_METHOD(`search_gold_items_by_piece?item_code=${this.code}`).then((response) => {
            this.isLoading = false;
            if (response.data.length !== 0) {
              this.caliber = response.data[0].category_id;
              this.item = response.data[0].sub_name;
              this.item_code = response.data[0].item_code;
              this.weight = response.data[0].weight;
              this.price = ((response.data[0].price * this.vats[response.data[0].category_id]) + response.data[0].price).toFixed(2);
              this.basePrice = ((response.data[0].base_price * this.vats[response.data[0].category_id]) + response.data[0].base_price).toFixed(2);
              this.vat = this.vats[response.data[0].category_id];
              this.lessPrice = ((response.data[0].min_price * this.vats[response.data[0].category_id]) + response.data[0].min_price).toFixed(2);
              this.sellCost = ((response.data[0].price * this.vats[response.data[0].category_id]) + response.data[0].price).toFixed(2);
              this.sub_category_id = response.data[0].sub_category_id;
              this.dialog = true
              switch (response.data[0].category_id) {
                case 18:
                  this.goldPrice = this.gold_price.price_18;
                  break;

                case 21:
                  this.goldPrice = this.gold_price.price_21;
                  break;

                case 22:
                  this.goldPrice = this.gold_price.price_22;
                  break;

                case 24:
                  this.goldPrice = this.gold_price.price_24;
                  break;
                default:
                  this.goldPrice = 0
                  break;

              }
              console.log('goldPrice', this.goldPrice);
              this.minPrice = (Number(response.data[0].weight) * Number(this.goldPrice)) * (1 + Number(this.vats[response.data[0].category_id]))

              this.noResults = false
            } else {
              this.noResults = true;
              this.caliber = '',
                this.sub_category_id = '',
                this.item = '',
                this.weight = '',
                this.vat = '',
                this.price = '',
                this.basePrice = '',
                this.lessPrice = '',
                this.sellPrice = '',
                this.sellCost = ''
            }
          })
        }
      }

    },
    usedModalMethod() {
      this.usedModal = false
    },
    closeDialog() {
      this.dialog = false;
      this.autofocus = true;
      this.code = '';
      this.item_code = '';
      this.caliber = '';
      this.sub_category_id = '';
      this.item = 0;
      this.weight = '';
      this.vat = '';
      this.price = '';
      this.basePrice = '';
      this.lessPrice = '';
      this.sellPrice = '';
      this.sellCost = '';
    }
  }
};
</script>
