<template>
  <!-- style="margin-top: 3rem !important;" -->
  <v-card class="white rounded-lg shadow  justify-center my-4  pa-5">
    <!-- <v-form ref="form" v-model="valid" lazy-validation> -->
    <v-row justify="center">
      <v-col cols="12" lg="12" md="12" class="d-flex mx-auto align-center py-0">
        <v-text-field class="border me-2" outlined background-color="light" :placeholder="$i18n.t('search by code')"
          v-model="code" @keydown.enter="searchByCode" rounded-md dense autofocus hide-details :readonly="isLoading"
          type="text" clearable />

        <v-text-field v-if="goldCaliber" :key="goldCaliber" class="border" outlined background-color="light"
          :placeholder="$t('search in') + ' ' + categoryTitle" @input="searchByName" rounded-md dense autofocus
          hide-details :readonly="isLoading" type="text" clearable />

        <v-btn @click="searchByCode" :disabled="isLoading" :loading="isLoading" class="my-2 mx-2 shadow" color="primary"
          height="40">
          <v-icon dark left>mdi-magnify-expand</v-icon>
          {{ $t('search') }}
        </v-btn>
      </v-col>
    </v-row>
    <!-- </v-form> -->

    <ItemPieceModal :gold="searchResult" :code="code" :vats="vats" :gold_price="gold_price"
      :sub_categories="sub_categories" :dialog="dialog" :closeDialog="closeDialog" :package_items="package_items"
      :qualities="qualities" :shapes="shapes" :clarities="clarities" :colors="colors" />
  </v-card>
</template>




<style scoped lang="scss"></style>



<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapState } = createNamespacedHelpers("InvoiceStore");
import ItemPieceModal from "./modals/ItemPieceModal.vue"

export default {
  name: "SalesByPiece",
  components: {
    ItemPieceModal,
  },
  computed: {
    ...mapState(["bill", "customerType"]),
    categoryTitle() {
      switch (+this.goldCaliber) {
        case 18:
        case 21:
        case 22:
        case 24: return this.$t('items') + ' ' + this.$t('caliber') + ' ' + this.goldCaliber
        case 25: return this.$t('diamond items')
        case 26: return this.$t('stone items')
        case 50: return this.$t('services')
        case 925: return this.$t('silver items')
        default: return ''
      }
    }
  },
  props: {
    gold_price: { default: {} },
    sub_categories: { default: [] },
    vats: { default: [] },
    goldCaliber: { default: null },
    searchByName: { default: Function },
    clarities: { default: [] },
    qualities: { default: [] },
    shapes: { default: [] },
    colors: { default: [] },
  },
  data: () => ({
    code: '',
    searchResult: {},
    package_items: [],
    dialog: false,
    isLoading: false,
    noResults: false,
    autofocus: true,
    exist: false,
    goldPrice: 0
  }),
  methods: {
    ...mapActions(["addBillAction"]),


    searchByCode() {

      if (this.bill.some((item) => item.itemCode == this.code || item.parent_item_code == this.code) == true) {
        this.exist = true
        this.$api.ShowAlert("info", this.$i18n.t("item is used"), null)
      } else {
        this.exist = false;
        if (this.code && this.code != 0) {

          this.isLoading = true;
          this.$api.GET_METHOD(`search_item?code=${this.code}`).then((response) => {
            this.isLoading = false;
            if (response.check == true) {

              this.dialog = true
              switch (+response.data.categoryId) {
                case 18:
                  this.goldPrice = this.gold_price?.price_18;
                  break;

                case 21:
                  this.goldPrice = this.gold_price?.price_21;
                  break;

                case 22:
                  this.goldPrice = this.gold_price?.price_22;
                  break;

                case 24:
                  this.goldPrice = this.gold_price?.price_24;
                  break;
                default:
                  this.goldPrice = 0
                  break;
              }
              response.data.caliber_price = this.goldPrice;
              this.searchResult = response.data;
              response.data?.package_items.forEach(item => {
                item.price = 0;
                item.checked = false;
              });
              this.package_items = response.data?.package_items || [];
              this.noResults = false
            } else {
              this.isLoading = false;
              this.noResults = true;
              this.searchResult = {};
              this.package_items = [];
              this.$api.ShowAlert("info", this.$i18n.t("No Results"), '')
            }
          })
        }
      }

    },

    closeDialog() {
      this.dialog = false;
      this.autofocus = true;
      this.code = '';
      this.searchResult = {};

    }
  }
};
</script>
