<template>
    <v-dialog v-model="dialog" max-width="380" persistent>
        <v-card relative class="pa-9">
            <v-row align="center">
                <v-col cols="12">
                    <span class="font-weight-bold text-h5 error--text">{{ $t("exit") }}</span>
                </v-col>
                <v-col cols="12">
                    <p>{{ $t("are you sure to get out , you wil lost all entered data") }}</p>
                </v-col>
                <v-col cols="6">
                    <v-btn depressed  @click="cancelControl" color="red12"  block height="44"
                        class="rounded-lg font-weight-bold red1--text">
                        {{ $t('exit') }}
                    </v-btn>
                </v-col>

                <v-col cols="6">
                    <v-btn color="primary" outlined depressed block height="44" @click="closeDialog"
                        class="rounded-lg font-weight-bold white--text">
                        {{ $t('stay here') }}
                    </v-btn>
                </v-col>
            </v-row>

        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "ExitConfirmationModal",
    props: {
        dialog: { default: false },
        exit_to: { default: null },
        closeDialog: { type: Function },
    },
    methods: {
        backRoute() {
            this.$router.go(-1)
        },
        cancelControl() {
            this.exit_to ? this.$router.push(this.exit_to) : this.$router.go(-1)
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
