<template>
  <v-container style="height: 100vh" class="d-flex align-center justify-center">
    <div>
      <h1 style="text-align: center; font-size:72px;">404</h1>
      <h1 style="text-align: center">Not Found</h1>
      <v-btn block to="/" class="mt-5" style="text-align: center" elevation="0">Back Home</v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Error404",
  computed: {},
  mounted() {
    document.title = this.$i18n.t("Qarat - 404");
    let description = `User Enter Wrong Page. URL => ${this.$route.fullPath}`;
    this.$issues.SEND_ISSUE(404, "Error 404", "Frontend wrong route", {}, null, null, description)
  }
};
</script>
