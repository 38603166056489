<template>
   <section>
      <!-- Master -->
      <div v-if="page == 1">
         <table width="100%" class="shift_master_table">
            <tr>
               <td width="15%" class="pa-2  gray6--text">
                  {{ $t('start date') | capitalize }}
               </td>
               <td width="10%" class="pa-2   gray6--text">
                  :
               </td>
               <td width="25%" style="text-align:start !important" class="pa-2 ">
                  {{ report.start_date | capitalize }}
               </td>

               <td width="15%" class="pa-2   gray6--text">
                  {{ $t('end date') | capitalize }}
               </td>

               <td width="10%" class="pa-2   gray6--text">
                  :
               </td>
               <td width="25%" style="text-align:start !important" class="pa-2 ">
                  {{ report.end_date | capitalize }}
               </td>
            </tr>
            <tr>
               <td width="15%" class="pa-2  gray6--text">
                  {{ $t('branch') | capitalize }}
               </td>

               <td width="10%" class="pa-2   gray6--text">:</td>

               <td width="25%" style="text-align:start !important" class="pa-2 ">
                  {{ report.branch_name | capitalize }}
               </td>


               <td width="15%" class="pa-2   gray6--text">
                  {{ $t('employee') | capitalize }}
               </td>

               <td width="10%" class="pa-2   gray6--text">:</td>
               <td width="25%" style="text-align:start !important" class="pa-2 ">
                  {{ report.user_full_name | capitalize }}
               </td>
            </tr>
         </table>
      </div>
      <!-- Sales -->
      <div v-if="page == 1">
         <div class="print_title">
            {{ $t("sales") }}
         </div>
         <table class="shift_table">
            <tr>
               <th>{{ $t("cash") }}</th>
               <th>{{ $t("network") }}</th>
               <th>{{ $t("tabby") }}</th>
               <th>{{ $t("tamara") }}</th>
               <th>{{ $t("STC Pay") }}</th>
               <th>{{ $t("transform") }}</th>
               <th>{{ $t("late sales") }}</th>
               <th>{{ $t("total sales") }}</th>
               <th>{{ $t("refund") }}</th>
               <th>{{ $t("final sales") }}</th>
            </tr>
            <tr>
               <td>{{ report.total_sales_cash | nonZero }}</td>
               <td>{{ report.total_sales_mada + report.total_sales_master + report.total_sales_visa +
         report.total_sales_other
         | nonZero }}</td>
               <td>{{ report.total_sales_tabby | nonZero }}</td>
               <td>{{ report.total_sales_tamara | nonZero }}</td>
               <td>{{ report.total_sales_stc | nonZero }}</td>
               <td>{{ report.total_sales_trans | nonZero }}</td>
               <td>{{ report.postpone_sales | nonZero }}</td>
               <td>{{ report.total_sales | nonZero }}</td>
               <td>{{ report.total_nc | nonZero }}</td>
               <td>{{ report.total_sales - report.total_nc | nonZero }}</td>
            </tr>
         </table>
      </div>
      <!-- Cash Transaction -->
      <div v-if="page == 1">
         <div class="print_title">
            {{ $t("cash transaction") }}
         </div>
         <table class="shift_table">
            <tr>
               <th rowspan="2">{{ $t("start day cash") }}</th>
               <th rowspan="2">{{ $t("total sales cash") }}</th>
               <th rowspan="1" colspan="3">{{ $t("cash withdrawals") }}</th>
               <th rowspan="2">{{ $t("other deposits") }}</th>
               <th rowspan="2">{{ $t("net") }}</th>
               <th rowspan="2">{{ $t("end day balance") }}</th>
               <th rowspan="2">{{ $t("difference") }}</th>
            </tr>
            <tr>
               <th style="border-right: 0 !important;">{{ $t("cash refund") }}</th>
               <th>{{ $t("expenses cash") }}</th>
               <th>{{ $t("used purchase") }}</th>
            </tr>
            <tr>
               <td>{{ report.start_cash | nonZero }}</td>
               <td>{{ report.total_sales_cash | nonZero }}</td>
               <td>{{ report.total_nc | nonZero }}</td>
               <td>{{ report.total_expenses | nonZero }}</td>
               <td>{{ report.total_used_cash | nonZero }}</td>
               <td>{{ report.total_deposits | nonZero }}</td>
               <td>{{ finalCashTransaction | nonZero }}</td>
               <td>{{ report.end_cash | nonZero }}</td>
               <td>{{ finalCashTransaction - report.end_cash | nonZero }}</td>
            </tr>
         </table>
      </div>
      <!-- Gold Sales -->
      <div v-if="page == 1 && goldSales.length">
         <div class="print_title">
            {{ $t("gold sales") }}
         </div>
         <table class="shift_table">
            <tr>
               <th style="border-right: 0 !important;">{{ $t("statement") }}</th>
               <th>{{ $t("caliber") }} 24</th>
               <th>{{ $t("caliber") }} 22</th>
               <th>{{ $t("caliber") }} 21</th>
               <th>{{ $t("caliber") }} 18</th>
               <th>{{ $t("total") }}</th>
            </tr>
            <tr>
               <td>{{ $t('weight') }}</td>
               <td>{{ goldSales[0]?.total_weight | nonZero }}</td>
               <td>{{ goldSales[1]?.total_weight | nonZero }}</td>
               <td>{{ goldSales[2]?.total_weight | nonZero }}</td>
               <td>{{ goldSales[3]?.total_weight | nonZero }}</td>
               <td>{{ goldSales[4]?.total_weight | nonZero }}</td>
            </tr>
            <tr style="border-top : 2px solid #28a9e0 !important">
               <td>{{ $t('sales') }}</td>
               <td>{{ goldSales[0]?.total_without_vat | nonZero }}</td>
               <td>{{ goldSales[1]?.total_without_vat | nonZero }}</td>
               <td>{{ goldSales[2]?.total_without_vat | nonZero }}</td>
               <td>{{ goldSales[3]?.total_without_vat | nonZero }}</td>
               <td>{{ goldSales[4]?.total_without_vat | nonZero }}</td>
            </tr>
            <tr>
               <td>{{ $t('vat') }}</td>
               <td>{{ goldSales[0]?.total_vat | nonZero }}</td>
               <td>{{ goldSales[1]?.total_vat | nonZero }}</td>
               <td>{{ goldSales[2]?.total_vat | nonZero }}</td>
               <td>{{ goldSales[3]?.total_vat | nonZero }}</td>
               <td>{{ goldSales[4]?.total_vat | nonZero }}</td>
            </tr>
            <tr>
               <td>{{ $t('total with vat') }}</td>
               <td>{{ goldSales[0]?.total_with_vat | nonZero }}</td>
               <td>{{ goldSales[1]?.total_with_vat | nonZero }}</td>
               <td>{{ goldSales[2]?.total_with_vat | nonZero }}</td>
               <td>{{ goldSales[3]?.total_with_vat | nonZero }}</td>
               <td>{{ goldSales[4]?.total_with_vat | nonZero }}</td>
            </tr>
            <!-- style="border-top : 2px solid #28a9e0 !important" -->
         </table>
      </div>
      <!-- Used gold transaction -->
      <div v-if="page == 1 && report.calibers_data">
         <div class="print_title">
            {{ $t("used gold transaction") }}
         </div>
         <div style="display:flex">
            <table class="shift_table" style="width :75% !important">
               <tr>
                  <th>{{ $t("the statement") }}</th>
                  <th>{{ $t("start day balance") }}</th>
                  <th>{{ $t("used purchase") }}</th>
                  <th>{{ $t("used sales") }}</th>
                  <th>{{ $t("net balance") }}</th>
                  <th>{{ $t("end day balance") }}</th>
                  <th>{{ $t("difference") }}</th>
               </tr>
               <tr>
                  <td>{{ $t('caliber') }} 18</td>
                  <td>{{ report.start_gold_18 | nonZero }}</td>
                  <td>{{ report.calibers_data?.used_18 | nonZero }}</td>
                  <td>{{ report.calibers_data?.sales_18 | nonZero }}</td>
                  <td>{{ totalGoldPermission(18) | nonZero }}
                  </td>
                  <td>{{ report.final_gold_18 | nonZero }}</td>
                  <td>{{ report.diff_calibers?.caliber_18 | nonZero }}</td>
               </tr>
               <tr>
                  <td>{{ $t('caliber') }} 21</td>
                  <td>{{ report.start_gold_21 | nonZero }}</td>
                  <td>{{ report.calibers_data?.used_21 | nonZero }}</td>
                  <td>{{ report.calibers_data?.sales_21 | nonZero }}</td>
                  <td>{{ totalGoldPermission(21) | nonZero }}
                  <td>{{ report.final_gold_21 | nonZero }}</td>
                  <td>{{ report.diff_calibers?.caliber_21 | nonZero }}</td>
               </tr>
               <tr>
                  <td>{{ $t('caliber') }} 22</td>
                  <td>{{ report.start_gold_22 | nonZero }}</td>
                  <td>{{ report.calibers_data?.used_22 | nonZero }}</td>
                  <td>{{ report.calibers_data?.sales_22 | nonZero }}</td>
                  <td>{{ totalGoldPermission(22) | nonZero }}
                  <td>{{ report.final_gold_22 | nonZero }}</td>
                  <td>{{ report.diff_calibers?.caliber_22 | nonZero }}</td>
               </tr>
               <tr>
                  <td>{{ $t('caliber') }} 24</td>
                  <td>{{ report.start_gold_24 | nonZero }}</td>
                  <td>{{ report.calibers_data?.used_24 | nonZero }}</td>
                  <td>{{ report.calibers_data?.sales_24 | nonZero }}</td>
                  <td>{{ totalGoldPermission(24) | nonZero }}
                  <td>{{ report.final_gold_24 | nonZero }}</td>
                  <td>{{ report.diff_calibers?.caliber_24 | nonZero }}</td>
               </tr>
            </table>
            <table class="shift_table" style="width :25% !important">
               <tr>
                  <th>{{ $t("") }}</th>
                  <th>{{ $t("payment permission") }}</th>
                  <th>{{ $t("receipt permission") }}</th>
               </tr>
               <tr>
                  <td>{{ $t('caliber') }} 18</td>
                  <td>{{ report.gold_permissions?.payment_gold_18 | nonZero }}</td>
                  <td>{{ report.gold_permissions?.receipt_gold_18 | nonZero }}</td>
               </tr>
               <tr>
                  <td>{{ $t('caliber') }} 21</td>
                  <td>{{ report.gold_permissions?.payment_gold_21 | nonZero }}</td>
                  <td>{{ report.gold_permissions?.receipt_gold_21 | nonZero }}</td>
               </tr>
               <tr>
                  <td>{{ $t('caliber') }} 22</td>
                  <td>{{ report.gold_permissions?.payment_gold_22 | nonZero }}</td>
                  <td>{{ report.gold_permissions?.receipt_gold_22 | nonZero }}</td>
               </tr>
               <tr>
                  <td>{{ $t('caliber') }} 24</td>
                  <td>{{ report.gold_permissions?.payment_gold_24 | nonZero }}</td>
                  <td>{{ report.gold_permissions?.receipt_gold_24 | nonZero }}</td>
               </tr>
            </table>
         </div>
      </div>
      <!-- Expenses -->

      <div>
         <div class="print_title">
            {{ $t("expenses") }}
         </div>
         <table class="shift_table">
            <tr>
               <th>{{ $t("beneficiary") }}</th>
               <th>{{ $t("amount") }}</th>
               <th>{{ $t("description") }}</th>
            </tr>
            <tr v-for="(item, index) in expenses" :key="index">
               <td>{{ item.pay_for }} </td>
               <td>{{ item.amount | nonZero }}</td>
               <td>{{ item.description }}</td>
            </tr>
            <tr v-if="isLastPage">
               <td>{{ '' }} </td>
               <td>{{ report.total_expenses | nonZero }}</td>
               <td><b>{{ $t('total') }}</b></td>
            </tr>

         </table>
      </div>
   </section>
</template>



<script>
export default {
   name: "ShiftReportPrint",
   props: {
      report: { default: Object },
      is_view: { default: false },
      page: { default: 1 },
      isLastPage: { default: true },
      expenses: { default: [] },
      printStaticData: { default: Object },
   },
   data: () => ({

   }),
   watch: {
   },
   components: {

   },
   computed: {
      finalCashTransaction() {
         return this.report.start_cash + this.report.total_sales_cash - (this.report.total_nc + this.report.total_expenses + this.report.total_used_cash) + this.report.total_deposits;
      },
      goldSales() {
         let caliber_categories = [24, 22, 21, 18]
         let details = this.report.calibers_data?.details || [];
         let default_obj = { total_weight: 0, total_without_vat: 0, total_vat: 0, total_with_vat: 0 }
         let rows = [];
         caliber_categories.forEach(category => {
            let selected_detail = details.find(detail => +detail.category_id == +category) || default_obj;
            rows.push(selected_detail)
         });
         rows.push({
            total_weight: rows.reduce((total, currentValue) => total + currentValue['total_weight'], 0),
            total_without_vat: rows.reduce((total, currentValue) => total + currentValue['total_without_vat'], 0),
            total_vat: rows.reduce((total, currentValue) => total + currentValue['total_vat'], 0),
            total_with_vat: rows.reduce((total, currentValue) => total + currentValue['total_with_vat'], 0),
         })
         return rows || [];
      },
      totalGoldPermission() {
         return (category) => {
            let calc_calibers_data = this.report.calibers_data ? this.report.calibers_data[`used_${category}`] - this.report.calibers_data[`sales_${category}`] : 0
            let calc_gold_permissions = this.report.gold_permissions ? this.report.gold_permissions[`payment_gold_${category}`] - this.report.gold_permissions[`receipt_gold_${category}`] : 0
            return this.report[`start_gold_${category}`] + calc_calibers_data + calc_gold_permissions;
         }
      }
   },
   mounted() {

   },
   methods: {

   },

};
</script>
<style lang="scss" scoped>
.is_view {}

.shift_master_table {
   td {
      padding: 0 0 0 13px !important;
   }

   td,
   th,
   tr {
      background: transparent;

      &:hover {
         background: transparent !important;
      }
   }
}

td {
   padding: 1.2rem 0.4rem;
   font-size: 12px !important;
}

td:nth-of-type(1),
td:nth-of-type(4) {
   color: var(--gray7) !important;
}

.print_title {
   width: fit-content;
   text-align: center;
   font-size: 14px !important;

   color: var(--blue1);
   background: var(--blue12);
   display: block;
   padding: 3px 6px 6px;
   margin: 5px auto;

   div {
      font-size: 16px !important;
      font-weight: bold;
   }
}

.shift_table {
   margin: 20px 0;
   border-collapse: collapse;
   border: 2px solid #e9e8ee;
   border-bottom: 8px solid #28aae1;
   border-right: 8px solid #28aae1;
   background: #fff !important;
   width: 100%;

   tr {


      th {
         z-index: 2;
         position: relative;
         padding: 5px !important;
         background: #e9e8ee !important;
         border: 1px solid #ddd !important;
         font-size: 12px !important;
      }

      td {
         padding: 5px 35px !important;
         font-weight: 600;
         border-bottom: 1px solid #ddd !important;
         font-size: 12px !important;
         background: #f8f9fd !important;
         border-left: 1px solid #eee;

         &:nth-of-type(1) {
            color: #666 !important;
            background: #e6ecff !important;
            padding: 5px 10px !important;
            width: 25% !important;
            text-align: center !important;
         }

         &:nth-of-type(2) {
            text-align: center !important;
            color: #333 !important;
            //direction: ltr;
         }



      }



   }
}
</style>