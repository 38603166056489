<template>
  <v-card relative>
    <v-btn absolute class="mt-2 mx-2" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
      color="grey lighten-1" text to="/">
      <v-icon color="grey lighten-1">mdi-close</v-icon>
    </v-btn>
    <v-card-title class="text-h6">{{ $t('endShift') }}</v-card-title>
    <v-divider></v-divider>
    <v-form class="pa-5" ref="form" v-model="form" lazy-validation>
      <v-card flat :height="$store.state.Settings.windowSize?.y - 280" max-height="500" class="overflow-y-auto py-2 px-2 overflow-x-hidden" color="transparent">
        <v-row>
          <v-col cols="6" class="py-1">
            <h5 class="mb-3">{{ $t('caliber') }} 18</h5>
            <FloatInput :value="caliber18" @input="caliber18 = $event" label="" solo :required="false"
              :isLoading="false" :cols="[12, 12, 12]" />
          </v-col>
          <v-col cols="6" class="py-1">
            <h5 class="mb-3">{{ $t('caliber') }} 21</h5>
            <FloatInput :value="caliber21" @input="caliber21 = $event" label="" solo :required="false"
              :isLoading="false" :cols="[12, 12, 12]" />
          </v-col>
          <v-col cols="6" class="py-1">
            <h5 class="mb-3">{{ $t('caliber') }} 22</h5>
            <FloatInput :value="caliber22" @input="caliber22 = $event" label="" solo :required="false"
              :isLoading="false" :cols="[12, 12, 12]" />
          </v-col>
          <v-col cols="6" class="py-1">
            <h5 class="mb-3">{{ $t('caliber') }} 24</h5>
            <FloatInput :value="caliber24" @input="caliber24 = $event" label="" solo :required="false"
              :isLoading="false" :cols="[12, 12, 12]" />
          </v-col>
          <v-col cols="6" class="py-1">
            <h5 class="mb-3">{{ $t('visa') }}</h5>
            <FloatInput :value="visa" @input="visa = $event" label="" solo :required="false" :isLoading="false"
              :cols="[12, 12, 12]" />
          </v-col>
          <v-col cols="6" class="py-1">
            <h5 class="mb-3">{{ $t('master card') }}</h5>
            <FloatInput :value="master" @input="master = $event" label="" solo :required="false" :isLoading="false"
              :cols="[12, 12, 12]" />
          </v-col>
          <v-col cols="6" class="py-1">
            <h5 class="mb-3">{{ $t('mada') }}</h5>
            <FloatInput :value="mada" @input="mada = $event" label="" solo :required="false" :isLoading="false"
              :cols="[12, 12, 12]" />
          </v-col>
          <v-col cols="6" class="py-1">
            <h5 class="mb-3">{{ $t('other card') }}</h5>
            <FloatInput :value="other" @input="other = $event" label="" solo :required="false" :isLoading="false"
              :cols="[12, 12, 12]" />
          </v-col>
          <v-col cols="6" class="py-1">
            <h5 class="mb-3">{{ $t('transfer') }}</h5>
            <FloatInput :value="trans" @input="trans = $event" label="" solo :required="false" :isLoading="false"
              :cols="[12, 12, 12]" />
          </v-col>
          <v-col cols="6" class="py-1">
            <h5 class="mb-3">{{ $t('finalCash') }}</h5>
            <FloatInput :value="cash" @input="cash = $event" label="" solo :required="false" :isLoading="false"
              :cols="[12, 12, 12]" />
          </v-col>
          <v-col cols="6" class="py-1">
            <h5 class="mb-3">{{ $t('tabby') }}</h5>
            <FloatInput :value="tabby" @input="tabby = $event" label="" solo :required="false" :isLoading="false"
              :cols="[12, 12, 12]" />
          </v-col>
          <v-col cols="6" class="py-1">
            <h5 class="mb-3">{{ $t('tamara') }}</h5>
            <FloatInput :value="tamara" @input="tamara = $event" label="" solo :required="false" :isLoading="false"
              :cols="[12, 12, 12]" />
          </v-col>
          <v-col cols="6" class="py-1">
            <h5 class="mb-3">{{ $t('STC Pay') }}</h5>
            <FloatInput :value="stc_pay" @input="stc_pay = $event" label="" solo :required="false" :isLoading="false"
              :cols="[12, 12, 12]" />
          </v-col>
        </v-row>
      </v-card>
      <v-col cols="12" class="py-4 px-0">
        <v-btn color="success" large @click="closeShift" :loading="loading" :disabled="loading" class="shadow"
          style="width: 100%">{{ $t('endShift') }}</v-btn>
      </v-col>
    </v-form>
  </v-card>
</template>







<script>
import axios from "axios";
import FloatInput from "../inputs/FloatInput.vue";
export default {
  name: "endShift",
  computed: {

  },
  data() {
    return {
      form: [],
      dialog: false,
      loading: false,
      cash: 0,
      mada: 0,
      visa: 0,
      master: 0,
      other: 0,
      trans: 0,
      tabby: 0,
      stc_pay: 0,
      tamara: 0,
      caliber18: 0,
      caliber21: 0,
      caliber22: 0,
      caliber24: 0,
    };
  },
  components: {
    FloatInput
  },
  mounted() {
    document.title = this.$i18n.t("Qarat - Close shift");
    if (!this.$store.state.isAuth) {
      this.$router.push('/login')
    }
  },
  methods: {
    closeShift() {
      this.loading = true;
      // this.$refs.form.validate()
      const requestBody = {
        "summary": {
          caliber18: +this.caliber18,
          caliber21: +this.caliber21,
          caliber22: +this.caliber22,
          caliber24: +this.caliber24,
          cash: +this.cash,
          trans: +this.trans,
          master: +this.master,
          visa: +this.visa,
          mada: +this.mada,
          other: +this.other,
          tabby: +this.tabby,
          tamara: +this.tamara,
          stc: +this.stc_pay,
        }
      }
      this.$api.POST_METHOD("end_shift", requestBody).then((response) => {
        this.loading = false;
        if (response.check) {
          if (response.data.print.temp == 'temp_2') {
            this.$router.push({
              name: "ShiftSummery2",
              params: { report: response.data.print.shift, expenses: response.data.print.shift.ec_list, printStaticData: response.data.print },
            });
          }
          else {
            this.$router.push({
              name: "ShiftSummery",
              params: { report: response.data.print.shift, printStaticData: response.data.print },
            });
          }
          // this.$api.LOGOUT(true);
          this.$store.state.isAuth = false;
          this.$store.state.permissions = [];
          var lang = localStorage.getItem("language");
          localStorage.clear();
          localStorage.setItem("language", lang ? lang : "ar");
          axios.defaults.headers.common["Authorization"] = null;
          axios.defaults.headers.common["currentbranch"] = null;
          axios.defaults.headers.common["clientID"] = null;
        }
      })
    },

  },
};
</script>
