<template>
  <v-btn @click="print" class="pa-0" width="20" height="20" 
    :loading="loading" x-small icon fab>
    <img src="@/assets/img/svg/pdf.svg" alt="Qarat-POS" />
  </v-btn>
</template>


<script>
export default {
  name: "PrintInvoice",
  props: {
    printMethod: { type: Function },
    endpoint: { default: null },
    btnText: { default: null },
    id: { default: null },
    type: { default: 'print' },
    screenCode: { default: null }
  },
  data: () => ({
    loading: false
  }),
  computed: {

  },
  watch: {},
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {
    print() {
      if (this.type == 'printGet') {
        this.loading = true;
        this.$api.PrintGET(this.endpoint, this.id, 'print').then(() => {
          this.loading = false;
        })
      }
      if (this.type == 'print') {
        this.loading = true;
        const src = `${this.$api.serverUrl}${this.endpoint}/${this.id}?type=${this.type}&clientID=${this.$store.state.clientID}`;
        this.$api.PrintInvoiceURL(src, this.id);
        setTimeout(() => {
          this.loading = false;
        }, 5000);
      }
      if (this.type == 'image') {
        this.loading = true;
        const src = `src="${this.$api.serverUrl}${this.endpoint}/${this.id}?type=${this.type}&clientID=${this.$store.state.clientID}"`;
        this.$api.PrintInvoice(src, this.id);
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      }
    }
  },
};
</script>



<style scoped lang="scss"></style>
