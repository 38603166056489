<template>
  <section>
    <v-container fluid>
      <v-card class="shadow">
        <v-col cols="12" lg="10" md="11" class="d-flex mx-auto align-center">
          <v-text-field
            class="my-2 border"
            outlined
            background-color="light"
            :placeholder="$i18n.t('search by invoice number')"
            v-model="search"
            @keydown.enter="searchReturnSales"
            rounded-md
            autofocus
            dense
            hide-details
            type="number"
            @click="seachResult = []"
          ></v-text-field>
          <v-btn
            @click="searchReturnSales"
            :disabled="loading"
            :loading="loading"
            class="my-2 mx-2 shadow"
            color="primary"
          >
            <v-icon dark left>mdi-search-web</v-icon>
            {{ $t('search') }}
          </v-btn>
        </v-col>

        <v-row justify="center" v-if="isLoading">
          <v-col cols="auto">
            <v-progress-circular
              :size="70"
              :width="5"
              style="margin: auto; "
              class="d-block"
              color="primary"
              indeterminate
            ></v-progress-circular>
            <p class="text-center">{{ $t('Loading data') }}</p>
          </v-col>
        </v-row>

        <v-form ref="form" v-model="valid" class="pa-5" v-if="seachResult.length > 0">
          <v-card class="backgroundW shadow pa-5">
            <v-row>
              <v-col cols="12" lg="3" sm="6">
                <label>{{ $t('customerName') }}*</label>
                <v-text-field
                  class="my-2"
                  outlined
                  background-color="light"
                  v-model="form.customer_name"
                  rounded-md
                  dense
                  readonly
                  type="text"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="3" sm="6">
                <label>{{ $t('customerMobile') }}*</label>
                <v-text-field
                  class="my-2"
                  outlined
                  background-color="light"
                  v-model="form.customer_mobile"
                  rounded-md
                  dense
                  readonly
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="3" sm="6">
                <label>{{ $t('Identification Number') }}*</label>
                <v-text-field
                  class="my-2"
                  outlined
                  background-color="light"
                  v-model="form.customer_id_number"
                  rounded-md
                  dense
                  readonly
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="3" sm="6">
                <label>{{ $t('Registration tax number') }}*</label>
                <v-text-field
                  class="my-2"
                  outlined
                  background-color="light"
                  v-model="form.registrationTaxNumber"
                  rounded-md
                  dense
                  readonly
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>
        </v-form>

        <!-- Table -->
        <v-col cols="12" sm="12" v-if="seachResult.length > 0">
          <v-card class="backgroundW shadow pa-5">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">{{ $t('item') }}</th>
                    <th class="text-center">{{ $t('caliber') }}</th>
                    <th class="text-center">{{ $t('weight') }}</th>
                    <th class="text-center">{{ $t('pieces') }}</th>
                    <th class="text-center">{{ $t('vat') }}</th>
                    <th class="text-center">{{ $t('Total including tax') }}</th>
                    <th class="text-center">{{ $t('return') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, index) in seachResult" :key="index">
                    <td class="text-center">{{ row.sub_category_title }}</td>
                    <td class="text-center">{{ row.category_title }}</td>
                    <td>{{ row.weight }} {{ $t('gram') }}</td>
                    <td>{{ row.count_pieces }} {{ $t('piece') }}</td>
                    <td class="text-center">{{ row.total_vat.toFixed(2) }} {{ $t('ryal') }}</td>
                    <td class="text-center">{{ row.grand_total.toFixed(2) }} {{ $t('ryal') }}</td>
                    <td class="d-flex justify-center">
                      <v-switch
                        class="d-block ma-auto"
                        :disabled="!(row.returned_weight < row.weight)"
                        v-model="row.return_weight"
                        hide-details
                        inset
                      ></v-switch>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
          <v-col cols="12" class="d-flex">
            <v-btn
              @click="() => { confirmationDialog = true }"
              :disabled="(loading || !availToReturn)"
              :loading="loading"
              color="primary"
              class="shadow d-block mi-start-auto"
              v-if="seachResult.length > 0"
            >{{ $t('full refund invoice') }}</v-btn>
            <v-btn
              @click="save"
              :disabled="(loading || !availToReturn)"
              :loading="loading"
              class="shadow d-block mx-2"
              color="success"
              v-if="seachResult.length > 0"
            >{{ $t('save') }}</v-btn>
          </v-col>
        </v-col>
      </v-card>

      <CustomModal
        :dialogContent="{
          icon: 'info',
          title: 'are you sure to return all invoice ?',
          details: '',
          buttonOneTitle: 'yes',
          buttonOneColor: 'primary',
          buttonTwoTitle: 'cancel',
          buttonTwoColor: 'error',
        }"
        :dialog="confirmationDialog"
        :buttonOneMethod="() => { confirmationDialog = false; returnAll() }"
        :buttonTwoMethod="() => { confirmationDialog = false }"
      ></CustomModal>
    </v-container>


  </section>
</template>

<style  lang="scss">

</style>

<script>
import router from "../../router";
import CustomModal from "../../components/modals/CustomModal.vue";
export default {
  name: "ReturnSalesPiece",
  computed: {

  },
  data: () => ({
    search: '',
    valid: false,
    dialog: false,
    loading: false,
    isLoading: false,
    seachResult: [],
    confirmationDialog: false,
    form: {
      customer_name: '',
      customer_mobile: '',
      customer_id_number: '',
      registrationTaxNumber: '',
    },
    printID: 0,
    availToReturn: false,
    disableSave: false,
    selectedRow: {},
    rules: {
      minValue(value, min, input) {
        return (value || "") >= min || `اقل ${input}` + ':' + min;
      },
      maxValue(value, max, input) {
        return (value || "") <= max || `ازيد ${input}` + ':' + max;
      }
    },
    printContent: {},
    printLoading: false,
  }),
  components: {
    CustomModal
  },

  mounted() {

    if (!this.$store.state.permissions.includes("06-500")) {
      router.push('/login')
    }

  },
  watch: {
    seachResult() {
      this.availToReturn = false;
      this.seachResult.forEach(row => {
        switch (((Number(row.returned_weight) < Number(row.weight)) && this.availToReturn == false)) {
          case true:
            this.availToReturn = true
            break;
        }
      });
    }
  },
  methods: {
    returnAll() {
      this.loading = true;
      this.seachResult.forEach((item) => {
        return item.returned_weight = item.weight, item.return_weight = true
      }, 0);
      let requestBody = {
        items: this.seachResult,
        invoice: Number(+this.search),
      }

      this.$api.POST_METHOD("create_return_invoice_by_piece", requestBody, true, this.$i18n.t('Invoice Created Successfully')).then((response) => {
        if (response.check) {
          this.loading = false;
          this.$refs.form.reset();
          this.seachResult = [];
          this.printLoading = true;
          this.printID = response.data
          this.$api.DOWNLOAD_METHOD(`export_invoice_return_piece/${response.data}?type=pdf`)
        }
      })
    },
    save() {
      this.loading = true;
      this.seachResult.forEach((item) => {
        return Number(+item.returned_pieces), Number(+item.returned_weight), console.log(item);
      }, 0);
      let requestBody = {
        items: this.seachResult,
        invoice: Number(+this.search),
      }

      this.$api.POST_METHOD("create_return_invoice_by_piece", requestBody, true, this.$i18n.t('Invoice Created Successfully')).then((response) => {
        this.loading = false;
        if (response.check) {
          this.search = '';
          this.$refs.form.reset();
          this.seachResult = [];
          this.printLoading = true;
          this.printID = response.data
          this.$api.DOWNLOAD_METHOD(`export_invoice_return_piece/${response.data}?type=pdf`)

        }
      })
    },
    searchReturnSales() {
      this.isLoading = true;

      this.$api.GET_METHOD(`get_sales_invoice_by_piece/undefined?invoiceCode=${this.search}`).then((response) => {
        this.isLoading = false;
        this.seachResult = []
        response.data.details.forEach(row => {
          row.return_weight = 0, row.return_count_pieces = 0;
          return this.seachResult.push(row)
        }, 0);
        this.form.customer_name = response.data.master.customer_name
        this.form.customer_mobile = response.data.master.customer_mobile
        this.form.customer_id_number = response.data.master.customer_id_number
        this.form.registrationTaxNumber = response.data.master.customer_vat_register_number
      })
    },
    openDialog(row) {
      this.selectedRow = row
      this.dialog = true
    },
    setData(data, status) {
      this.dialog = status
    }
  },
};
</script>
