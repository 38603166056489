<template>
  <TextInput :type="price_input ? 'text' : 'text'" :readonly="readonly" :background="background"
    :value="price_input ? $global.NumberWithCommas((+value).toFixed(2)) : value" :name="name" :label="label"
    :labelInline="labelInline" :required="required" :hideDetails="hideDetails" :disabled="disabled" :isLoading="isLoading"
    :reverse="reverse" :focusin="() => price_input = false" :focusout="() => price_input = true" :color="color"
    :icon="icon" :solo="solo" :outlined="outlined" :dense="dense" :classes="classes" :clearable="clearable"
    @input="input = $event" :keydownEnter="(event) => keydownEnter(event)" :tabKey="(event) => tabKey(event)"
    :onChange="(event) => onChange(event)" :keydown="(event) => keydown(event)">
    <template v-slot:append-outer>
      <slot name="append-outer" />
    </template>
    <template v-slot:append>
      <slot name="append" />
    </template>
    <template v-slot:prepend-inner>
      <slot name="prepend-inner" />
    </template>
    <template v-slot:prepend>
      <slot name="prepend" />
    </template>
  </TextInput>
</template>


<script>
import TextInput from './TextInput.vue';

export default {
  name: "FloatInput",
  props: {
    name: { default: null },
    label: { default: null },
    type: { default: null },
    value: { default: null },
    required: { default: false },
    readonly: { default: false },
    dense: { default: true },
    onChange: { type: Function },
    focusout: { type: Function },
    focusin: { type: Function },
    isLoading: { default: null },
    disabled: { default: null },
    background: { default: null },
    color: { default: "primary" },
    icon: { default: "" },
    hideDetails: { default: false },
    labelInline: { default: false },
    clearable: { default: false },
    hideImage: { default: false },
    onClickOuter: { type: Function },
    keydownEnter: { type: Function },
    tabKey: { default: Function },
    placeholder: { default: null },
    solo: { default: false },
    outlined: { default: true },
    classes: { default: null },
    dir: { default: null },
    reverse: { default: false }
  },
  data: () => ({
    price_input: true,
  }),
  computed: {
    input: {
      get() {
        return this.value || 0;
      },
      set(val) {
        const convert = num => num.replace(/[٠-٩]/g, d => '٠١٢٣٤٥٦٧٨٩'.indexOf(d)).replace(/[a-zA-Z]/g, '').replace(/[٠-٩ء-ي]/g, '');
        this.$emit("input", +convert(val));
      },
    },
    floatNumber() {
      return this.$global.NumberWithCommas(this.input.toFixed(2));
    }
  },
  watch: {},
  mounted() {
  },
  beforeDestroy: function () {
  },
  methods: {},
  components: { TextInput }
};
</script>



<style  lang="scss">
.v-price-active.v-input input:not(.inputPrice) {
  opacity: 1 !important;
}

.v-price.v-input input {
  opacity: 0;
}

.v-price .v-input__prepend-outer {
  margin-left: 0;
}

.inputPrice {
  position: absolute;
  z-index: 2;
  left: 10px;
  top: 2px;

  &.active {
    opacity: 1 !important;
  }
}

.v-application--is-rtl {
  .inputPrice {
    left: unset;
    right: 10px;
  }
}
</style>
