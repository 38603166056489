<template>
  <section>
    <v-container fluid>
      <v-row justify="space-between">
        <v-col cols="12" class="d-flex justify-center align-center py-0 mb-6">
          <v-row align="center" justify="space-between">
            <v-col cols="12" md="auto">
              <div class="subtitle-2 text-center me-3 text--disabled">
                <strong>{{ $t('transfers') | capitalize }} . {{ $t('transfer cash') }} . {{ $t('add') | capitalize }} {{
                  $t('transfer cash') }}</strong>
              </div>
            </v-col>
            <v-col cols="12" md="auto">
              <v-btn color="red12" class="error--text mx-2" :width="120" depressed @click="exitDialog = true">
                {{ $t('cancel') }}
                <v-icon right>mdi-close</v-icon>
              </v-btn>
              <v-btn color="success" :width="120" :loading="saveIsLoading" depressed @click="save">
                {{ $t('save') }}
                <v-icon right>mdi-check</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-card class="shadow">

        <v-row justify="center" v-if="isLoading">
          <v-col cols="auto">
            <v-progress-circular :size="70" :width="5" style="margin: auto; " class="d-block" color="primary"
              indeterminate></v-progress-circular>
            <p class="text-center">{{ $t('Loading data') }}</p>
          </v-col>
        </v-row>

        <v-form ref="form" v-model="valid" class="pa-5" v-if="!isLoading">
          <v-sheet class="backgroundW  pa-5">
            <v-row justify="space-between" class="justify-xl-space-around py-4 py-xl-6">

              <v-col cols="12" md="6" lg="7">
                <v-row align="center" justify="space-between">
                  <!-- <v-col cols="12" class="d-flex justify-center">
                    <v-tabs class="custom_tab d-block ma-auto">
                      <div class="tab-parent d-flex">
                        <v-tab @click="form.transfer_type = 1" class="tab-child  gray2--text"
                          :class="form.transfer_type == 1 ? 'white' : 'transparent'">
                          <strong>{{ $t('bank transfer') }}</strong>
                        </v-tab>
                        <v-tab @click="form.transfer_type = 2" class="tab-child  gray2--text"
                          :class="form.transfer_type == 2 ? 'white' : 'transparent'">
                          <strong>{{ $t('branch transfer') }}</strong>
                        </v-tab>
                      </div>
                    </v-tabs>
                  </v-col> -->
                  <GenericInput type="select" :lookups="from_banks_branches"
                    :disableItem="{ key: 'select_id', value: form.to_id }" :value="form.from_id" :has_slot="true"
                    @input="changeTransfer('from', $event)" label="transfer from" :multi="false" :required="true"
                    selected_label="name" selected_prop="select_id" :hide-details="true" :isLoading="false"
                    :cols="[12, 12, 5]">
                    <template v-slot:selection="item">
                      <div class="subtitle-2">
                        <v-chip :color="item.item.type_id == 1 ? 'success' : 'blue5'" class="white--text" small>{{
                          $t(item.item.type) }}</v-chip>
                        <span>{{ item.item.name }}</span>
                      </div>
                    </template>
                    <template v-slot:item="item">
                      <template>
                        <div style="width: 100%" class="body-2 d-flex justify-space-between">
                          <div> {{ item.item.name }}</div> 
                          <div v-if="form.to_id == item.item.select_id"> ({{ $t('transfered to') }})</div>
                          <div class="font-weight-bold">
                            <v-chip :disabled="form.to_id == item.item.select_id"
                              :color="item.item.type_id == 1 ? 'success' : 'blue5'" class="white--text" small>{{
                                $t(item.item.type) }}</v-chip>
                          </div>
                        </div>
                      </template>
                    </template>
                  </GenericInput>
                  <v-col cols="1" class="d-none d-lg-block">
                    <img class="d-block ma-auto" src="@/assets/img/svg/u_exchange.svg" alt="qarat">
                  </v-col>

                  <GenericInput type="select" :lookups="to_banks_branches"
                    :disableItem="{ key: 'select_id', value: form.from_id }" :value="form.to_id" :has_slot="true"
                    @input="changeTransfer('to', $event)" label="transfer to" :multi="false" :required="true"
                    selected_label="name" selected_prop="select_id" :hide-details="true" :isLoading="false"
                    :cols="[12, 12, 5]">
                    <template v-slot:selection="item">
                      <div class="subtitle-2">
                        <v-chip :color="item.item.type_id == 1 ? 'success' : 'blue5'" class="white--text" small>{{
                          $t(item.item.type) }}</v-chip>
                        <span>{{ item.item.name }}</span>
                      </div>
                    </template>
                    <template v-slot:item="item">
                      <template>
                        <div style="width: 100%" class="body-2 d-flex justify-space-between">
                          <div> {{ item.item.name }}</div>
                          <div v-if="form.from_id == item.item.select_id"> ({{ $t('transfered from') }})</div>
                          <div class="font-weight-bold">
                            <v-chip :disabled="form.from_id == item.item.select_id"
                              :color="item.item.type_id == 1 ? 'success' : 'blue5'" class="white--text" small>{{
                                $t(item.item.type) }}</v-chip>
                          </div>
                        </div>
                      </template>
                    </template>
                  </GenericInput>


                  <GenericInput type="date" :solo="false" :dense="true" classes="rounded-lg" :value="form.action_date"
                    @input="form.action_date = $event" label="date" :required="false" :hide-details="true"
                    :cols="[12, 12, 12]">
                  </GenericInput>

                  <v-col cols="12" class="px-0">
                    <GenericInput type="textarea" :solo="false" :dense="true" classes="rounded-lg"
                      :value="form.description" @input="form.description = $event" label="description" :required="false"
                      :hide-details="true" :cols="[12, 12, 12]">
                    </GenericInput>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="auto" class="d-none py-16 d-md-block">
                <v-divider vertical style="height: 180px ;"></v-divider>
              </v-col>

              <v-col cols="12" md="auto">
                <v-sheet max-width="400" class="d-block mt-n3 ma-auto">
                  <label>{{ $t('amount') }}*</label>
                  <NumberPad :backValueMethod="setAmount" :value="String(form.amount)" />
                </v-sheet>
              </v-col>




            </v-row>
          </v-sheet>
        </v-form>


      </v-card>
      <ExitConfirmationModal :dialog="exitDialog" :closeDialog="() => exitDialog = false" exit_to="/transfers" />
    </v-container>
  </section>
</template>
 
 
 
<script>
import NumberPad from "@/components/NumberPad.vue";
import GenericInput from "@/components/GenericInput.vue";
import ExitConfirmationModal from "@/components/modals/ExitConfirmationModal.vue";
export default {
  name: "CashTransfer",
  computed: {

  },
  data: () => ({
    valid: false,
    isEdit: false,
    exitDialog: false,
    isLoading: false,
    saveIsLoading: false,
    branches: [],
    banks: [],
    from_banks_branches: [],
    to_banks_branches: [],
    key: 1,
    form: {
      from_id: 0,
      to_id: 0,
      from_bank_id: 0,
      from_branch_id: 0,
      to_bank_id: 0,
      to_branch_id: 0,
      amount: 0,
      transfer_type: 1,
      action_date: null,
      description: null,
    },
    today: null,
  }),
  components: {
    NumberPad,
    GenericInput,
    ExitConfirmationModal
  },
  watch: {
    'form.transfer_type'() {
      if (!this.isEdit) {
        this.form.to_bank_id = 0;
        this.form.to_branch_id = 0;
      }
    },
  },
  mounted() {
    this.pageMainData()
  },
  methods: {
    pageMainData() {
      this.today = this.$global.GetCurrentDate();
      this.form.action_date = this.$global.GetCurrentDate();
      if (this.$route.params.type == 'cash') {
        this.branches = this.$route.params.responseData.branchs || []
        this.banks = this.$route.params.responseData.banks || [];
        this.tableRows = this.$route.params.details || [];
        this.form = this.$route.params.master;
        this.banks.forEach(bank => {
          bank.bank_id = bank.id;
          bank.type = 'Bank';
          bank.type_id = 1;
          bank.select_id = `bank_${bank.id}`;
        });
        this.branches.forEach(branch => {
          branch.branch_id = branch.id;
          branch.type = 'Branch';
          branch.type_id = 2;
          branch.select_id = `branch_${branch.id}`;
        });
        this.from_banks_branches = [
          {
            header: this.$i18n.t('Bank'),
          },
          ...this.banks,
          {
            divider: true
          },
          {
            header: this.$i18n.t('Branch'),
          },
          ...this.branches.filter((branch) => branch.id == this.$store.state?.userData?.user?.shift?.branch_id),
        ];
        this.to_banks_branches = [
          {
            header: this.$i18n.t('Bank'),
          },
          ...this.banks,
          {
            divider: true
          },
          {
            header: this.$i18n.t('Branch'),
          },
          ...this.branches.filter((branch) => branch.id !== this.$store.state?.userData?.user?.shift?.branch_id),
        ];
        if (this.$route.params.master.from_branch_id) {
          this.form.from_id = `branch_${this.$route.params.master.from_branch_id}`
        } else {
          this.form.from_id = `bank_${this.$route.params.master.from_bank_id}`
        }
        if (this.$route.params.master.to_branch_id) {
          this.form.to_id = `branch_${this.$route.params.master.to_branch_id}`
        } else {
          this.form.to_id = `bank_${this.$route.params.master.to_bank_id}`
        }
        this.isEdit = true
      } else {
        this.getData();
      }
    },
    getData() {
      this.isLoading = true;
      this.$api
        .GET_METHOD(`money_transfer/create`)
        .then((response) => {
          this.isLoading = false;
          if (response.check) {
            this.banks = response.data.banks;
            this.branches = response.data.branchs;
            response.data.banks.forEach(bank => {
              bank.bank_id = bank.id;
              bank.type = 'Bank';
              bank.type_id = 1;
              bank.select_id = `bank_${bank.id}`;
            });
            response.data.branchs.forEach(branch => {
              branch.branch_id = branch.id;
              branch.type = 'Branch';
              branch.type_id = 2;
              branch.select_id = `branch_${branch.id}`;
            });
            this.from_banks_branches = [
              {
                header: this.$i18n.t('Bank'),
              },
              ...response.data.banks,
              {
                divider: true
              },
              {
                header: this.$i18n.t('Branch'),
              },
              ...response.data.branchs.filter((branch) => branch.id == this.$store.state?.userData?.user?.shift?.branch_id),
            ];
            this.to_banks_branches = [
              {
                header: this.$i18n.t('Bank'),
              },
              ...response.data.banks,
              {
                divider: true
              },
              {
                header: this.$i18n.t('Branch'),
              },
              ...response.data.branchs.filter((branch) => branch.id !== this.$store.state?.userData?.user?.shift?.branch_id),
            ];
          }
        });
    },
    save() {
      this.saveIsLoading = true;
      if (this.isEdit) {
        this.form._method = 'PUT';
        this.$api.POST_METHOD(`money_transfer/${this.form.id}`, this.form).then((response) => {
          this.saveIsLoading = false;
          if (response.check) {
            this.$router.push(`/transfers/${this.form.id}?type=cash`)
          }
        })
      } else {
        this.$api.POST_METHOD(`money_transfer`, this.form).then((response) => {
          this.saveIsLoading = false;
          if (response.check) {
            this.$router.push(`/transfers/${response.data.id}?type=cash`)
          }
        })
      }

    },
    changeTransfer(direction, value) {
      if (direction == 'from') {
        this.form.from_id = value;
        if (value.split('_')[0] == 'bank') {
          this.form.from_bank_id = +value.split('_')[1];
          this.form.from_branch_id = 0;
        }
        else {
          this.form.from_branch_id = +value.split('_')[1];
          this.form.from_bank_id = 0;
        }
      } else {
        this.form.to_id = value;
        if (value.split('_')[0] == 'bank') {
          this.form.to_bank_id = +value.split('_')[1];
          this.form.to_branch_id = 0;
        }
        else {
          this.form.to_branch_id = +value.split('_')[1];
          this.form.to_bank_id = 0;
        }
      }
    },
    setAmount(returnedAmount) {
      this.form.amount = +returnedAmount;
    },
  },
};
</script>
 
 