<template>
   <span>
      <div style="width : 260px" v-if="header.key == 'sub_category_id' && !detail.barcode_id">
         <AutoCompleteInput :lookups="sub_categories" :value="detail.sub_category_id" placeholder="item"
            @input="detail.sub_category_id = $event" label=""
            :required="!(tableRows.length == index + 1 && tableRows.length !== 1)" selected_label="sub_category_title"
            selected_prop="sub_category_id" :isLoading="false" :cols="[12, 12, 12]" :has_slot="true">
            <template v-slot:selection="item">
               <div class="subtitle-2">
                  <span class="font-weight-bold">{{ item.item.sub_category_title }}</span>
                  <span> - {{ item.item.category_title }}</span>
               </div>
            </template>
            <template v-slot:item="item">
               <div class="body-2">
                  <span class="font-weight-bold">{{ item.item.sub_category_title }}</span>
                  <span> - {{ item.item.category_title }}</span>
               </div>
            </template>
         </AutoCompleteInput>
      </div>
      <div style="width : 260px" v-if="header.key == 'sub_category_id' && detail.barcode_id">
         <v-btn color="blue4" text >
            <v-icon left>mdi-barcode</v-icon>
            {{ detail.barcode_id }} - {{ detail.sub_category_name }} ( {{ detail.category_name }} )
         </v-btn>
      </div>
      <FloatInput :disabled="!(detail.sub_category_id)" v-if="header.key == 'sell_price' && detail.barcode_id " :value="detail.sell_price"
      @input="setSellPrice($event)" placeholder="sell price" :required="false" :isLoading="false" />
      <span
         v-if="detail.barcode_id && header.key !== 'sub_category_id' && header.key !== 'actions' && header.key != 'sell_price' && header.key !== 'wages'">
         <span v-if="header.key == 'caliber_id'"> {{ $t('caliber') }} {{ detail[header.key] }}</span>
         <span v-else> {{ detail[header.key] || 0 | float }}</span>
      </span>
      <FloatInput :disabled="!(detail.sub_category_id)" v-if="header.key == 'weight' && !detail.barcode_id"
         :value="detail.weight" @input="detail.weight = $event" label="" :required="true" :isLoading="false"
         :cols="[12, 12, 12]" />

      <FloatInput :disabled="!(detail.sub_category_id) " v-if="header.key == 'wages' && detail.category_id < 25"
         :value="detail.wages" :tabKey="() => tabKey(index)" @input="detail.wages = $event" label="" :required="false"
         :isLoading="false" :cols="[12, 12, 12]" />

      <template v-if="is_diamond && !detail.barcode_id">
         <AutoCompleteInput v-if="header.key == 'caliber_id' && !detail.barcode_id" inputDesign="inline_label"
            :lookups="[{ id: 18 }, { id: 21 }, { id: 22 }, { id: 24 }]" :value="detail.caliber_id"
            :disabled="!(detail.sub_category_id)" @input="detail.caliber_id = $event" :multi="false" :required="false"
            selected_label="id" selected_prop="id" :isLoading="false"  :tabKey="() => { tableRows.length == index + 1 ? addDetail() : null }" />

         <FloatInput :disabled="!(detail.sub_category_id)" v-if="header.key == 'gold_weight' && !detail.barcode_id"
            :value="detail.gold_weight" @input="detail.gold_weight = $event" label="" :required="false" :isLoading="false"
            :cols="[12, 12, 12]" />

         <AutoCompleteInput v-if="header.key == 'shape_id' && !detail.barcode_id" inputDesign="inline_label"
            :lookups="shapes" :value="detail.shape_id" @input="detail.shape_id = $event" :multi="false" :required="false"
            selected_label="title" selected_prop="id" :isLoading="false" />

         <AutoCompleteInput v-if="header.key == 'color_id' && !detail.barcode_id" inputDesign="inline_label"
            :lookups="colors" :value="detail.color_id" @input="detail.color_id = $event" :multi="false" :required="false"
            selected_label="color_code" selected_prop="color_id" :isLoading="false" />

         <AutoCompleteInput v-if="header.key == 'quality_id' && !detail.barcode_id" inputDesign="inline_label"
            :lookups="qualities" :value="detail.quality_id" @input="detail.quality_id = $event" :multi="false"
            :required="false" selected_label="title" selected_prop="quality_id" :isLoading="false" />

         <AutoCompleteInput v-if="header.key == 'clarity_id' && !detail.barcode_id" inputDesign="inline_label"
            :lookups="clarities" :value="detail.clarity_id" @input="detail.clarity_id = $event" :multi="false"
            :required="false" selected_label="clarity_code" selected_prop="clarity_id" :isLoading="false"
            :tabKey="() => { tableRows.length == index + 1 ? addDetail() : null }" />

         <TextInput v-if="header.key == 'supplier_code'" :value="detail.supplier_code"
            @input="detail.supplier_code = $event" placeholder="supplier barcode" :required="false" :isLoading="false" />

         <NumberInput v-if="header.key == 'factor'" :disabled="!(detail.sub_category_id)" :value="detail.factor"
            @input="setFactor($event)" placeholder="factor" :required="detail.sub_category_id" :isLoading="false" />

         <FloatInput :disabled="!(detail.sub_category_id)" v-if="header.key == 'price'" :value="detail.price"
            @input="setPrice($event)" placeholder="price" :required="false" :isLoading="false" />

         <FloatInput :disabled="!(detail.sub_category_id)" v-if="header.key == 'stone_weight'" :value="detail.stone_weight"
            @input="detail.stone_weight = $event" placeholder="weight" :required="false" :isLoading="false" />

         <FloatInput :disabled="!(detail.sub_category_id)" v-if="header.key == 'package_weight'"
            :value="detail.package_weight" @input="detail.package_weight = $event" placeholder="package weight"
            :required="false" :isLoading="false" />

         <FloatInput :disabled="!(detail.sub_category_id)" v-if="header.key == 'sell_price'" :value="detail.sell_price"
            @input="setSellPrice($event)" placeholder="sell price" :required="false" :isLoading="false" />

         <FloatInput :disabled="!(detail.sub_category_id)" v-if="header.key == 'min_sell_price'"
            :value="detail.min_sell_price" @input="detail.min_sell_price = $event" placeholder="minimum sell price"
            :required="false" :isLoading="false" />



      </template>


      <div v-if="header.key == 'actions'">
         <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
               <v-btn color="transparent" tabindex="-1" fab x-small depressed block v-bind="attrs" v-on="on">
                  <v-icon>mdi-menu</v-icon>
               </v-btn>
            </template>
            <v-list>
               <div class="pa-2" v-if="!detail.barcode_id">
                  <v-btn @click="duplicateDetail(detail, index)" class="rounded-0" block
                     :disabled="!(detail.sub_category_id)" color="secondary" tabindex="-1" text>
                     <v-icon>mdi-content-copy</v-icon>
                     {{ $t('duplicate') }}
                  </v-btn>
               </div>
               <div class="pa-2" v-if="tableRows.length >= index + 1">
                  <v-btn @click="addDetail" class="rounded-0" block :disabled="!(detail.sub_category_id)" color="success"
                     text tabindex="-1">
                     <v-icon>mdi-plus</v-icon>
                     {{ $t('add line') }}
                  </v-btn>
               </div>
               <div class="pa-2" v-if="tableRows.length > 1">
                  <v-btn @click="removeDetail(index, detail)" :disabled="(detail.is_locked == 1)" class="rounded-0" block
                     text tabindex="-1" color="error">
                     <v-icon>mdi-delete</v-icon>
                     {{ $t('delete') }}
                  </v-btn>
               </div>
            </v-list>
         </v-menu>
      </div>
   </span>
</template>

<script>
import AutoCompleteInput from '@/components/inputs/AutoCompleteInput.vue';
import FloatInput from '@/components/inputs/FloatInput.vue';
import NumberInput from '@/components/inputs/NumberInput.vue';
import TextInput from '@/components/inputs/TextInput.vue';
export default {
   name: "GoldPermissionDetail",
   props: {
      addDetail: { default: Function },
      updateDetail: { default: Function },
      removeDetail: { default: Function },
      duplicateDetail: { default: Function },
      // Detail
      header: { default: Object },
      detail: { default: Object },
      index: { default: null },
      // List
      tableRows: { default: [] },
      sub_categories: { default: [] },
      categories: { default: [] },
      vats: { default: Object },
      gold_price: { default: Object },
      pageIsEdit: { default: false },
      is_diamond: { default: false },
      clarities: { default: [] },
      qualities: { default: [] },
      shapes: { default: [] },
      colors: { default: [] },
   },
   computed: {
   },
   components: { AutoCompleteInput, FloatInput, TextInput, NumberInput },
   watch: {
      'detail.sub_category_id'() {
         this.calculateDetail();
      },
   },
   data: () => ({
      isLoading: false,
   }),
   methods: {
      tabKey(index) {
         if (this.tableRows.length === index + 1 && this.detail.sub_category_id) {
            this.addDetail()
         }
      },
      calculateDetail() {
         if (this.detail.sub_category_id) {
            var getItem = this.sub_categories.find(item => item.sub_category_id === this.detail.sub_category_id) || {};
            this.detail.category_id = getItem.category_id
            this.detail.weight = this.detail.weight || 1;
            this.updateDetail(this.detail, this.index)
         }
      },
      openBarcode(barcode_id) {
         if (this.$global.CheckAction('03-004', 1)) {
            window.open('/store/print_gold_barcode-control/' + barcode_id, '_blank')
         }
      },
      setSellPrice(price) {
         this.detail.sell_price = +price;
         this.detail.factor = +this.detail.sell_price / +this.detail.price;
         this.updateDetail({ ...this.detail }, this.index)
      },
      setFactor(factor) {
         console.log(factor);
         this.detail.sell_price = +factor * +this.detail.price;
         this.detail.factor = +factor;
      },
      setPrice(price) {
         this.detail.price = +price;
         this.detail.min_sell_price = +price;
         this.detail.sell_price = +this.detail.factor * +this.detail.price;
         this.updateDetail({ ...this.detail }, this.index)
      },
   },
   mounted() {

   },
}
</script>