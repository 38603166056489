<template>
  <v-text-field v-model="input" :solo="solo" outlined :reverse="reverse" :dense="dense" :name="name"
    :autocomplete="name" @keydown.enter="keydownEnter()" :class="classes" :loading="isLoading" :disabled="disabled"
    :rules="required == true ? $global.passwordRule : $global.notRequiredRule" :color="color" rounded-md
    :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'" :type="showPassword ? 'text' : 'password'"
    prepend-inner-icon="mdi-account-key" :hide-details="hideDetails == true ? 'auto' : false"
    @click:append="showPassword = !showPassword">
    <template v-slot:label>
      <label v-if="labelInline == false">
        {{ $t(label) }}
        <span class="error--text" v-if="required == true">*</span>
      </label>
    </template>
    <template v-slot:append-outer>
      <slot name="append-outer" />
    </template>
    <template v-slot:append>
      <slot name="append" />
    </template>
    <template v-slot:prepend-inner>
      <slot name="prepend-inner" />
    </template>
    <template v-slot:prepend>
      <slot name="prepend" />
    </template>
  </v-text-field>
</template>


<script>
export default {
  name: "PasswordInput",
  props: {
    name: { default: null },
    label: { default: null },
    type: { default: null },
    value: { default: null },
    required: { default: false },
    dense: { default: true },
    onChange: { type: Function },
    isLoading: { default: null },
    disabled: { default: null },
    color: { default: "primary" },
    icon: { default: "" },
    hideDetails: { default: false },
    labelInline: { default: false },
    clearable: { default: false },
    hideImage: { default: false },
    onClickOuter: { type: Function },
    keydownEnter: { type: Function },
    placeholder: { default: null },
    solo: { default: false },
    classes: { default: null },
    dir: { default: null },
    reverse: { default: false }
  },
  data: () => ({
    showPassword: false
  }),
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {

  },
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {

  },
};
</script>



<style scoped lang="scss"></style>
